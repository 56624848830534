import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoginService } from '../../../services/login.service';
import { CommonService } from 'apps/common/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { getLocale } from 'apps/common/helper/util-function-class';

@Component({
  selector: 'login-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.css']
})
export class SheetComponent implements OnInit {
  embeddedURL: SafeResourceUrl;
  msgServiceError = $localize`Oops, something went wrong! Please try again later.`;
  showMessage:boolean = false; 
  message = '';
  embeddedDashboard:any;
  params: any;
  dashboardId:string;
  sheetId:string;
  action:string;
  userId:string;
  newWindow:string;

  constructor(public commonService: CommonService, public service: LoginService, private route: ActivatedRoute, public sanitizer: DomSanitizer) {
    this.userId = this.commonService.getUserId();
  }

  ngOnInit(): void {
    var url  = new URL(document.referrer);
    this.route.params.subscribe(params => {
      this.dashboardId = params['dashboardId'];
      this.sheetId = params['sheetId'];
    });

    this.route.queryParams.subscribe(params => {
      this.params = JSON.parse(JSON.stringify(params).replaceAll('p.', ''));
      this.params.prmEnvironment = url.hostname;
      this.params.prmLocale = getLocale();
      this.newWindow = this.params['newWindow']; 
      }
    ); 
    if(this.newWindow != null && this.newWindow === 'No') {
      console.log(window.location.href);
//      const url = new URL(window.location.href);
      const params = new URLSearchParams(window.location.href.split('?')[1]);
      params.delete("newWindow");
      if(params.toString() != "")
      {
        parent.location.href = window.location.href.split('?')[0]+"?"+params;
      }else{
        parent.location.href = window.location.href.split('?')[0];
      }

      parent.location.reload();
    }else{
      this.loadSheet();
    }
  }

  loadSheet() {
    if(this.dashboardId === 'console')
      this.service.getConsoleUrl(this.dashboardId, this.getUrlCallback, this.getUrlError);
    else  
      this.service.getDashboardUrl(this.dashboardId, this.getUrlCallback, this.getUrlError);
  }

  getUrlCallback = (result:any) => {
    if( result != null ) {
      this.embeddedURL = this.sanitizer.bypassSecurityTrustResourceUrl(result.value);
      const options = {
        url: result.value,
        container: document.getElementById('sheetContainer'),
        parameters: this.params,
        scrolling: 'no',
        height: 'AutoFit',
        width: '100%',
        sheetId: this.sheetId
      };
      this.embeddedDashboard = embedDashboard(options);
      this.embeddedDashboard.on('error', this.onError);
      this.embeddedDashboard.on('load', this.onDashboardLoad);
    }
  }

  onDashboardLoad(payload) {
    console.log("Do something when the dashboard is fully loaded.", payload);
  }

  onError(payload) {
    console.log("Do something when the dashboard fails loading", payload);
  }

  getUrlError = (error:any) =>  {
    if( error !== null && 
      error.error !== null && 
      error.error.serviceMessage !== undefined && 
      error.error.serviceMessage != null ) {
    } 
    else {
      console.log(error.message + '(response code: ' + error.status + ')');
    }
  }

}

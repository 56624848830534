import {constantParmeter} from './util-function';

export default function createReportCardPdf(emissionResult:any, pdList:any, pgList:any, tvList:any){
  var adRow:any[] = [];
  var adTitle = [
    { text: emissionResult.aLocale==="en"?"ACTIVITY":"ACTIVITÉ", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, false, true] , fillColor:"#c0e9fc", alignment: 'center'},
    { text: emissionResult.aLocale==="en"?"PEER GROUP AVERAGE":"MOYENNE DU GROUPE DE PAIRS", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, false, true] ,  fillColor:"#f2f2f2", alignment: 'center'},
    { text: emissionResult.aLocale==="en"?"VIC AMOUNT":"MONTANTE VIC", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, true, true] , fillColor:"#f2f2f2", alignment: 'center'},
  ]
  adRow.push(adTitle);
  emissionResult.adList.forEach((element:any) => {
      var row = [
        { text: emissionResult.aLocale==="en"?element.activityDefinitionDesc:element.activityDefinitionDescFr, style: 'bodyText', fontSize: 9, bold: 'false', border: [true, true, false, true] , fillColor:"#FFFFFF", alignment: 'left'},
        { text: element.activityDefinitionDesc.includes("%")?element.average.toFixed(1)+"%":Math.floor(element.average), style: 'bodyText', bold: 'false', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'right'},
        { text: element.activityDefinitionDesc.includes("%")?element.amount.toFixed(1)+"%":element.amount.toFixed(1), style: 'bodyText', bold: 'false', border: [true, true, true, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'right'},
      ]
      adRow.push(row);
  })

  var pmRow:any[] = [];
  var pmTitle = [
    { text: emissionResult.aLocale==="en"?"PERFORMANCE":"PERFORMANCE", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, false, true] , fillColor:"#c0e9fc", alignment: 'center'},
    { text: emissionResult.aLocale==="en"?"PEER GROUP\nAVERAGE":"MOYENNE DU\nGROUPE DE PAIRS", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, false, true], fillColor:"#f2f2f2", alignment: 'center'},
    { text: emissionResult.aLocale==="en"?"VIC\nPERCENTAGE":"POURCENTAGE\nVIC", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, false, true] , fillColor:"#f2f2f2", alignment: 'center'},
    { text: emissionResult.aLocale==="en"?"PERCENTAGE\nCOMPARED TO\nPEER GROUP":"POURCENTAGE\nCOMPARÉ À\nGROUPE DE PAIRS", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, false, true] , fillColor:"#f2f2f2", alignment: 'center'},
    { text: emissionResult.aLocale==="en"?"GRADE":"NOTE", style: 'bodyText', bold: 'true', border: [true, true, true, true] , fontSize: 9, fillColor:"#f2f2f2", alignment: 'center'},
  ]
  pmRow.push(pmTitle);
  emissionResult.pmList.forEach((element:any) => {
    // Comment out per Chip's request
    // if(element.amount === 0){
    //   element.amount = 100;
    //   element.performanceGradeCd = 'A';
    //   element.amountCompareToGroup = element.amount - element.average;
    // }
    var row = [
      { text: emissionResult.aLocale==="en"?element.performanceMetric:element.performanceMetricFr, style: 'bodyText', bold: 'false', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'left'},
      { text: element.average.toFixed(1)+"%", style: 'bodyText', bold: 'false', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'right'},
      { text: element.amount.toFixed(1)+"%", style: 'bodyText', bold: 'false', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'right'},
      { text: element.amountCompareToGroup.toFixed(1)+"%", style: 'bodyText', bold: 'false', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'right'},
      { text: element.performanceGradeCd, style: 'bodyText', color: 'white', bold: 'false', border: [true, true, true, true] , fontSize: 9, fillColor:element.performanceGradeCd=='A'?"#2B8737":element.performanceGradeCd=='B'?"#0369AC":element.performanceGradeCd=='C'?"#8A600D":element.performanceGradeCd=='D'?"#D81A21":"#FFFFFF", alignment: 'center'},
    ]
    pmRow.push(row);
  })
  
  var tvRow:any[] = [];
  var tvTitle = [
    { text: emissionResult.aLocale==="en"?"Peer Group Test Volume":"Volume de tests du groupe de pairs", style: 'bodyText', bold: 'true', border: [true, true, false, true] , fontSize: 9, fillColor:"#f2f2f2", alignment: 'center'},
    { text: (emissionResult.aLocale==="en"?(emissionResult.inspectionCategoryCd==="EMS"?"Emissions (Test)":"Safety (Test)"):(emissionResult.inspectionCategoryCd==="EMS"?"Émissions (test)":"Sécurité (test")), style: 'bodyText', bold: 'true', border: [true, true, true, true] , fontSize: 9, fillColor:"#f2f2f2", alignment: 'center'},
  ]
  tvRow.push(tvTitle);
  tvList.forEach((element:any) => {
    var row = [
      { text: emissionResult.aLocale==="en"?element.testVolumeDesc:element.testVolumeDescFr, style: 'bodyText', bold: 'true', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'left'},
      { text: element.startRange=="0.00"?"<= "+ element.endRange:element.endRange=="99999.00"?">= "+ element.startRange:"" + element.startRange != "0.00"&&element.endRange!="99999.00"?element.startRange+" - "+element.endRange:"", style: 'bodyText', bold: 'false', border: [true, true, true, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'left'},
    ]
    tvRow.push(row);
  })

  var pgRow:any[] = [];
  var pgTitle = [
    { text: emissionResult.aLocale==="en"?"GRADE":"NOTE", style: 'bodyText', bold: 'true', border: [true, true, false, true] , fontSize: 9, fillColor:"#f2f2f2", alignment: 'center'},
    { text: emissionResult.aLocale==="en"?"PERFORMANCE RANGE":"FOURCHETTE DE RENDEMENTS", style: 'bodyText', bold: 'true', border: [true, true, true, true] , fontSize: 9, fillColor:"#f2f2f2", alignment: 'center'},
  ]
  pgRow.push(pgTitle);
  pgList.forEach((element:any) => {
    var row = [
      { text: element.performanceGradeCd, style: 'bodyText', bold: 'true', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'center'},
      { text: emissionResult.aLocale==="en"?element.performanceGradeDesc:element.performanceGradeDescFr, style: 'bodyText', bold: 'false', border: [true, true, true, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'left'},
    ]
    pgRow.push(row);
  })

  var pdRow:any[] = [];
  var pdTitle = [
    { text: "", style: 'bodyText', bold: 'true', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'center'},
    { text: emissionResult.aLocale==="en"?"Performance Metric":"Indicateur de rendement ", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, true, true], alignment: 'center', margin: [ 2, 0, 0, 0 ]},
    { text: emissionResult.aLocale==="en"?"Description":"Description", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, true, true], alignment: 'center', margin: [ 2, 0, 0, 0 ]},
    { text: emissionResult.aLocale==="en"?"Why is this important?":"Pourquoi est-ce important?", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, true, true], alignment: 'center', margin: [ 2, 0, 0, 0 ]},
    { text: emissionResult.aLocale==="en"?"How can you improve?":"Comment pouvez-vous vous améliorer?", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, true, true], alignment: 'center', margin: [ 2, 0, 0, 0 ]},
  ]
  pdRow.push(pdTitle);
  var i = 0;
  pdList.forEach((element:any) => {
    i = i + 1;
    var row = [
      { text: i, style: 'bodyText', bold: 'true', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'center'},
      { text: emissionResult.aLocale==="en"?element.performanceMetric:element.performanceMetricFr, style: 'bodyText', fontSize: 9, bold: 'true', border: [true, false, true, true], alignment: 'left', margin: [ 2, 0, 0, 0 ]},
      { text: emissionResult.aLocale==="en"?element.performanceDesc:element.performanceDescFr, style: 'bodyText', bold: 'false', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'left'},
      { text: emissionResult.aLocale==="en"?element.performanceImportantDesc:element.performanceImportantDescFr, style: 'bodyText', bold: 'false', border: [true, true, false, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'left'},
      { text: emissionResult.aLocale==="en"?element.performanceImproveDesc:element.performanceImproveDescFr, style: 'bodyText', bold: 'false', border: [true, true, true, true] , fontSize: 9, fillColor:"#FFFFFF", alignment: 'left'},
    ]
    pdRow.push(row);
  })

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [8, 10, 8, 15],
    /*ownerPassword: '***pwd***',*/
    permissions: {
          printing: 'highResolution',
          modifying: false,
          copying: true,
          annotating: false,
          fillingForms: false
    },
    info: {
    	title: emissionResult.inspectionCategoryCd==="EMS"?"VIC Report Card - HD Commercial Vehicle Emissions":"VIC Report Card - Safety",
    	author: 'Parsons',
    	subject: 'subject of document',
    	keywords: 'keywords for document',
    },

  	content: [
      {
        columns: [
          {image: emissionResult.aLocale==="en"?constantParmeter.base64LogoEn:constantParmeter.base64LogoFr,
            width: 267,
            height: 56,
            margin: [ 154, 0, 0, 0 ]
          }
        ]
      },
      {
        columns: [
          {
            text:
            [
              { text:(emissionResult.aLocale==="en"?"VIC Report Card - ":"Bulletin de notes du CIV - ") },
              { text:(emissionResult.aLocale==="en"?(emissionResult.inspectionCategoryCd==="EMS"?"HD Commercial Vehicle Emissions":"Safety"):(emissionResult.inspectionCategoryCd==="EMS"?"Émissions des véhicules utilitaires lourds":"Sécurité"))},
            ], alignment: 'center', margin: [ 0, 0, 0, 0 ], fontSize: 11, bold: true
          }
        ],

      },
      {
        columns: [
          { text: (emissionResult.aLocale==="en"?emissionResult.reportDate:emissionResult.reportDateFr), alignment: 'center', margin: [ 0, 0, 0, 0 ], fontSize: 9 },
        ],
      },
      {
        text: '\n', lineHeight: 1.5
      },

      {
        columns: [
          {
            table: {
              headerRows: 1,
              width: '100%',
              widths: ['96%'],
              body: [
                [
                  { text: emissionResult.aLocale==="en"?"VEHICLE INSPECTION CENTRE (VIC) INFORMATION":"RENSEIGNEMENTS DU CENTRE D’INSPECTION DES VÉHICULES (CIV)", style: 'bodyText', bold: 'true', fontSize: 9, fillColor:"#81D3F8", alignment: 'left'},
                ],
                [
                  {
                    table: {
                      headerRows: 1,
                      width: '100%',
                      style: 'bodyText',
                      widths: ['12%','20%','10%','22%','20%','12%'],
                      body: [
                        [
                          { text: emissionResult.aLocale==="en"?"VIC ID:":"ID CIV:", style: 'bodyText', fontSize: 9, bold: 'true', alignment: 'right'},
                          { text: emissionResult.stationId, style: 'bodyText', fontSize: 9 },
                          { text: emissionResult.aLocale==="en"?"ADDRESS:":"ADRESSE:", style: 'bodyText', fontSize: 9, bold: 'true',  alignment: 'right'},
                          { text: emissionResult.address, style: 'bodyText', fontSize: 9 },
                          { text: emissionResult.aLocale==="en"?"VIC PEER GROUP:":"GROUPE DE PAIRS DU CIV:", style: 'bodyText', fontSize: 9, bold: 'true', alignment: 'right'},
                          { text: emissionResult.aLocale==="en"?emissionResult.testVolumeDesc:emissionResult.testVolumeDescFr, style: 'bodyText', fontSize: 9 }
                        ],
                        [
                          { text: emissionResult.aLocale==="en"?"VIC NAME:":"NOM DU CIV:", style: 'bodyText', fontSize: 9, bold: 'true', alignment: 'right'},
                          { text: emissionResult.stationName, style: 'bodyText', fontSize: 9},
                          { text: emissionResult.aLocale==="en"?"CITY:":"VILLE:", style: 'bodyText', fontSize: 9, bold: 'true', alignment: 'right'},
                          { text: emissionResult.city, style: 'bodyText', fontSize: 9},
                          { text: emissionResult.aLocale==="en"?"VIC TYPE:":"TYPE DE CIV:", style: 'bodyText', fontSize: 9, bold: 'true', alignment: 'right'},
                          { text: emissionResult.stationTestClass, style: 'bodyText', fontSize: 9}
                        ],
                      ]
                  },layout: 'noBorders'
                  },
                ],
              ]
            }, margin: [ 20, 0, 0, 0 ],
          },
        ],
      },
      {
        text: '\n', lineHeight: 1
      },
      {
        columns: [
          {
            table: {
              headerRows: 1,
              width: '100%',
              style: 'bodyText',
              widths: ['46%','25%', '25%'],
              body: adRow,
            }, margin: [ 20, 0, 0, 0 ],
          },
        ],
      },
      {
        text: '\n', lineHeight: 1
      },
      {
        columns: [
          {

            table: {
              headerRows: 1,
              width: '100%',
              style: 'bodyText',
              widths: ['42%','14%','12%','16%','12%'],
              body: pmRow,
            }, margin: [ 20, 0, 0, 0 ],
          },
        ],
      },
      {
        text: '\n', lineHeight: 1
      },

      {
        width: '100%',
        columnGap: 0,
        columns: [
          {
            table: {
              headerRows: 1,
              width: '100%',
              style: 'bodyText',
              widths: ['84%', '12%'],
              body: [
                [
                  { text: emissionResult.aLocale==="en"?"VEHICLE INSPECTION CENTRE PERFORMANCE":"RENDEMENT DU CENTRE D’INSPECTION DES VÉHICULES", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, true, true], color:'white', fillColor:"#1b3349", alignment: 'center', margin: [ 2, 0, 0, 0 ]},
                  { text: emissionResult.aLocale==="en"?"GRADE":"NOTE", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, true, true, true], alignment: 'center', margin: [ 2, 0, 0, 0 ]},
                ],
                [
                  { text: emissionResult.aLocale==="en"?"OVERALL GRADE":"NOTE GLOBALE", style: 'bodyText', fontSize: 9, bold: 'true', border: [true, false, true, true], alignment: 'left', margin: [ 2, 0, 0, 0 ]},
                  { text: emissionResult.overallPerformanceGrade, style: 'bodyText', color: 'white', bold: 'false', border: [true, true, true, true] , fontSize: 9, fillColor:emissionResult.overallPerformanceGrade=='A'?"#2B8737":emissionResult.overallPerformanceGrade=='B'?"#0369AC":emissionResult.overallPerformanceGrade=='C'?"#8A600D":emissionResult.overallPerformanceGrade=='D'?"#D81A21":"#FFFFFF", alignment: 'center'},
                ],
              ]
          }, margin: [ 20, 0, 0, 0 ],
          },
        ],
      },
      {
        text: '\n', lineHeight: 1.5
      },
      {
        width: '96%',
        columnGap: 0,
        columns: [
         { text: emissionResult.aLocale==="en"?"Report Card Definitions":"Définitions du bulletin de notes", style: 'bodyText', fontSize: 9, bold: 'true', border: [false, false, false, false] , alignment: 'center', decoration: 'underline', margin: [ 5, 0, 0, 0 ]},
        ]
      },
      {
        columns: [
          { text: emissionResult.aLocale==="en"?"VIC Peer Group":"Groupe de pairs du CIV", style: 'bodyText', fontSize: 9, bold: 'true', alignment: 'left', margin: [ 20, 0, 0, 0 ] },
        ]
      },
      {
        columns: [
          { text: emissionResult.aLocale==="en"?"VICs are categorized into Peer Groups based on the reporting cycle test volumes. Your VIC performance is compared to averages from your Peer Group.":"Les CIV sont catégorisés en groupes de pairs selon les volumes de tests réalisés durant le cycle d’établissement de rapports. Le rendement de votre CIV est comparé aux moyennes de votre groupe de pairs.", alignment: 'left', margin: [ 20, 0, 0, 0 ], style: 'bodyText',fontSize: 9},

        ],
      },
      {
        text: '\n', lineHeight: 1
      },
      {
        columns: [
          {
            table: {
              headerRows: 1,
              width: '100%',
              style: 'bodyText',
              widths: ['48%','48%'],
              body: tvRow,
            }, margin: [ 20, 0, 0, 0 ],
          },
        ],
      },
      {
        text: '\n', lineHeight: 1
      },
      {
        width: '100%',
        columnGap: 0,
        columns: [
         { text: emissionResult.aLocale==="en"?"Report Card Grades":"Notes du bulletin de notes", style: 'bodyText', fontSize: 9, bold: 'true', border: [false, false, false, false] , alignment: 'center', decoration: 'underline', margin: [ 5, 0, 0, 0 ]},
        ]
      },
      {
        columns: [
          { text: emissionResult.aLocale==="en"?"Your Grades are determined as follows:":"Vos notes sont déterminées comme suit:", style: 'bodyText', fontSize: 9, bold: 'true', alignment: 'left', margin: [ 20, 0, 0, 0 ] },
        ]
      },
      {
        text: '\n', lineHeight: 1
      },
      {
        columns: [
          {
            table: {
              headerRows: 1,
              width: '100%',
              style: 'bodyText',
              widths: ['20%','76%'],
              body: pgRow,
            }, margin: [ 20, 0, 0, 0 ],
          },
        ],
      },
      {
        text: '\n', lineHeight: 1
      },
      {
        width: '100%',
        columnGap: 0,
        columns: [
          {
            text:(emissionResult.aLocale==="en"?(emissionResult.inspectionCategoryCd==="EMS"?"Emission Definitions":"Safety Definitions"):(emissionResult.inspectionCategoryCd==="EMS"?"Définitions des émissions":"Définitions de sécurité")), style: 'bodyText', fontSize: 9, bold: 'true', border: [false, false, false, false] , alignment: 'center', decoration: 'underline', margin: [ 5, 0, 0, 0 ],
            id: 'definitions'
          },
        ]
      },
      {
        text: '\n', lineHeight: 1
      },
      {
        width: '100%',
        columnGap: 0,
        columns: [
          {
            table: {
              headerRows: 1,
              dontBreakRows: true,
              width: '100%',
              style: 'bodyText',
              widths: ['4%','20%','24%','24%','24%'],
              body: pdRow,
          }, margin: [ 20, 0, 0, 0 ],
          },
        ],
      },

    ],
    pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      if (currentNode.id === 'definitions' && emissionResult.inspectionCategoryCd === "SAF") {
        return true;
      }
      return false;
    },

    defaultStyle: {
      fontSize: 9,
    }
  }

  return docDefinition;
}

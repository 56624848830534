    <fieldset class="form-group" style="border: solid; border-width: thin; border-color: #d3cdcd; padding: 10px;">
      <legend i18n style="width:auto; border:0px; margin: 0px; font-size: 14px; font-weight: bold;"></legend>
      <p style="text-align: center; font-size: large; " mat-dialog-title>
        <b>{{dialogData.title}}</b>
      </p><br/>
      <label>{{dialogData.reason}}</label><br/><br/>
      <div *ngIf="dialogData.auditId!=null">
        <label i18n>The Audit report can be viewed</label> 
        <a i18n [routerLink]="" (click)="showReport()" (keyup.enter)="showReport()" style="cursor: hand !important"> here</a><br/><br/>
      </div>
      <label *ngIf="dialogData.id!=null"><span>Lockout reason</span>: {{dialogData.lockoutReason}}</label><br/>
      <label *ngIf="dialogData.id!=null">{{dialogData.id}}</label><br/>
      <label *ngIf="dialogData.name!=null">{{dialogData.name}}</label><br/>
      <label *ngIf="dialogData.auditId!=null"><span>Audit ID</span>: {{dialogData.auditId}}</label><br/>
      <label *ngIf="dialogData.auditId!=null"><span>Audit Date</span>: {{dialogData.auditDate | date:'yyyy/MM/dd HH:mm a'}}
        <br/><br/><br/><br/>
      </label>
      <mat-dialog-content [innerHTML]="dialogData.additionalMessage">
      </mat-dialog-content><br/><br/>
  <!--     <label>{{dialogData.action}}</label><br/><br/>
      <label i18n>Call the Vehicle Inspection Centre Assistance Line at 1 (833) 420-2110</label><br/>
      <label i18n>8:00 am to 6:00 pm (ET) Monday to Friday (excluding holidays)</label><br/>
      <label i18n>9:00 am to 3:00 pm (ET) Saturday</label><br/><br/> --><br/><br/><br/>
      <mat-checkbox i18n *ngIf="dialogData.auditId!=null" [(ngModel)]="dialogData.acknowledged">
        By checking this box and clicking "Close" I acknowledge that I understand this notice
      </mat-checkbox>
      <br/><br/>
      <button i18n class="mat-raised-button" cmpntId="LOGIN.ComplianceDialog.close" 
        [id]="dialogData.ok" [disabled]="!dialogData.acknowledged && dialogData.auditId!=null"
        [mat-dialog-close]="dialogData">Close
      </button><br/><br/>
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router, ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import { CustomDialogComponent } from 'apps/common/components/custom-dialog/custom-dialog.component'
import {environment} from 'apps/common/environments/environment';
import { CommonService} from 'apps/common/services/common.service';
import { LoginService } from '../../../services/login.service';
import {getLocale, isBlank} from 'apps/common/helper/util-function-class';
import {translateMessage} from 'apps/common/helper/service-messages';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.css']
})

export class LoginScreenComponent implements OnInit {
  @ViewChild('newsLettersTable', { read: MatSort, static: true }) newsLettersTableSort: MatSort;
  @ViewChild("enrolmentInfoDlg") enrolmentInfoDlg:any;
  version: string = environment.version ;
  env: string = environment.env ;
  newsLetterFolder = 'on-esafety-pdf/docs/newsletters/';
  latestNewsLetter:any = null;
  showEnrolmentInfo:boolean = false;
  customDialogConfig:MatDialogConfig = new MatDialogConfig();
  title = 'on-login-web';
  userId = '' ;
  password = '' ;
  message = '' ;
  errorMessage = '';

  dialogConfig:MatDialogConfig = null;
  dialogData: any = {};
  resetPasswordRequest:any = {};

  showNewsLettersDialog = false ;

  newsLettersColumns = ['fileName'];
  newsLettersDataSource = new MatTableDataSource([]);
  isBlank = isBlank;

  msgServiceError = $localize`Oops, something went wrong! Please try again later.`;
  lblUserId = $localize`User ID`;
  lblEmail = $localize`Email Address`;
  lblCancel = $localize`Cancel`;
  lblSubmit = $localize`Submit`;

  showSSO = false;
  sentRequest = false;

  constructor(
    public service: LoginService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService
    ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.showSSO = params['sso'] === "sso" && this.commonService.isKeycloakUp();
    });

    this.userId = this.commonService.getUserId();
    if( this.userId != null) {
      this.commonService.logout();
    }else {
      this.customDialogConfig.ariaLabel=" ";
      this.customDialogConfig.autoFocus= false;
      this.dialogConfig = new MatDialogConfig();
      this.dialogConfig.ariaLabel=" ";
      this.dialogConfig.autoFocus= false;
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = true;
      this.dialogConfig.width = '540px';
  
  //    if(this.showSSO || this.env != 'DEV') {
        this.commonService.ssoLogin();
  //    }
    }
  }

  ssoLogin() {
    this.commonService.ssoLogin();
  }

  login() {
    this.sentRequest = true;
    const loginRequest = {'userId': this.userId, 'pwd': this.password};
    this.service.login(loginRequest, this.loginCallBack, this.loginError);
  }

  loginCallBack = (result:any) => {
    this.sentRequest = false;
    if (result.userStatusCd === 'E') {
      if (result.pwdResetRequired == 'Y') {
        //change temp password
        this.changePassword(result.token);
      } else {
        this.continueLogin(result.token);
      }
    } else if (result.userStatusCd === 'SN') {
      this.router.navigate(['/station-enroll']);
    }else {
      if (result.userStatusCd === 'SP') {
        alert($localize`Please wait for approval of your enrolment.`);
      } else if (result.userStatusCd === 'SX') {
        alert($localize`Your station enrolment is denied.`);
      } else if (result.userStatusCd === 'SC') {
        alert($localize`Your station is closed.`);
      } else if (result.userStatusCd === 'SL') {
        alert($localize`Your station is locked.`);
      } else if (result.userStatusCd === 'SS') {
        alert($localize`Your station is suspended.`);
      } else {
        alert($localize`Unknown status for your account.`);
      }
    }
  }

  changePassword(token){
    this.commonService.pwdResetRequired = true;
    this.commonService.setAuthToken(token);
    this.commonService.setSessionTimeout();
    this.router.navigate(['/changePassword']);
  }

  continueLogin(token) {
      this.commonService.setAuthToken(token);
      this.commonService.setSessionTimeout();
      this.router.navigate(['/home']);
  }

  saveSecurityQuestionCallback = (result: any) => {
    this.router.navigate(['/home']);
  }

  saveSecurityQuestionErrorCallback = (result: any) => {
    this.commonService.logout();
    alert(this.msgServiceError);
  }

  loginError = (error:any) => {
    this.sentRequest = false;
    if( error !== null && error.error !== null && error.error.serviceMessage !== undefined && error.error.serviceMessage != null ) {
      const serviceMessage = error.error.serviceMessage;
      this.message = translateMessage(serviceMessage.serviceName, serviceMessage.msgId, serviceMessage.msgValues);
    } else {
      this.message = this.msgServiceError;
      console.log(error.message + '(response code: ' + error.status + ')');
    }
  }

  resetForm() {
    this.userId = '' ;
    this.password  = '' ;
    this.message = '';
  }

  registerStationUser() {
    this.router.navigate(['/station-user-register-step1']);
  }

  forgetPassword = () => {

    this.message = '';

    this.dialogData = { type:'forget-password-SearchId', title:$localize`Please enter your User ID or Email Address`, userIdField:this.lblUserId, emailField:this.lblEmail, cancel:this.lblCancel, ok:this.lblSubmit};

    this.dialogConfig.data = this.dialogData;
    const dialogRef = this.dialog.open(CustomDialogComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe(dialogData => {
      if (dialogData != null) {
        this.resetPasswordRequest.userId = dialogData.userId;
        this.resetPasswordRequest.emailAddress =  dialogData.email;
        const searchId = dialogData.userId != null?dialogData.userId:dialogData.email;
        this.service.getSecQuestion(searchId, this.getSecAnswer, this.showRequestErrorMessage);
      }
    });
  }

  getSecAnswer =  (result:any) => {
    if( result.name === 'multipleUsers' ) {
      if( result.value === 'Y' )
        this.dialogData = { type:'forget-password-SearchId',title:$localize`Multiple user records are found for your email. Please provide your user id`, userIdField:this.lblUserId, emailField:this.lblEmail, emailFieldDisabled:'Y', cancel:this.lblCancel, ok:$localize`Submit`};
      else
        this.dialogData = { type:'forget-password-SearchId',title:$localize`No user found. Please enter your User ID or Email Address`, userIdField:'User Id', emailField:this.lblEmail, cancel:this.lblCancel, ok:$localize`Submit`};

      this.dialogConfig.data = this.dialogData;
      const dialogRef = this.dialog.open(CustomDialogComponent, this.dialogConfig);
      dialogRef.afterClosed().subscribe(dialogData => {
        if (dialogData != null) {
          this.resetPasswordRequest.userId = dialogData.userId;
          this.resetPasswordRequest.emailAddress =  dialogData.email;
          const searchId = dialogData.userId != null?dialogData.userId:dialogData.email;
          this.service.getSecQuestion(searchId, this.getSecAnswer, this.showRequestErrorMessage);
        }
      });
    }else {
      const secQuestion = result.value.split(';');
      if(secQuestion[1] == 'X') {
        this.dialogData = { type:'warning-dialog', message:$localize`Sorry, your account is deleted. Please click on the \"Register as a new user\" button to create a new account.`, ok:$localize`Close`};
        this.dialogConfig.data = this.dialogData;
        this.dialog.open(CustomDialogComponent, this.dialogConfig);
      }else{
        this.dialogData = { type:'forget-password-Security-Question',title:$localize`Please enter the answer for your security question`, securityQuestionField:$localize`Security Question`, securityQuestion:secQuestion[0], securityQuestionAnswerField:$localize`Security Question Answer`,  cancel:this.lblCancel, ok:$localize`OK`};

        this.dialogConfig.data = this.dialogData;
        const dialogRef = this.dialog.open(CustomDialogComponent, this.dialogConfig);
  
        dialogRef.afterClosed().subscribe(dialogData => {
          if (dialogData != null) {
            this.resetPasswordRequest.securityQuestionAnswer = dialogData.securityQuestionAnswer;
            this.service.resetPassword(this.resetPasswordRequest, this.resetPasswordSuccess, this.showRequestErrorMessage);
          }
        });
      }

    }

  }

  resetPasswordSuccess = () => {
    this.errorMessage = null;
    this.message = $localize`Your password has been successfully reset and sent to your email. Please check your email and change your password in 5 days.`;
  }

  showRequestErrorMessage = (error:any) => {
    this.message = null;
    this.errorMessage = error.error + '(response code: ' + error.status + ')';
    alert(this.errorMessage);
  }

  openInformationSessionSchedule() {
    var url = window.location.href;
    if (url.indexOf('on-login-web/fr') > -1) {
      window.open("assets/docs/FR/DriveON_Information_Sessions_Schedule_FR.pdf");
    }else{
      window.open("assets/docs/EN/DriveON_Information_Sessions_Schedule.pdf");
    }
    
  }

  openSignUpPdf() {
    window.open("assets/docs/signup_information.pdf");
  }

  openNewApplicationPdf() {    
    window.open("assets/docs/new_applicants.pdf");
  }

  showErrorMsg = (error:any) => {
    this.spinnerService.hide();
    if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    }else if( error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0 ) {
      console.error(error.error.msg);
      alert(this.msgServiceError);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert(this.msgServiceError);
    }
  }
}

import { Component, ViewChild, Input} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { CommonService } from '../services/common.service';
import { Subscription } from 'rxjs';
import { getLocale } from '../helper/util-function-class';

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})

export class MainNavComponent {
  @Input() appName:any;
  @Input() version: string;

  env: string = environment.env;
  userName: string = '';
  appUrl:string = '';
  sliceCount = 100;
  txtVic:string = '';
  txtPortal:string = '';
  isShowScrollMenu = true;
  subscription: Subscription;
  showTrigger:boolean=true;
  switchLanguage: string;
  switchLocale: string;
  contextRoot: string;

  currentUrl: any = '';
  showMainNav:boolean = false;

  @ViewChild('drawer', {static: false}) drawer ;

  isHandset$: Observable<boolean> = null;

  public static generateLink (name: string, link: string, external = false, roles = [] ): any {
    return { 'name': name, 'link': link, 'roles': roles, 'isExternal': external, 'display': true} ;
  }

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, public commonService: CommonService) {
    this.isHandset$ = this.breakpointObserver.observe(['(max-width: 768px)'])
    .pipe(
      map(result => result.matches)
    );

    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url != '') {
          this.currentUrl = e.url;
        } 
        else {
          this.currentUrl = '';
        }
      }
    });      
  }

  ngOnInit(): void {
    const uri = window.location.pathname.split('/');
    var url = window.location.href;
    if (url.indexOf('realtime-audit-entry') > -1 || url.indexOf('focused-record-audit') > -1
    || url.indexOf('data-record-audit') > -1 || url.indexOf('annual-safety-full-inspection-audit') > -1
    || url.indexOf('directed-by-ministry-audit') > -1 || url.indexOf('post-test-interview-audit') > -1
    || url.indexOf('report-card-audit') > -1 || url.indexOf('structural-inspection-audit') > -1) {
      this.showTrigger = false;
    }
    const currentLocale = uri[2];
    if(uri[4] !== undefined ) {
      this.contextRoot = uri[1]+'/#/'+uri[4];
    }
    else
      this.contextRoot = uri[1];
    if (currentLocale == 'en' ) {
      this.switchLocale = 'fr';
      this.switchLanguage = 'Français';
    }else {
      this.switchLocale = 'en'
      this.switchLanguage = 'English';
    }

    this.appUrl = window.location.href;
    this.userName = this.commonService.getUserName();

    this.version = this.version==null||this.version.length==0?environment.version:this.version;

    if( this.commonService.isLoggedIn()) {
      if (!this.commonService.skipLoadPageControls) {
        this.commonService.loadPageControls();
      }
    }else {
      this.commonService.logout();
    }
    this.txtVic = $localize`DriveON` + ' -\u00A0  ';
    this.txtPortal = '\u00A0' + $localize`Portal`;

    if(this.appUrl.includes('on-login-web')) {
      this.appName = $localize`Integrated Vehicle Safety and Emissions Inspection Program`;
      this.txtVic = '';
      this.txtPortal = '';
      this.isShowScrollMenu = false;
    } else if(this.appUrl.includes('on-uma-web')) {
      this.appName = $localize`User Management`;
    } else if(this.appUrl.includes('on-stationadmin-web')) {
      this.appName =  $localize`Inspection Centre Administration Application`;
    } else if(this.appUrl.includes('on-tmq-web')) {
      this.appName =  $localize`Training`;
    } else if(this.appUrl.includes('on-icpaa-web')) {
      this.appName = $localize`Inspection Centre Personnel Administration`;
    } else if(this.appUrl.includes('on-vca-web')) {
      this.appName = $localize`Vehicle Control Application`;
    } else if(this.appUrl.includes('on-amt-web')) {
      this.appName = $localize`Audit Management and Trigger`;
    } else if(this.appUrl.includes('on-fcra-web')) {
      this.appName = $localize`Inspection Fees Collection and Reconciliation Application`;
    } else if(this.appUrl.includes('on-epba-web')) {
      this.appName = $localize`Equipment Purchasing Billing Application`;
    }else if(this.appUrl.includes('on-epa-web')) {
      this.appName = $localize`Equipment Purchase Application`;
    } else if (this.appUrl.includes('on-sticker-web')) {
      this.appName = $localize`Sticker Management Application`;
    }

    this.showMainNav = this.commonService.pwdResetRequired == false;
  }

  skipToMain() {
    if( this.currentUrl.endsWith("main") )
      this.router.navigateByUrl(this.currentUrl);
    else
      this.router.navigateByUrl(this.currentUrl+"#main");
  }

  // onSwitchLanguage() {
  //   const uri = window.location.href.split('/');
  //   if( uri[2]==='fr' ) {
  //     this.switchLocale = 'en'
  //     this.switchLanguage = 'English';
  //   }
  //   else {
  //     this.switchLocale = 'fr';
  //     this.switchLanguage = 'Français';
  //   }
  //   uri[2] = this.switchLocale;
  //   window.location.href = '/' + uri.join('/');
  //   console.log(window.location.href);
  //   return false;
  // }

  switch() {
    const to = "/"+this.switchLocale;
    var from = "/en";
    if (this.switchLocale === 'fr') {
      this.switchLocale = 'fr';
      this.switchLanguage = 'Français';
    }
    else {
      from = '/fr';
      this.switchLocale = 'en'
      this.switchLanguage = 'English';
    }
    const href = window.location.href.replace(from, to);
    window.location.href = href;
    return false;
  }

  showScrollMenu() {
    setTimeout(() => this.showMenu(), 100);
  }

  showMenu() {
    this.isShowScrollMenu = true;
  }

  goHome(){
    if (this.commonService.pwdResetRequired) {
      this.commonService.logout();
    }
    else {
      this.commonService.goToHomePage();
    }
  }

  goDashboard() {
    window.open(window.location.protocol+"//"+window.location.host+"/on-login-web/en/#/dashboard/main");
  }

  navigateToApp(item, sub){
    if(this.drawer != null)
      this.drawer.close();
    if(item && sub) {
      const link = item.link + sub.link;
      window.location.href = link;
      this.showScrollMenu();
    }
  }

  gotoUrl(url: string, isExternal: boolean) {
    if(this.drawer != null)
      this.drawer.close();
    if( isExternal) {
      window.location.href = url;
    }else {
      this.router.navigateByUrl(url);
    }
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements OnInit {

  @Input() placeholder = 'placeholder';
  @Output() inputValueChange = new EventEmitter();
  @Input() inputstyle: string;
  @Input() appwidth = '30%' ;
  @Input() inputdisabled = false;
  @Input() required = false ;
  @Input() maxlength = 250 ;
  @Input() minlength = 1 ;
  @Input() rows = 3;
  @Input() cmpntId = null ;
  @Input() style = '';
  @Input() labelColorRequired:boolean = false;
  _inputValue = ' ' ;

  @Input()
  set inputValue(inputValue: any) {
    this._inputValue =  inputValue ;
  }

  get inputValue() {
    return this._inputValue ;
  }

  constructor(public commonService: CommonService) {
  }

  ngOnInit() {
  }

  update() {
    this.inputValueChange.emit(this.inputValue);
  }

  formatValue(value: any) {
    return value ;
  }

  handleKeyEnter(event) {
    event.preventDefault();   
  }  
  

}

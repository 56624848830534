<ngx-spinner bdColor= "rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating" [fullScreen]="true">
	<p i18n style="color: white">Loading...</p>
</ngx-spinner>
<app-form-panel i18n-applabel applabel="Vehicle Inspection Centre User Registration">
  <app-card apptitle="{{pageLabel}}"> 
    <div style="padding-left: 22px;">
      <mat-error *ngIf="message != null && message != ''" ><span innerHtml="{{message}}"></span></mat-error>
    </div>
    <p i18n style="font-weight: bold; font-size: 16px">Create a security question and answer. Information marked with * are required fields.</p>
    <app-input-field i18n-placeholder placeholder="Security Question"
      cmpntId="LOGIN.RegistrationPage3.SecurityQuestion" #question [required]="true"
      appwidth="100%" [(inputValue)]="userProfile.securityQuestion">
    </app-input-field>
    <br><br>
    <app-input-field i18n-placeholder placeholder="Security Question Answer"
      cmpntId="LOGIN.RegistrationPage3.SecurityQuestionAnswer" #answer [required]="true"
      appwidth="100%" [(inputValue)]="userProfile.securityQuestionAnswer">
    </app-input-field>
    <p i18n>
      <b>Click on the Register button to finish the registration</b>. Please check your spam folder in case you don't see your temporary password.
    </p>
    <p i18n>
      Security answer is not case sensitive.
    </p>
  </app-card>
  <br/><br/><br/>
  <app-padded-panel>
    <button i18n id="LOGIN.RegistrationPage3.Previous" mat-raised-button routerLink="/station-user-register-step2">Previous</button>&nbsp;
    <button i18n id="LOGIN.RegistrationPage3.CreateAccount" mat-raised-button (click)="submit()"
      [disabled]="invalidInputs()">Register</button>&nbsp;
    <button i18n id="LOGIN.RegistrationPage3.Cancel" mat-raised-button routerLink="/login/sso">Cancel</button>&nbsp;
    <br /><br />&nbsp;
    </app-padded-panel>
    <app-padded-panel>&nbsp;</app-padded-panel>
  <app-form-panel>

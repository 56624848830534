import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
//        style({ transform: 'scale3d(.3, .3, .3) translateX(300%)' }),
        style({ transform: 'translateX(150%)' }),
        animate(300)
      ]),
      transition('* => void', [
//        animate(600, style({ transform: 'scale3d(.0, .0, .0) translateX(0%)' }))
        animate(300, style({ transform: 'translateX(150%)' }))
      ])
    ])
  ]
})
export class DialogComponent implements OnInit {
  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("innerInput") innerInput: ElementRef;
  focussed = false;

  constructor() { }

  ngOnInit() { }

  focus() {
    this.innerInput.nativeElement.focus();
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}

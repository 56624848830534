import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { CommonService } from '../../services/common.service';
import { isBlank } from 'apps/common/helper/util-function-class';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-multi-select-field',
  templateUrl: './multi-select-field.component.html',
  styleUrls: ['./multi-select-field.component.css']
})
export class MultiSelectFieldComponent implements OnInit {
  @ViewChild('formControl') formControl:any;
  @Input() appvalue:any;
  @Output() appvalueChange = new EventEmitter();
  @Input() placeholder: string;
  @Input() appLabel: string;
  @Input() appoptions: any[] = [];
  @Input() appwidth = '';
  @Input() errorMessage = null;
  @Input() required = false;
  @Input() isvalid = true;
  @Input() disabled = false ;
  @Input() cmpntId = null ;
  @Input() areaLabel = null ;
  @Input() selectFieldClass = 'select-field' ;
  @Output() change = new EventEmitter() ;
  @Output() isvalidChange =  new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Input() helpIconName = null;
  @Input() toolTipText: string;
  isBlank = isBlank;

  //@ViewChild('parsonsSelect', {static: true})  parsonsSelect : MatSelect ;
  @ViewChild("mySel") skillSel: MatSelect;

  allSelected = false;

  public isReady: boolean ;

  //public formControl = new FormControl();

  constructor(public commonService: CommonService) { }

  ngOnInit() {
    const _this = this;
    this.setIsReady();
  }

  private setIsReady() {
    this.isReady = this.formControl && this.formControl.valid  && this.appvalue != null ;
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }

  update() {
    this.isvalid = this.formControl.valid ;
    this.appvalueChange.emit(this.appvalue);
    this.isvalidChange.emit(this.formControl.valid);
    this.setIsReady();
  }

  isArray(obj: any) {
    return obj instanceof  Array;
  }

  toggleAllSelection(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      // The "Select all" item has the value 0
      .filter((item: MatOption) => item.value === 0)
      // Get the selected property (this tells us whether Select all is selected or not)
      .map((item: MatOption) => item.selected)[0];
    // Get the first element (there should only be 1 option with the value 0 in the select)

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
    console.log(this.appvalue);
  }
}


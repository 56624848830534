import { Component, OnInit } from '@angular/core';
import {RouterWrapperService} from '../../../../services/router-wrapper.service';
import {LoginService} from '../../../../services/login.service';
import {CommonService} from 'apps/common/services/common.service';
import { isEmpty} from 'apps/common/helper/util-function-class';

@Component({
  selector: 'station-user-registration-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})
export class StationUserRegistrationStep1Component implements OnInit {

  userProfile: any = {};
  noticeTitle:string = null;
  noticeText:string = null;

  constructor(private router: RouterWrapperService, public service: LoginService, private commonService: CommonService) {
  }

  ngOnInit() {
    this.commonService.userProfile = {step:'station-user-register-step1'};
    this.service.getScreenNotice(2, this.getScreenNoticeCallback);
  }

  getScreenNoticeCallback = (result:any) =>{
    if (isEmpty(result)) {
      console.log(`Not able to getScreenNotice for noticeId=2` );
    } else {
      this.noticeTitle = result.noticeTitle;
      this.noticeText = result.noticeText;
    }
  }

  next(userType:number) {
    this.commonService.userProfile = {organizationId:this.service.IC_USER, departmentId:userType};
    this.router.gotoUrl('station-user-register-step2');
  }

}



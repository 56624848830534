import { NgModule, APP_INITIALIZER } from '@angular/core';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initKeycloak } from '../services/init-keycloak-service';
@NgModule({
  declarations: [],
  imports: [KeycloakAngularModule],
  providers: [
    {
        provide: APP_INITIALIZER,
        useFactory: initKeycloak,
        multi: true,
        deps: [KeycloakService]
    }
  ]
})
export class KeycloakModule { }

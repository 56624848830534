import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'login-training-videos',
  templateUrl: './training-videos.component.html',
  styleUrls: ['./training-videos.component.css']
})
export class TrainingVideosComponent implements OnInit {
  trainingVideoList: any = [];
  hostname: string;
  fileCatgoryCount: number;  
  constructor(private service: LoginService,public spinnerService: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.service.getPortalTrainingVideos(this.setPortalTrainingVideosCallback, this.showErrorMsg);
  }

  setPortalTrainingVideosCallback = (result: any) => {
    this.spinnerService.hide();    
    if (result && result.length > 0) {
      this.fileCatgoryCount = result.length;
      this.trainingVideoList = result;
    } else {
      var infoArea = document.getElementById('no-videos-list');
      infoArea.textContent = 'No Records found.';
    }
  }

  playVideo(videoName) {
    window.open(window.location.protocol+"//"+window.location.host+"/connect/parsonsvic/"+videoName, '_blank');
    return false;
  }

  showErrorMsg = (error: any) => {
    this.spinnerService.hide();
    if (error.status === 400) {
      alert($localize`Invalid request parameters!`);
    } else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    } else if (error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }
}

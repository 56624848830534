import {Component, OnInit} from '@angular/core';
import {CommonService} from 'apps/common/services/common.service';
import { environment } from 'apps/common/environments/environment';

declare var startChat: Function;

@Component({
  selector: 'aws-chat-field',
  templateUrl: './aws-chat.component.html',
  styleUrls: ['./aws-chat.component.css']
})

export class AwsChatComponent implements OnInit {
  firstName:string = "";
  userId:string = "";
  isLoggedIn:boolean = false;
  onlineModuleUrl = environment.onlineModuleUrl;
  aUrl:string = "";

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.firstName = this.commonService.getUserName();
    this.userId = this.commonService.getUserId();
    this.isLoggedIn = this.commonService.isLoggedIn()?true:false;
  }

  chat() {
    let customerName = this.firstName;
    let userName = this.userId + "-" + new Date().toISOString();
    // this.aUrl = this.onlineModuleUrl + "chat" + "/chat.html?customerName=" + customerName +"&userName=" + this.userName;
    // window.open(this.aUrl, "width=365, height=525,left=0,top=0,toolbar=0,status=0,");
    startChat(customerName, userName);
  }
}

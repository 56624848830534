<ngx-spinner bdColor= "rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating" [fullScreen]="true">
	<p i18n style="color: white" >Loading...</p>
</ngx-spinner>
<div id="dashboardContainer"></div>
<dashboard-dialog #messageDialog class="attention" [visible]="showMessage">
    <div>
        <p tabindex="0">&nbsp;</p>
        <p tabindex="0">{{message}}</p>
        <br><br>
        <button i18n mat-raised-button (click)="close()" tabindex="0">Ok</button>
    </div>
</dashboard-dialog>
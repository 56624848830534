import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CustomDialogComponent } from 'apps/common/components/custom-dialog/custom-dialog.component'
import { CommonService } from 'apps/common/services/common.service';
import {isBlank, getLocale, isValidPhone, isValidEmail} from 'apps/common/helper/util-function-class';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @ViewChild("termConditionsDialog") termConditionsDialog:any;
  @ViewChild("contactUsDialog") contactUsDialog:any;
  @ViewChild("browserCompatibilityDialog") browserCompatibilityDialog:any;
  @ViewChild("phoneNum") phoneNum:any;

  showSupportedBrowsers:boolean = false;
  showTermConditions:boolean = false;
  showContactUs:boolean = false;
  contactUs:any = {};
  disableAddButton = false;
  termConditions = 'assets/term-conditions';
  today: number;
  dialogConfig:MatDialogConfig = null;
  dialogData: any = {};

  constructor(public commonService: CommonService, private dialog: MatDialog) { }

  ngOnInit() {
    this.termConditions = this.termConditions + '-' + getLocale() + '.html';
    this.today = new Date().getFullYear();
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.ariaLabel=" ";
    this.dialogConfig.autoFocus= false;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '540px';
  }

  invalidContactUsInputs = () => {
    const inValidBusPhoneNum = this.phoneNum!=undefined && !isBlank(this.phoneNum.value) && !isValidPhone(this.phoneNum.value);
    const inValidEmail = isBlank(this.contactUs.userEmailAddress) || !isValidEmail(this.contactUs.userEmailAddress);
    return isBlank(this.contactUs.userName) || inValidEmail || inValidBusPhoneNum || isBlank(this.contactUs.messageBody);
  }

  displayTermConditions() {
    this.showTermConditions = true; 
    this.showContactUs = false;    
    this.showSupportedBrowsers = false; 
    setTimeout(() => {
      this.termConditionsDialog.focus();
    }, 200);
  }

  displayContactUs() {
    this.showTermConditions = false; 
    this.showContactUs = true;
    this.showSupportedBrowsers = false;
    setTimeout(() => {
      this.contactUsDialog.focus();
    }, 200);
  }

  displayBrowserCompatibility() {
    this.showTermConditions = false; 
    this.showContactUs = false;
    this.showSupportedBrowsers = true; 
    setTimeout(() => {
      this.browserCompatibilityDialog.focus();
    }, 200);
  }

  addContactUs() {
    this.disableAddButton = true;
    this.commonService.addContactUs(this.contactUs, this.addContactUsCallback, this.showErrorMsg);
  }

  addContactUsCallback = (result:any) => {
    this.disableAddButton = false;
    if (result && result > 0) {
      this.dialogData = { type:'warning-dialog', message:$localize`<b>Thank you for contacting us. </b><br/><br/>You will receive a response within one business day. <br/><br/>If you need immediate assistance please call 
      Vehicle Inspection Centre Assistance Line at 1-833-420-2110`, ok:$localize`Close`};
    }else {
      this.dialogData = { type:'warning-dialog', message:$localize`Failed to submit your message.`, ok:$localize`Close`};
    }

    this.dialogConfig.data = this.dialogData;
    const dialogRef = this.dialog.open(CustomDialogComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe(dialogData => {
      this.contactUs = {};
      this.showContactUs = false;
    });
  }

  showErrorMsg = (error:any) => {
    this.disableAddButton = false;
    if (error.status === 400) {
      alert($localize`Invalid request parameters!`);
    }else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    }else if( error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0 ) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }

}

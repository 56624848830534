import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterWrapperService} from '../../../../services/router-wrapper.service';
import {LoginService} from '../../../../services/login.service';
import {CommonService} from 'apps/common/services/common.service';
import {InputFieldComponent} from 'apps/common/components/input-field/input-field.component';
import {NgxSpinnerService} from 'ngx-spinner';
import { isBlank, isValidStr, isNumeric, isValidPhone, isValidEmail, phoneString2Number, getLocale, clickInputField} from 'apps/common/helper/util-function-class';

@Component({
  selector: 'user-registration-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})

export class UserRegistrationStep2Component implements OnInit {
  userNamePattern = /^[a-zA-Z][a-zA-Z.' ]*$/;

  @ViewChild('confirmEmailAddress', {static: true}) confirmEmailAddressField: InputFieldComponent;
  @ViewChild('verificationCodeField', {static: true}) verificationCodeField: InputFieldComponent;
  @ViewChild("termConditionsDialog") termConditionsDialog:any;
  @ViewChild('mobilePhoneField') mobilePhoneField: any;
  @ViewChild('busPhoneField') busPhoneField: any;

  userProfile: any = {};
  invalidEmailAddr = true;
  usedMobilePhoneNum = true;
  mobilePhoneChecked = false;
  termOfUseChecked = false;
  verificationCode = '' ;
  styleUpperCase = "text-transform:uppercase";
  styleLowerCase = "text-transform:lowercase";
  locales = [
    { code: 'en', value: 'English' },
    { code: 'fr', value: 'Français' },
  ];

  showTermConditions:boolean = false;
  termConditions = 'assets/term-conditions';

  constructor(private router: RouterWrapperService, public service: LoginService, private commonService: CommonService,
              private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    this.commonService.userProfile.step = 'user-register-step2';
    this.termConditions = this.termConditions + '-' + getLocale() + '.html';
    this.userProfile = this.commonService.userProfile ;
    this.userProfile.preferredLanguage = getLocale();
    this.service.getVerificationCode() ;
    this.clear();
  }

  validateEmail(email:string) {
    if (this.confirmEmailAddressField) {
      this.confirmEmailAddressField.message = '';
      this.invalidEmailAddr = true;
      if(isValidEmail(this.userProfile.emailAddress) && !isBlank(this.userProfile.confirmEmailAddress)) {
        if (this.userProfile.confirmEmailAddress.toLowerCase() !== this.userProfile.emailAddress.toLowerCase()) {
           this.confirmEmailAddressField.message = $localize`Email address does not match`;
        }else {
          this.commonService.checkEmailExists(this.userProfile.emailAddress, this.checkEmailCallBack, this.commonService.showErrorMsg);
        }
      }
    }
  }


  checkEmailCallBack = (result:any) =>{
    if (result && result.responseCode !== 0) {
      this.confirmEmailAddressField.message = $localize`Email address has already been registered in the system.`;
    } else {
      this.invalidEmailAddr = false;
      clickInputField('LOGIN.RegistrationPage2.Department')
    }
  }


  validateMobilePhone() {
    if (this.mobilePhoneField !=null) {
      this.usedMobilePhoneNum = true;
      const validPhoneNum = !isBlank(this.userProfile.mobilePhoneNum) && isValidPhone(this.userProfile.mobilePhoneNum);
      if (validPhoneNum) {
        this.commonService.isMobilePhoneExist(this.userProfile.mobilePhoneNum, this.isPhoneExistCallBack, this.commonService.showErrorMsg);
      }
    }
  }
  
  isPhoneExistCallBack = (isPhoneExist:boolean) =>{
    if (isPhoneExist) {
      this.mobilePhoneField.message = $localize`Mobile Phone Number has already been registered in the system.`;
    } 
    else {
      this.mobilePhoneField.message = '';
      if(isBlank(this.busPhoneField.value)){
        clickInputField('LOGIN.RegistrationPage2.Phone');
      }
    }
    this.usedMobilePhoneNum = isPhoneExist;
  }

  changeVerificationCode() {
    this.service.getVerificationCode() ;
  }

  invalidInputs = () => {
    
    if(this.busPhoneField == null || this.mobilePhoneField == null)
      return true;

    if (isBlank(this.mobilePhoneField.value) || !isValidPhone(this.mobilePhoneField.value)) {
      this.mobilePhoneChecked = false;
    }
    else if (!this.mobilePhoneChecked) {
      this.mobilePhoneChecked = true;
      this.commonService.isMobilePhoneExist(this.mobilePhoneField.value, this.isPhoneExistCallBack, this.commonService.showErrorMsg);
    }

    if(this.termOfUseChecked && isBlank(this.busPhoneField.value)) {
      clickInputField('LOGIN.RegistrationPage2.Phone');
    }

    return isBlank(this.userProfile.firstName) || !isValidStr(this.userProfile.firstName, this.userNamePattern)
        || isBlank(this.userProfile.lastName) || !isValidStr(this.userProfile.lastName, this.userNamePattern)
        || (!isBlank(this.userProfile.middleName) && !isValidStr(this.userProfile.middleName, this.userNamePattern))
        || this.invalidEmailAddr
        || isBlank(this.busPhoneField.value) || !isValidPhone(this.busPhoneField.value)
        || (!isBlank(this.userProfile.busPhoneNumExt)  && !isNumeric(this.userProfile.busPhoneNumExt))
        || (!isBlank(this.mobilePhoneField.value) && this.usedMobilePhoneNum)
        || (!isBlank(this.userProfile.faxNum )  && !isValidPhone(this.userProfile.faxNum ))
        || isBlank(this.userProfile.departmentId)
        || isBlank(this.verificationCode) || !this.termOfUseChecked;
  }

  clear() {
    this.userProfile.firstName = '' ;
    this.userProfile.lastName = '' ;
    this.userProfile.middleName = '' ;
    this.userProfile.preferredLanguage = 'en';
    this.userProfile.emailAddress = '' ;
    this.userProfile.confirmEmailAddress = '' ;
    this.userProfile.busPhoneNum = '' ;
    this.userProfile.busPhoneNumExt = '' ;
    this.userProfile.faxNum = '' ;
    this.userProfile.mobilePhoneNum =  '' ;
    this.userProfile.departmentId =  '' ;
    this.userProfile.regionCd =  '' ;
    this.verificationCode = '' ;
    this.termOfUseChecked = false;
  }

  submit() {
    if ( (this.verificationCode.toLowerCase() !== this.service.captcha.captchaValue.toLowerCase())) {
      this.verificationCodeField.message = $localize`Invalid Verification code.` ;
    } else {
      this.userProfile.firstName = this.userProfile.firstName.trim();
      this.userProfile.middleName = this.userProfile.middleName.trim();
      this.userProfile.lastName = this.userProfile.lastName.trim();
      this.userProfile.busPhoneNum = phoneString2Number(this.userProfile.busPhoneNum);
      this.userProfile.mobilePhoneNum = phoneString2Number(this.userProfile.mobilePhoneNum);
      this.userProfile.faxNum = phoneString2Number(this.userProfile.faxNum);
      this.genUserIdCallBack();
    }
  }

  genUserIdCallBack(){
    this.commonService.userProfile.step = 'user-register-step3';
    this.router.gotoUrl('user-register-step3');
  }

  openTermConditions() {
    this.showTermConditions = true;
    setTimeout(() => {
      this.termConditionsDialog.focus();
    }, 200);
  }

}

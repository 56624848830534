// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: 'R20240726.1',
  env: 'DEV',
  production: false,
  idleTime: 900, //15 minutes
  idleSessionTimeout: 300, //5 minutes
  refreshTokenTime: 300, //5 minutes
  
  google_maps_api: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyADrfFacL2619IvD6Pa0t39pYe-bB33n5k&libraries=places&language=',

  //amtApiUrl: 'http://localhost:8080/on-amt-service/api',
  //emisApiUrl: 'http://localhost:8080/on-emis-service/api',
  //epaApiUrl: 'http://localhost:8080/on-epa-service/api'
  //fcraApiUrl: 'http://localhost:9080/on-fcra-service/api',
  //fcraLookupUrl: 'http://localhost:8080/on-fcra-service/lookup',
  //epbaApiUrl: 'http://localhost:8080/on-epba-service/api',
  //epbaLookupUrl: 'http://localhost:8080/on-epba-service/lookup',
  //icaaApiUrl: 'http://localhost:8080/on-icaa-service/api',
  //icpaaApiUrl: 'http://localhost:8080/on-icpaa-service/api',
  //loginApiUrl: 'http://localhost:9080/on-uma-service',
  //lookupApiUrl: 'http://localhost:8080/on-lookup-service/api',
  //lookupAdminApiUrl: 'http://localhost:8080/on-lookup-service/adminapi',
  //onlineModuleUrl: 'http://localhost:8080/online-modules/',
  //tmqApiUrl: 'http://localhost:8080/on-tmq-service/api',
  //reportApiUrl: 'http://localhost:8080/on-report-service/api',
  //umaApiUrl: 'http://localhost:9080/on-uma-service/api',
  //vcaApiUrl: 'http://localhost:8080/on-vca-service/api',
  //fcraReportApiUrl: 'http://localhost:9080/on-report-service/api',
  //mtoApiUrl: 'http://localhost:8080/on-mto-service/api',
  //quicksightApiUrl: 'http://localhost:8080/on-quicksight-service/api',
  //stickerApiUrl: 'http://localhost:8080/on-sticker-service/api',
  //tmqApiUrl: 'http://localhost:9080/on-tmq-service/api',

  amtApiUrl: 'https://dev.on.parsonsvic.com/on-amt-service/api',
  emisApiUrl: 'https://dev.on.parsonsvic.com/on-emis-service/api',
  epaApiUrl: 'https://dev.on.parsonsvic.com/on-epa-service/api',
  epbaApiUrl: 'https://dev.on.parsonsvic.com/on-epba-service/api',
  epbaLookupUrl: 'https://dev.on.parsonsvic.com/on-epba-service/lookup',
  fcraApiUrl: 'https://dev.on.parsonsvic.com/on-fcra-service/api',
  fcraLookupUrl: 'https://dev.on.parsonsvic.com/on-fcra-service/lookup',
  icaaApiUrl: 'https://dev.on.parsonsvic.com/on-icaa-service/api',
  icpaaApiUrl: 'https://dev.on.parsonsvic.com/on-icpaa-service/api',
  loginApiUrl: 'https://dev.on.parsonsvic.com/on-uma-service',
  lookupApiUrl: 'https://dev.on.parsonsvic.com/on-lookup-service/api',
  lookupAdminApiUrl: 'https://dev.on.parsonsvic.com/on-lookup-service/adminapi',
  onlineModuleUrl: 'https://dev.on.parsonsvic.com/online-modules/',
  trainningVideosUrl: 'https://dev.on.parsonsvic.com/connect/parsonsvic/',
  reportApiUrl: 'https://dev.on.parsonsvic.com/on-report-service/api',
  tmqApiUrl: 'https://dev.on.parsonsvic.com/on-tmq-service/api',
  tmqUrl: 'https://dev.on.parsonsvic.com/on-tmq-service',
  umaApiUrl: 'https://dev.on.parsonsvic.com/on-uma-service/api',
  vcaApiUrl: 'https://dev.on.parsonsvic.com/on-vca-service/api',
  fcraReportApiUrl: 'https://dev.on.parsonsvic.com/on-fcra-report-service/api',

  quickSightUrl: 'https://ca-central-1.quicksight.aws.amazon.com',
  zendeskUrl: 'https://parsonsvic1643899207.zendesk.com',
  mtoApiUrl: 'https://dev.on.parsonsvic.com/on-mto-service/api',
  stickerApiUrl: 'https://dev.on.parsonsvic.com/on-sticker-service/api',
  reportCardApiUrl: 'https://dev.on.parsonsvic.com/on-reportcard-service/api',

  icaaUrl: 'https://dev.on.parsonsvic.com/on-icaa-service', 
  emisUrl: 'https://dev.on.parsonsvic.com/on-emis-service',

  ccaApiUrl: 'https://dev.on.parsonsvic.com/on-vca-service',
  loginPageUrl:'https://dev.on.parsonsvic.com/on-login-web',

  keycloak: {
    url: 'https://dev.on.parsonsvic.com/sso',
    realm: 'onvic22',
    clientId: 'on-web',
    redirectHost: 'https://dev.on.parsonsvic.com'
  },

  keycloakPingUrl: 'https://dev.on.parsonsvic.com/sso/realms/onvic22',

  articles: {
    "amt/": "14555472202775",
    "amt/audit-maintenance": "14556569134103",
    "amt/audit-entry": "14556755230359",
    "amt/realtime-audit": "14556755230359",
    "amt/trigger-occurrences": "14556851241879",
    "amt/lockout": "14556917735447",
    "amt/flags": "14557056927127",
    "amt/safety-levels": "14557114047127",
    "amt/data-record-audit": "14556755230359",
    "amt/directed-by-ministry-audit": "14556755230359",
    "amt/report-card-audit": "14556755230359",
    "amt/focused-report-audit": "14556755230359",
    "amt/annual-safety-full-inspection-audit": "14556755230359",
    "amt/structural-inspection-audit": "14556755230359",
    "amt/out-of-province": "15000460513175",
    "epa/": "14070539167767",
    "epa/main-screen": "14116406279831",
    "epa/equipmentStep1": "14116406279831",
    "epa/equipmentStep2": "14116406279831",
    "epa/equipmentStep3": "14116406279831",
    "epa/sel-ic-sttus": "14789155857431",
    "epa/sel-ic": "14789155857431",
    "fcra/": "14117211340695",
    "fcra/station-lockout": "14117216985751",
    "fcra/pad": "14117344378391",
    "fcra/discrepancy": "14117415037079",
    "fcra/report": "14117503575575",
    "icpaa/": "14788331956503",
    "icpaa/technician-info": "14788643786775",
    "icpaa/technician-query": "14124293910551",
    "login/login": "14554590074007",
    "login/home": "14786646003479",
    "login/homeTechnician": "14788331956503",
    "login/changePassword": "14555153736599",
    "login/dashboard/main": "22996392556951",
    "login/dashboard/38830925-8fae-4254-b229-78dcaf729f7a": "23641750791191",
    "login/dashboard/bf93ffe9-f301-4e31-ae18-d36abd439148": "24257636379671",
    "login/dashboard/5e9b0075-8607-46c3-ab25-707f2b7fc5ed": "24257682422551",
    "login/dashboard/60bc2ca6-8e14-4b36-bd97-84a3333dee6d": "24257042564503",
    "login/sheet/0d67ecb2-3c82-400c-872a-53662e7a8e1e":     "25165196021143",
    "login/sheet/96d0163e-55f9-437f-82ca-622046639b1f":     "24800596824599",
    "login/sheet/e33b2e5e-490a-4238-a594-c54f6724e5af":     "24816090060439",
    "login/dashboard/fb880a59-e3e8-4dab-84cd-3ba3d105e299": "24830247118871",
    "login/report-card": "24969737661719",
    "login/dashboard/70db9b39-8886-4e26-ba79-e705c42454c8": "24941352214167",
    "login/dashboard/8f34c21f-bc9c-4b84-8ddb-fdb9389a8884": "24943232996119",
    "login/sheet/a9ecb352-e76e-4c9a-bc23-c462fda22dbb":     "24830247118871",
    "login/dashboard/49724a56-3dd9-49fe-8ec4-c89c7321e68e": "24768355546135",
    "login/dashboard/7a6e3770-581c-47f2-97da-43b6f8a01767": "24769390907159",
    "login/dashboard/a0c65f7d-72d3-4447-ac45-64f3773620a7": "24770144935831",
    "login/dashboard/aaeff8ca-d60b-48ae-9361-039f75ddb870": "24826371561879",
    "login/dashboard/a1b32e62-f50f-4952-ad70-618539c8f04a": "24962843622935",
    "login/dashboard/8465431d-8a2a-49ab-a847-be91d3193931": "24771616585239",
    "login/dashboard/763a20c6-00db-4123-8d9e-5059820d44af": "24827878608407",
    "login/dashboard/38976c74-3294-4163-929f-91a35d690ec8": "24944741167895",
    "login/sheet/0b3ea5ec-303d-4942-8ec7-d50da7c9f4cd":     "24948257769111",
    "login/sheet/68282890-6029-48a1-936b-e5ff41e83f7f":     "24949055355799",
    "login/reports": "14277159315223",
    "login/user-register-step1": "14787834339735",
    "login/user-register-step2": "14787834339735",
    "login/user-register-step3": "14787834339735",
    "login/user-register-step4": "14787834339735",
    "login/station-user-register-step1": "14070557175191",
    "login/station-user-register-step2": "14070557175191",
    "login/station-user-register-step3": "14070557175191",
    "login/station-user-register-step4": "14070557175191",
    "stationadmin/": "14120377583383",
    "stationadmin/inspectionCenterTabs": "14120360988055",
    "stationadmin/icaaLandingPage": "14120377583383",
    "stationadmin/messaging": "14120585279511",
    "stationadmin/tabletMessaging": "14120680827031",
    "stationadmin/changeManagementSearch": "14120769980695",
    "stationadmin/steps": "14121235238039",
    "stationadmin/inspectionCentreOnboardingSteps": "14121339223703",
    "stationadmin/VicChangeManagementDetails": "14123219785495",
    "stationadmin/vicWithdrawal": "14123242786199",
    "stationadmin/sopAndDirectives": "14123541169431",
    "stationadmin/vicDormancyRequest": "14125572732695",
    "stationadmin/inspectionCenterList": "14786642405527",
    "stationadmin/vicChangeManagement": "14123219785495",
    "stationadmin/emailMessaging": "14787597077399",
    "sticker/": "14121897249047",
    "sticker/orderStickers": "14122945911959",
    "sticker/fulfillOrders": "14790783396759",
    "sticker/stickerOverview": "14121897249047",
    "tmq/": "15084465778967",
    "tmq/training-modules": "14554924621591",
    "tmq/training-status": "15084603575319",
    "tmq/training-quiz": "15084701473559",
    "tmq/quiz-details": "15084701473559",
    "tmq/quiz-result": "15084679767575",
    "tmq/result-details": "15084662887319",
    "tmq/training-maintenance": "15084531019927",
    "tmq/training-survey": "15084673522967",
    "uma/": "14786646003479",
    "uma/change-password": "14555153736599",
    "uma/my-profile": "14555171442327",
    "uma/my-roles": "14555172944279",
    "uma/manage-notifications": "14555191859223",
    "uma/maintain-roles": "14555213014807",
    "uma/maintain-users": "14555211138711",
    "uma/maintain-approvers": "14555251306775",
    "uma/clone-user": "14555255512727",
    "vca/": "14360255510423",
    "vca/inspectionTypeDetermination": "14361209776023",
    "vca/specifyVehicleInformation": "14361366815255",
    "vca/snapOpacityMainScreen": "14360865581975",
    "vca/snapOpacityVariablesMainScreen": "14361057582231",
    "vca/snapOpacityVariables": "14361057582231",
    "vca/snapOpacityVariablesException": "14361057582231",
    "vca/snapOpacityCriteria": "14360865581975",
    "vca/snapOpacityCriteriaException": "14360865581975",
    "vca/vehicleInspectionReport": "14360488583191",
    "vca/obdReadinessMainScreen": "14360764424471",
    "vca/obdReadinessCriteria": "14360764424471",
    "vca/obdReadinessCriteriaException": "14360764424471",
    "vca/programVariables": "14360399179927",
    "vca/vehicleSignature": "14360687801111",
  }
};

<br/><br/>
<div style="text-align: center; font-size:x-large; color:lime">
  <div>Unfortuately, you are using an <strong>outdated</strong> browser.</div>
<br/>
<div>Please upgrade your browser to imporve your experince and security.</div>
<br/><br/>
<div>Supported browsers:</div>
</div>
<br/>
<div style="margin-left: 40%; color: white">
  <div><img src="assets/img/chrome.png" alt="Chrome" style="width:50px;height:50px;vertical-align: middle;"><strong> Chrome</strong> version 117 and later</div>
  <br/>
  <div><img src="assets/img/firefox.png" alt="Firefox" style="width:50px;height:50px;vertical-align: middle;"><strong> Mozilla Firefox</strong> version 117 and later</div>
  <br/>
  <div><img src="assets/img/safari.png" alt="Safari" style="width:50px;height:50px;vertical-align: middle;"><strong> Safari</strong> version 16 and later</div>
  <br/>
  <div><img src="assets/img/edge.png" alt="IE Edge" style="width:50px;height:50px;vertical-align: middle;"><strong> Microsoft Edge</strong> version 116 and later</div>
</div>

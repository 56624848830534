import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../services/common.service';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css']
})
export class InputFieldComponent implements OnInit {
  @ViewChild('formControl') formControl:any;

  @Input() placeholder = 'placeholder';
  @Input() inputValue = null;
  @Output() inputValueChange = new EventEmitter();
  @Input() inputstyle: string;
  @Input() appwidth = null ;
  @Input() inputdisabled = false;
  @Input() isdate = false;
  @Input() istime = false;
  @Input() isPassword = false;
  @Input() isEmail = false;
  @Input() required = false;
  @Input() max = null;
  @Input() min = null;
  @Input() mask = null;
  @Input() suffix = '';
  @Input() showMaskTyped:boolean=false;
  @Input() maxlength = 50;
  @Input() minlength = 0;
  @Input() length = null;
  @Input() type = 'text';
  @Input() RegExp = '^\S[a-zA-Z ]+\S$';
  @Input() fastUpdate = false ;
  @Input() cmpntId = null;
  @Input() pattern = null;
  @Input() errorMessage = null;
  @Input() disablePaste = false;
  @Input() style = '';
  @Input() labelColorRequired:boolean = false;
  @Input() helpIconName = null;
  @Input() toolTipText: string;
  @Output() hasError:boolean;

  ngStyle = {};

  public message = null ;

  requiredMsg = '';
  invalidMsg = '';
  minlengthMsg = '';
  maxlengthMsg = '';
  minValueMsg = '';
  maxValueMsg = '';
  isPasswordField = false;
  isEdge: boolean = false;

  extra_message = '';

  constructor(private dialog: MatDialog, public commonService: CommonService) {
  }

  ngOnInit() {
    this.requiredMsg = this.placeholder +  $localize` is required`;
    this.invalidMsg =  $localize`Invalid ` + this.placeholder;
    this.minlengthMsg = this.placeholder +  $localize` must be at least ` + this.minlength;
    this.maxlengthMsg = this.placeholder +  $localize` cannot be more than ` + this.maxlength;
    this.minValueMsg = $localize`The min value of ` + this.placeholder + $localize` is ` +  this.min;
    this.maxValueMsg = $localize`The max value of ` + this.placeholder + $localize` is ` +  this.max;
    this.isPasswordField = this.type == 'password';

    const browser = window.navigator.userAgent.toLowerCase();
    this.isEdge = browser.indexOf('edg') > 0;

    if (this.length != null) {
      this.maxlength = this.length;
      this.minlength = this.length;
    }

    if (this.isPassword === true) {
      this.type = 'password';
    }

    if (this.type === 'password') {
      this.isPassword = true;
    }

    if (this.istime === true) {
      this.type = 'time';
    }

    if (this.isEmail === true) {
      this.type = 'email';
    }

    if (this.appwidth != null && this.appwidth.length > 0) {
      this.ngStyle = {'width':this.appwidth, 'padding': '2px'};
    }else {
      this.ngStyle = {'padding': '2px'};
    }

    this.onInputChange() ;
  }

  onPaste(event: any) {
    if (this.disablePaste ) {
      SimpleDialogComponent.open(this.dialog, $localize`Paste is not allowed in this field`);
    }
    return !this.disablePaste ;
  }

  onKeyPress(event:any) {
    if (this.type == 'password') {
      this.extra_message = this.isCapslock(event)?$localize`* Caps Lock is on`:'';
    }else{
      this.extra_message = '';
    }
  }

  isCapslock(event:any){
      const IS_MAC = /Mac/.test(navigator.platform);

      const charCode = event.charCode;
      const shiftKey = event.shiftKey;
      let capsLock = false;

      if (charCode >= 97 && charCode <= 122){
        capsLock = shiftKey;
      } else if (charCode >= 65 && charCode <= 90 && !(shiftKey && IS_MAC)){
        capsLock = !shiftKey;
      }

      return capsLock;

  }

  onInputChange() {
    this.hasError = this.formControl?!this.formControl.valid:false;
    this.inputValueChange.emit(this.inputValue);
  }

  handleKeyEnter(event) {
    event.preventDefault();   
  }  
  
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-padded-panel',
  templateUrl: './padded-panel.component.html',
  styleUrls: ['./padded-panel.component.css']
})
export class PaddedPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

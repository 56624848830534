<ngx-spinner bdColor= "rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating" [fullScreen]="true">
	<p i18n style="color: white">Loading...</p>
</ngx-spinner>
<app-form-panel i18n-applabel applabel="User Registration">
  <app-card i18n-applabel apptitle="Step 2 of 4 - User Info"> 
    <div style="padding-left:4px;">
      <label i18n class="important_instruction"
      style="line-height: 1.2em; padding-top: 0.4em; padding-bottom: 0.4em; font-weight: bold !important; font-size: 16px !important;"
      >Please complete the form below to register for the DriveON Portal.</label>
    </div>
    <app-card>
      <p i18n style="font-weight: bold; font-size: 16px">Personal Information  (* marks required fields)</p>
      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
        <app-input-field i18n-placeholder placeholder="First Name" 
          i18n-errorMessage errorMessage="Invalid character"
          cmpntId="LOGIN.RegistrationPage2.FirstName" 
          #firstName [required]="true" 
          [pattern]="userNamePattern" 
          [style]="styleUpperCase" 
          fxFlex="30%" appwidth="100%"
          [(inputValue)]="userProfile.firstName">
        </app-input-field>
        <app-input-field i18n-placeholder placeholder="Last Name"
          i18n-errorMessage errorMessage="Invalid character"
          cmpntId="LOGIN.RegistrationPage2.LastName" 
          #lastName [required]="true" 
          [pattern]="userNamePattern" 
          [style]="styleUpperCase"
          fxFlex="30%" appwidth="100%" 
          [(inputValue)]="userProfile.lastName">
        </app-input-field>
        <app-input-field i18n-placeholder placeholder="Middle Name"
          i18n-errorMessage errorMessage="Invalid character"
          cmpntId="LOGIN.RegistrationPage2.MiddleName" 
          #middleName [required]="false" 
          [pattern]="userNamePattern" 
          [style]="styleUpperCase"
          fxFlex="30%" appwidth="100%"
          [(inputValue)]="userProfile.middleName"></app-input-field>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
        <app-select-field i18n-placeholder placeholder="Email Preferred Language" 
          cmpntId="LOGIN.RegistrationPage2.PreferredLanguage" 
          [required]="true" areaLabel="Email Preferred Language Dropdown"
          fxFlex="30%" appwidth="100%"
          [(appvalue)]="userProfile.preferredLanguage" 
          [appoptions]="locales">
        </app-select-field>
        <app-input-field i18n-placeholder placeholder="Email Address"
          i18n-matTooltip matTooltip="Make sure this is an active email, we will send important emails and documentations."
          i18n-errorMessage errorMessage="Please enter a valid email address"
          cmpntId="LOGIN.RegistrationPage2.Email" 
          #emailAddress type="email" [required]="true" 
          pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" 
          fxFlex="30%" appwidth="100%" [style]="styleLowerCase"
          [(inputValue)]="userProfile.emailAddress"
          (inputValueChange)="validateEmail($event)">
        </app-input-field>
        <app-input-field i18n-placeholder placeholder="Re-enter Email Address"
          i18n-matTooltip matTooltip="Make sure this is an active email, we will send important emails and documentations."
          i18n-errorMessage errorMessage="Please enter a valid email address"
          cmpntId="LOGIN.RegistrationPage2.EmailConfirmation" 
          #confirmEmailAddress type="email" [required]="true" 
          pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" 
          fxFlex="30%" appwidth="100%" [style]="styleLowerCase"
          [(inputValue)]="userProfile.confirmEmailAddress" 
          (inputValueChange)="validateEmail($event)" [disablePaste]="true">
        </app-input-field>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
        <app-select-field i18n-placeholder placeholder="Organization" 
          cmpntId="LOGIN.RegistrationPage2.Organization" 
          [disabled]="true" #org [required]="true"
          [(appvalue)]="userProfile.organizationId" 
          areaLabel="Organization Dropdown"
          fxFlex="30%" appwidth="100%"
          [appoptions]="service.org">
        </app-select-field>
        <app-select-field i18n-placeholder placeholder="Job Function" 
          cmpntId="LOGIN.RegistrationPage2.Department" 
          [required]="true" errorMessage="please select your job function" 
          #depart fxFlex="30%" appwidth="100%"
          [(appvalue)]="userProfile.departmentId" 
          areaLabel="Job Function Dropdown"
          [appoptions]="service.depart">
        </app-select-field>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
        <app-phone-field i18n-placeholder placeholder="Mobile Phone Number"
          cmpntId="LOGIN.RegistrationPage2.MobilePhone" 
          #mobilePhoneField
          fxFlex="30%" appwidth="100%"
          [(value)]="userProfile.mobilePhoneNum">
        </app-phone-field>
        <app-phone-field i18n-placeholder placeholder="Business Phone Number"
          cmpntId="LOGIN.RegistrationPage2.Phone" 
          #busPhoneField [valueRequired]="true"
          [(value)]="userProfile.busPhoneNum" 
          [(extValue)]='userProfile.busPhoneNumExt' [ext]="true"
          extId="LOGIN.RegistrationPage2.PhoneExt">
        </app-phone-field>
      </div>
      <div class="flex-container">
        <div>
          <mat-checkbox color="primary" id="LOGIN.RegistrationPage2.Checkbox" [required]="true" [(ngModel)]="termOfUseChecked" aria-label="Terms Of Use"></mat-checkbox>
        </div>
        <div>
          <ng-container i18n class="indent">*I have read and agree with the</ng-container>
          <button i18n id="btn" class="hereBtn" type="button" aria-label=" Terms Of Use"  (click)="openTermConditions()">
            <span class="btn__content" tabindex="-1">Terms Of Use</span>
          </button>
          <ng-container i18n>of the DriveON Portal.</ng-container>
        </div>
      </div>
      <br/>
      <div class="parsons-grid" style="max-width: 700px;">
        <div>
          <ng-container i18n>*Enter the verification code characters shown on the right (letters are not case sensitive)</ng-container> <br />
          <app-input-field i18n-placeholder placeholder="Verification Code"
            cmpntId="LOGIN.RegistrationPage2.VerificationCode" #verificationCodeField [required]="true" #captcha
            [(inputValue)]="verificationCode"></app-input-field><br/><br/>
          <ng-container i18n>If you can't see the characters clearly, click 'Change the characters above' button to regenerate the image.</ng-container>
        </div>
        <div style="padding-left: 25px;">
          <img [src]="service.captcha.srcString" alt="Verification Code Characters"/><br /><br />
          <button i18n id="LOGIN.RegistrationPage2.ChangeVerification" mat-raised-button
            (click)="changeVerificationCode()">Change the characters above</button>
        </div>
      </div>
    </app-card>
    <div style="padding-left:4px;">
      <label i18n class="important_instruction"
        style="line-height: 1.2em; padding-top: 0.4em; padding-bottom: 0.4em; font-weight: bold !important; font-size: 16px !important;"
        >When you have filled in required information, please click on the Next button to continue.</label>
    </div>
    <br>
    <app-padded-panel>
      <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.lt-md="column">
        <button i18n mat-raised-button id="LOGIN.RegistrationPage2.Back" routerLink="/user-register-step1">Previous</button>
        <button i18n mat-raised-button id="LOGIN.RegistrationPage2.Submit" [disabled]="invalidInputs()" (click)="submit()">Next</button>
        <button i18n mat-raised-button id="LOGIN.RegistrationPage2.Reset" (click)="clear()">Reset</button>
        <button i18n mat-raised-button id="LOGIN.RegistrationPage2.Cancel" routerLink="/login/sso">Cancel</button>
      </div><br/>
      <div fxLayout="row wrap">
        <span i18n align="center" class="bg" fxFlex="30%">Select User Type</span>
        <span i18n align="center" class="bg" fxFlex="30%">User Info Creation</span>
        <span i18n align="center" class="bg" fxFlex="30%">Confirm Account</span>
      </div>
      <div fxLayout="row wrap">
        <span align="center" class="bggreen" height="9" fxFlex="33%" style="height:9px;"></span>
        <span align="center" class="bgred" height="9" fxFlex="33%" style="height:9px;" ></span>
        <span align="center" class="bgred" height="9" fxFlex="33%" style="height:9px;" ></span>
      </div>
    </app-padded-panel>
  </app-card>  
</app-form-panel>
<app-dialog #termConditionsDialog [(visible)]="showTermConditions">
  <div>
    <app-static-html source="{{termConditions}}"></app-static-html>
  </div>
  <button i18n id="Footer.Close" mat-raised-button
      (click)="this.showTermConditions=false">Close</button>
</app-dialog>

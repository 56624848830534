import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { integer } from 'aws-sdk/clients/cloudfront';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})

export class FileUploadComponent implements OnInit {
  @Input() cmpntId = null;
  @Input() placeholder = $localize`Choose a file`;
  @Input() tooltip = $localize`Please choose a file`;
  @Input() maxSize = 0;
  @Input() fileSelected = null;
  @Output() fileSelectedChange = new EventEmitter();

  fileName = $localize`No File Selected`;

  constructor() { }

  ngOnInit() {
  }

  onChange(event) {
    if(event.target.files.length > 0) {
      this.fileSelected = event.target.files[0];
      const fileSize = this.fileSelected.size;
      if(fileSize > 0 && fileSize <= this.maxSize){
        this.fileName = this.fileSelected.name;
      }else {
        const alertMsg = fileSize > this.maxSize? $localize`File "` + this.fileSelected.name + $localize`" exceeds the maximum file size limit. Please upload the file within the limit.`
                         : $localize`File "` + this.fileSelected.name + $localize`" is empty file.`;
        alert(alertMsg);
        this.fileSelected = null;
        this.fileName = $localize`No File Selected`;
      }
    }else{
      this.fileSelected = null;
      this.fileName = $localize`No File Selected`;
    }

    this.fileSelectedChange.emit(this.fileSelected); 
  }
}

  <label mat-dialog-title>{{title}}</label>
  <div *ngIf="dialogData.type === 'confirm-dialog' ">
    <div *ngIf="dialogData.inputField != null && dialogData.inputField.length > 0; ">
      <mat-dialog-content>
        <mat-form-field>
          <textarea matInput [id]="dialogData.inputField" placeholder="{{dialogData.inputField}}" [(ngModel)]="dialogData.inputValue" rows="2" cols="50"></textarea>
        </mat-form-field>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions>
      <button [id]="dialogData.ok" class="mat-raised-button" [mat-dialog-close]="dialogData" >{{dialogData.ok}}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button [id]="dialogData.cancel" class="mat-raised-button" (click)="close()">{{dialogData.cancel}}</button>
    </mat-dialog-actions>
  </div>

  <div *ngIf="dialogData.type === 'confirm-dialog-countdown'">
    <div *ngIf="dialogData.inputField != null && dialogData.inputField.length > 0; ">
      <mat-dialog-content>
        <mat-form-field>
          <textarea matInput [id]="dialogData.inputField" placeholder="{{dialogData.inputField}}" [(ngModel)]="dialogData.inputValue" rows="2" cols="50"></textarea>
        </mat-form-field>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions>
      <button [id]="dialogData.ok" class="mat-raised-button" [mat-dialog-close]="dialogData" >{{dialogData.ok}}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button [id]="dialogData.cancel" class="mat-raised-button" (click)="close()">{{dialogData.cancel}}</button>
    </mat-dialog-actions>
  </div>

  <div *ngIf="dialogData.type === 'warning-dialog' ">
    <mat-dialog-content style="font: 500 16px / 24px Roboto, Helvetica Neue, sans-serif;" [innerHTML]="dialogData.message">
    </mat-dialog-content>
    <br/>
    <mat-dialog-actions>
      <button [id]="dialogData.ok" class="mat-raised-button" (click)="close()">{{dialogData.ok}}</button>
    </mat-dialog-actions>
  </div>

  <div *ngIf="dialogData.type === 'change-password' ">
    <mat-dialog-content>
      <mat-form-field>
        <input [id]="dialogData.passwordField" matInput placeholder="{{dialogData.passwordField}}" type="password" [(ngModel)]="dialogData.password" />
      </mat-form-field>
      <mat-form-field>
        <input [id]="dialogData.newPasswordField" matInput placeholder="{{dialogData.newPasswordField}}" type="password" [(ngModel)]="dialogData.newPassword" />
      </mat-form-field>
      <mat-form-field>
        <input [id]="dialogData.confirmPasswordField" matInput placeholder="{{dialogData.confirmPasswordField}}" type="password" [(ngModel)]="dialogData.confirmPassword" #confirmPassword="ngModel" />
        <div i18n class="alert alert-danger" [hidden]="dialogData.newPassword === dialogData.confirmPassword">Must match the new password</div>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="mat-raised-button" [mat-dialog-close]="dialogData" >{{dialogData.ok}}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button class="mat-raised-button" (click)="close()">{{dialogData.cancel}}</button>
    </mat-dialog-actions>
  </div>

  <div *ngIf="dialogData.type === 'forget-password-SearchId' ">
    <mat-dialog-content>
      <mat-form-field style="width:98% !important;">
        <input [id]="dialogData.userIdField" matInput placeholder="{{dialogData.userIdField}}" [(ngModel)]="dialogData.userId"  >
      </mat-form-field>
      <br/><ng-container i18n>or</ng-container><br/>
      <mat-form-field style="width:98% !important;">
        <input [id]="dialogData.emailField" matInput placeholder="{{dialogData.emailField}}" [(ngModel)]="dialogData.email" [disabled]="dialogData.emailFieldDisabled === 'Y'" >
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button [id]="dialogData.ok"  class="mat-raised-button" [mat-dialog-close]="dialogData" [disabled]="(dialogData.userId == null || dialogData.userId.length == 0) && (dialogData.email == null || dialogData.email.length == 0)">{{dialogData.ok}}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button [id]="dialogData.cancel" class="mat-raised-button" (click)="close()">{{dialogData.cancel}}</button>
    </mat-dialog-actions>
  </div>

  <div *ngIf="dialogData.type === 'forget-password-Security-Question' ">
    <mat-dialog-content>
      <mat-form-field style="width:98% !important;">
        <input [id]="dialogData.securityQuestionField" matInput placeholder="{{dialogData.securityQuestionField}}" [(ngModel)]="dialogData.securityQuestion" [readonly]="dialogData.securityQuestion != null && !dialogData.changeSecurityQuestion" >
      </mat-form-field>
      &nbsp;&nbsp;
      <mat-form-field style="width:98% !important;">
        <input [id]="dialogData.securityQuestionAnswerField" matInput placeholder="{{dialogData.securityQuestionAnswerField}}" [(ngModel)]="dialogData.securityQuestionAnswer" >
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button [id]="dialogData.ok" class="mat-raised-button" [mat-dialog-close]="dialogData" [disabled]="!dialogData.securityQuestion || !dialogData.securityQuestionAnswer">{{dialogData.ok}}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button [id]="dialogData.cancel" class="mat-raised-button" (click)="close()">{{dialogData.cancel}}</button>
    </mat-dialog-actions>
  </div>

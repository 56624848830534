<div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column">
  <mat-form-field [ngStyle]="ngStyle" 
    [floatLabel]="inputdisabled?'always':'auto'"
    [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'">
    <mat-label [ngClass]="{'labelColor': labelColorRequired}">{{placeholder}} <span *ngIf="labelColorRequired">(required)</span></mat-label>
    <input  #phoneField #formControl="ngModel" [id]="cmpntId" [required]="valueRequired"  
    maxlength="15" (change)="phoneNumChange()"
    autocomplete="new-password"
    [disabled]="inputdisabled || commonService.isCmpntDisabled(cmpntId)" 
    matInput [ngModel]="value | phoneFormat" (ngModelChange)="value = $event" 
    placeholder="{{placeholder}}" pattern="^1?\(\d{3}\)\s\d{3}-\d{4}$">
    <mat-error *ngIf="formControl.hasError('required')">{{requiredMsg}}</mat-error>
    <mat-error *ngIf="formControl.hasError('pattern')">{{phonePatternMsg}}</mat-error>
  </mat-form-field>
  <mat-form-field  *ngIf="ext" class="small" [ngStyle]="ngStyle"
    [floatLabel]="inputdisabled?'always':'auto'"
    [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'" floatLabel="always">
    <input #extFormControl="ngModel" [id]="extId" (change)="extChange()" maxlength="5"
    autocomplete="new-password"
    [disabled]="inputdisabled || commonService.isCmpntDisabled(cmpntId) || !value" 
    matInput [(ngModel)]="extValue" placeholder="Ext"  pattern="^[0-9]+$">
    <mat-error *ngIf="extFormControl.hasError('required')">{{requiredMsg}}</mat-error>
    <mat-error *ngIf="extFormControl.hasError('pattern')">{{extPatternMsg}}</mat-error>
  </mat-form-field>
</div>
<mat-error *ngIf="message != null && message !=' '">{{message}}</mat-error>


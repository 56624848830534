import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { ping } from '../helper/util-function-class';

export function initKeycloak(keycloak: KeycloakService){

    const options: KeycloakOptions = {
      config : environment.keycloak,
      loadUserProfileAtStartUp: false,
      initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      bearerExcludedUrls: [
        'main.js',
      ]
    };


    const isKeycloakUp = environment.keycloakPingUrl?ping(environment.keycloakPingUrl):false;
    if (isKeycloakUp) {
      return () => keycloak.init(options);
    }else {
      return () =>{};
    }

}


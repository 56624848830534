import {Component, OnInit, SimpleChanges, Input} from '@angular/core';
import {CommonService} from "../../services/common.service";

@Component({
  selector: 'app-static-html',
  templateUrl: './static-html.component.html',
  styleUrls: ['./static-html.component.css']
})
export class StaticHtmlComponent implements OnInit {
  @Input() source: string;

  innerHtml = null;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.getStaticHTML(this.source);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getStaticHTML(this.source);
  }

  getStaticHTML(source:string) {
    if(source && source.length > 0) {
      this.commonService.getStaticHTML(source, this.getStaticHTMLCallback);
    }
  }

  getStaticHTMLCallback = (result: any) => {
    if (result) {
      this.innerHtml = result;
    }
  };

}

import {Component, Input, Output, OnInit, ViewChild, EventEmitter} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FormControl } from '@angular/forms';

import { CommonService } from '../../services/common.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { getLocale} from 'apps/common/helper/util-function-class';

@Component({
  selector: 'app-date-picker',
  templateUrl: './app-date-picker.component.html',
  styleUrls: ['./app-date-picker.component.css']
})

export class AppDatePickerComponent implements OnInit {
  @ViewChild('formControl') formControl:any;
  @ViewChild('picker') picker:MatDatepicker<Date>;

  @Output() appdateChange = new EventEmitter();
  @Output() hasError:boolean;
  @Input() placeholder = 'Choose a Date' ;
  @Input() inputdisabled = false ;
  @Input() cmpntId = null ;
  @Input() minDate =null;
  @Input() maxDate =null;
  @Input() required = false;
  @Input() appdate = null;
  @Input() appwidth = null;
  @Input() labelColorRequired:boolean = false;

  locale = 'en';

  constructor(public commonService: CommonService, private dateAdapter: DateAdapter<Date>) {
    this.locale = getLocale()=='fr'?'fr-CA':'en-CA';
    this.dateAdapter.setLocale(this.locale);
  }

  ngOnInit() {
    this.minDate = this.minDate !=null?new Date(this.minDate):null;
    this.maxDate = this.maxDate !=null?(this.maxDate=='today'? new Date():new Date(this.maxDate)):null;
    if (this.inputdisabled && this.formControl)
      this.formControl.disable();
  }

  inputChange() {
    if (this.formControl != null && this.formControl.control.errors != null && this.formControl.control.errors.matDatepickerParse != null) {
      this.formControl.control.setErrors({invalidInput: true});
    }
    this.hasError = this.formControl?!this.formControl.control.valid:false;
    this.appdateChange.emit(this.appdate);
  }

  handleKeyEnter(event) {
    event.preventDefault();   
  }

}

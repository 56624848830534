<label i18n mat-dialog-title tabindex="0"><b>You have an application in progress.</b>
    <br>Click <b>Cancel</b> and go to the yellow bar to continue with the application.
    <br>To start a new Vehicle Inspection Centre click <b>Continue</b>.
</label>
<mat-dialog-content>
    <!-- 
    <p> Place content here <br> 
        Place content here  <br>
        Place content here  </p> -->
</mat-dialog-content>
<mat-dialog-actions>
    <br>
    <button i18n mat-raised-button type="button" class="mat-raised-button" 
        cmpntId="Login.InspectionCentre.Close" 
        (click)="close()">Cancel
    </button>
    <button i18n mat-raised-button type="button" class="mat-raised-button"
        cmpntId="Login.InspectionCentre.Continue"
        (click)="continue()">Continue
    </button>
</mat-dialog-actions>
import { Component, OnInit } from '@angular/core';
import {RouterWrapperService} from '../../../../services/router-wrapper.service';
import {LoginService} from '../../../../services/login.service';
import {CommonService} from 'apps/common/services/common.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { isBlank} from 'apps/common/helper/util-function-class';

@Component({
  selector: 'station-user-registration-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css']
})
export class StationUserRegistrationStep3Component implements OnInit {
  userProfile: any = {};
  message:string = null;
  pageLabel = '';
  constructor(private router: RouterWrapperService, private service: LoginService, private commonService: CommonService, private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    this.pageLabel = $localize`Step 3 of 4 - Confirm Registration and Security`;
    this.userProfile = this.commonService.userProfile ;
    this.userProfile.step = 'station-user-register-step3';
  }

  invalidInputs = () => {
    return isBlank(this.userProfile.securityQuestion) || isBlank(this.userProfile.securityQuestionAnswer);
  }

  submit() {
    this.spinnerService.show();
    this.userProfile.ownerFlag = true;
    this.service.registerUser(this.userProfile, this.registerUserCallBack, this.showErrorMsg );
  }

  registerUserCallBack = (result:any) =>{
    this.spinnerService.hide();
    this.commonService.userProfile = result;
    this.commonService.userProfile.step = 'station-user-register-step4';
    this.router.gotoUrl('station-user-register-step4');
  }

  showErrorMsg = (error:any) => {
    console.log('####### error: ' + JSON.stringify(error));
    this.spinnerService.hide();

    if (error.status === 400) {
      alert($localize`Invalid request parameters!`);
    }else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    }else if( error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0 ) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {Router} from '@angular/router';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.css']
})
export class SelectListComponent implements OnInit {

  @Input() columns = ['row1'] ;

  @Input() columnsNames = [] ;

  @Input() appdata = [{row1: 'data'}, {row1: 'datasadfsadf'}];

  @Input() selectedRowIndex = -1;
  @Output() selectedRowIndexChange = new EventEmitter();

  @Input() appactionname: string ;
  @Input() appaction: () => {} ;

  @Input() deleteAction: boolean;

  @Input() doubleClickFunction: (obj: any) => void;

  highlight(row) {
    const id = this.getSelectedRow(row);
    if ( this.selectedRowIndex === id ) {
      this.selectedRowIndex = -1;
      this.selectedRowIndexChange.emit(this.selectedRowIndex);
    } else {
      this.selectedRowIndex = id;
      this.selectedRowIndexChange.emit(this.selectedRowIndex);
    }
  }

  getSelectedRow(row): number {
    for (let i = 0 ; i < this.appdata.length; i++) {
      if (this.appdata[i] === row ) {
        return i + 1;
      }
    }
    return -1 ;
  }

  constructor(private router: Router) { }

  ngOnInit() {
    if (this.appdata != null && this.appdata.length > 0 ) {
      const obj = this.appdata[0];
      this.columns = [] ;
      for (const k of Object.keys(obj)) {
        this.columns = this.columns.concat(k);
      }
      if (this.deleteAction ) {
        this.columns = this.columns.concat('action');
      }
    }

    if (this.columnsNames == null || this.columnsNames.length === 0 ) {
      this.columnsNames = this.columns;
    }
  }

  doubleClickAction(obj: any) {

    if (this.doubleClickFunction != null && this.doubleClickFunction !== undefined) {
      this.doubleClickFunction(obj);
    }
  }
}

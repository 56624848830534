import { Component } from "@angular/core";
import { Router} from '@angular/router';
import { MatDialogRef } from "@angular/material/dialog";
import { CommonService } from "apps/common/services/common.service";
import { getLocale } from "apps/common/helper/util-function-class";

@Component({
  selector: 'login-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.css']
})
export class DialogBodyComponent  {

  constructor( public dialogRef: MatDialogRef<DialogBodyComponent>, public commonService: CommonService, private router: Router){}

  close() {
    this.dialogRef.close();
  }

  continue() {    
    localStorage.clear();
    localStorage.setItem("stationId", "0");
    window.location.href = '/on-stationadmin-web/' + getLocale() + '/#/inspectionCentreOnboardingSteps?stationId=0';
  }

}

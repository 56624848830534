<app-form-panel >
  <h2 i18n aria-label="Change password">Change password</h2><br/>
 
    <span i18n style="line-height: 1.2em;
    padding-top: 0.4em; display: inline-block;
    padding-bottom: 0.4em; padding-left: 16px;
    font-weight: bold !important;
    font-size: 16px !important; font-family: ArialMT, Arial, sans-serif;">
    Please enter your current password, new password and confirm your new password.</span><br>
    <span i18n style="line-height: 1.2em;
    padding-top: 0.4em; display: inline-block;
    padding-bottom: 0.4em; padding-left: 16px;
    font-weight: bold !important;
    font-size: 16px !important; font-family: ArialMT, Arial, sans-serif;">
    Note that copy and paste will not work and your new password cannot have been used in the last 12 months.</span><br>
    <span i18n style="line-height: 1.2em;
    padding-top: 0.4em; display: inline-block;
    padding-bottom: 0.4em; padding-left: 16px;
    font-weight: bold !important;
    font-size: 16px !important; font-family: ArialMT, Arial, sans-serif;">
    A confirmation notice will be sent to your email address.</span>
  <app-card>
    <div class="row">
      <div class="pwd_container">
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
          <app-input-field i18n-placeholder id="changePassword.currentPasswordField" [disablePaste]="false" #currentPasswordField type="password"  
            (inputValueChange)="validateOldPassword($event)" id="currentPasswordField" fxFlex="100%" appwidth="100%"  
            [required]="true" placeholder="Current password:" [(inputValue)]="oldPassword">
          </app-input-field>
        </div>
        <div class="hidden-xs"><br/><br/></div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
          <app-input-field i18n-placeholder id="changePassword.newPasswordField" [disablePaste]="true" #newPasswordField type="password" 
            (inputValueChange)="validateNewPassword($event)" id="newPasswordField" fxFlex="100%" appwidth="100%" 
            [required]="true" placeholder="New password:" [(inputValue)]="newPassword">
          </app-input-field>
        </div>
        <div class="hidden-xs"><br/><br/></div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
          <app-input-field i18n-placeholder id="changePassword.confirmPasswordField" [disablePaste]="true" #confirmPasswordField type="password" 
            (inputValueChange)="validateConfirmNewPassword($event)" id="confirmPasswordField" fxFlex="100%" appwidth="100%"   
            [required]="true" placeholder="Confirm new password:" [(inputValue)]="confirmNewPassword">
          </app-input-field>
        </div>
      </div> 
      <div class="visible-xs">&nbsp;<br/></div>     
      <div>
        <table border="0" cellpadding="1" cellspacing="1" style="color: rgb(34, 34, 34); font-family: Helvetica, sans-serif; font-size: 12px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;">
          <tr>
            <td i18n>Password strength<span>&nbsp;</span><br>
              <div id="password_strength" style="margin: 5px 0px 5px 0px;">
                <div style="width: 180px; border: 1px solid rgb(204, 204, 204);">
                  <div id="pwd_progress_bar_ns_Z7_52IG13K0JOVIE0A2AD19K830O3_"  [ngStyle]="{'width': passwordStrength, 'background-color': validationColor}" style="height: 5px; border: 0px solid rgb(255, 255, 255); font-size: 1px; ">
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span i18n>Password requirements</span>
              <table border="0" cellpadding="0" cellspacing="0" style="margin-top:5px">
                <tr>
                  <td><img id="length_rule_icon_ns_Z7_52IG13K0JOVIE0A2AD19K830O3_" alt="Length rule"
                    border="0" [src]="eightChars? tickImg : crossImg" style="border: 0px; vertical-align: middle;width: 2em;padding: 1px;"></td>
                  <td i18n>&nbsp;Eight characters</td>
                </tr>
                <tr>
                  <td><img id="upper_rule_icon_ns_Z7_52IG13K0JOVIE0A2AD19K830O3_" alt="Upper rule"
                    border="0" [src]="upperCase ? tickImg : crossImg" style="border: 0px; vertical-align: middle;width: 2em;padding: 1px;"></td>
                  <td i18n>&nbsp;One uppercase character</td>
                </tr>
                <tr>
                  <td><img id="lower_rule_icon_ns_Z7_52IG13K0JOVIE0A2AD19K830O3_" alt="Lower rule"
                    border="0" [src]="lowerCase  ? tickImg : crossImg" style="border: 0px; vertical-align: middle;width: 2em;padding: 1px;"></td>
                  <td i18n>&nbsp;One lowercase character</td>
                </tr>
                <tr>
                  <td><img id="digit_rule_icon_ns_Z7_52IG13K0JOVIE0A2AD19K830O3_" alt="Digit rule"
                    border="0" [src]="numeric ? tickImg : crossImg" style="border: 0px; vertical-align: middle;width: 2em;padding: 1px;"></td>
                  <td i18n>&nbsp;One numeric character</td>
                </tr>
                <tr>
                  <td><img id="reused_rule_icon_ns_Z7_52IG13K0JOVIE0A2AD19K830O3_" alt="Reused rule"
                    border="0" [src]="!reuseOldPwd ? tickImg : crossImg" style="border: 0px; vertical-align: middle;width: 2em;padding: 1px;"></td>
                  <td i18n>&nbsp;Current password not reused</td>
                </tr>
                <tr>
                  <td><img id="pattern_rule_icon_ns_Z7_52IG13K0JOVIE0A2AD19K830O3_" alt="Pattern rule"
                    border="0" [src]="!similarPattern ? tickImg : crossImg" style="border: 0px; vertical-align: middle;width: 2em;padding: 1px;"></td>
                  <td i18n>&nbsp;No similar password pattern</td>
                </tr>
                <tr>
                  <td><img id="names_rule_icon_ns_Z7_52IG13K0JOVIE0A2AD19K830O3_" alt="Names rule"
                    border="0" [src]="!containUserInfo ? tickImg : crossImg" style="border: 0px; vertical-align: middle;width: 2em;padding: 1px;"></td>
                  <td i18n>&nbsp;Not containing account ID or names</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br>
    <button i18n mat-raised-button (click)="changePassword()" [disabled]="!(validNewPassword&&validConfirmPassword&&validOldPassword)">Change</button>&nbsp;&nbsp;
    <button i18n mat-raised-button (click)="cancel()" >Cancel</button>
  </app-card>
</app-form-panel>

import { Component, OnInit } from '@angular/core';
import {CommonService} from 'apps/common/services/common.service';

@Component({
  selector: 'user-registration-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.css']
})
export class UserRegistrationStep4Component implements OnInit {
  userProfile: any = {};

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.userProfile.step = 'user-register-step4';
    this.userProfile = this.commonService.userProfile;
  }

}

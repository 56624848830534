import { Component, OnInit } from '@angular/core';
import {CommonService} from 'apps/common/services/common.service';

@Component({
  selector: 'station-user-registration-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.css']
})
export class StationUserRegistrationStep4Component implements OnInit {
  userProfile: any = {};
  pageLabel = '';

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.userProfile.step = 'station-user-register-step4';
    this.pageLabel = $localize`Step 4 of 4 - You are now a registered user`;
    this.userProfile = this.commonService.userProfile;
  }

}

import { Injectable } from '@angular/core';
import { CommonService } from 'apps/common/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import {Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements  CanActivate {

  constructor(private commonService: CommonService, private router: Router, private dialog: MatDialog) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.commonService.isLoggedIn()) {
        return this.commonService.postLoginCheck();
    }
    else if (this.commonService.callingSSOLogin()) {
      return true;      // From keycloak login
    }
    else {
      this.commonService.openLoginScreen();
    }
  }
}

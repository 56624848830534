import {Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-form-panel',
  templateUrl: './form-panel.component.html',
  styleUrls: ['./form-panel.component.css']
})
export class FormPanelComponent implements OnInit {

  @Input() applabel: string;
  @Input() appStyle = {} ;

  constructor() {
    this.applabel = null;
  }

  ngOnInit() {
  }

}

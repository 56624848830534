<span >
  <mat-form-field  [ngClass]="selectFieldClass" [ngStyle]="{'width':appwidth, 'padding':'2px'}" 
    [floatLabel]="disabled?'always':'auto'"
    [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'">    
    <mat-label [ngClass]="{'labelColor': labelColorRequired}">{{placeholder}} <span *ngIf="labelColorRequired">(required)</span></mat-label>
    <mat-select  [id]="cmpntId" disableOptionCentering 
      [compareWith]="compareObjects" #parsonsSelect  [disabled]="disabled || commonService.isCmpntDisabled(cmpntId)" 
      (selectionChange)="onSelectionChange()" placeholder="{{placeholder}}"  
      [(ngModel)]="appvalue" [required]="required"  required [formControl]="formControl"
      [attr.aria-label]="areaLabel !== null ? areaLabel : 'Dropdown'">
      
      <mat-option is *ngFor="let o of appoptions; index as i" 
         [value]="o.code !== undefined ? o.code : (isArray(o)?o[0]:o)"   
         [id]="cmpntId!==null ? cmpntId + '-option-' + i : null" >{{o.value !== undefined? 
         o.value : ( isArray(o)?o[1]:(o.values !== undefined?o.values[0]:o)) }}
      </mat-option>
    </mat-select>

    <mat-error *ngIf="formControl.hasError('required')" i18n>
      <span style="color: #d50000;" *ngIf="!isBlank(errorMessage)">
      {{errorMessage}}
      </span>
      <span style="color: #d50000;" *ngIf="isBlank(errorMessage)">
        Please select {{placeholder}}
      </span>
    </mat-error>
  </mat-form-field>
</span>


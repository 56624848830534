<app-form-panel i18n-applabel applabel="User Registration">
  <app-card i18n-applabel apptitle="Step 4 of 4 - Registered"> 
    <p i18n>A new user account has been successfully registered with the following information:</p>
    <p i18n>
      User ID: <strong>{{userProfile.userId}}</strong><br>
      Email: <strong>{{userProfile.emailAddress}}</strong>
    </p>
    <p i18n>
      A temporary password has been emailed to you. Please check your spam folder in case you don't see your temporary password.
    </p>
    <p i18n>
      <b>Please log into the <a id="portalLink" [routerLink]="'/login/sso'">Ontario DriveON Portal</a> within 5 days. If you do not login, your temporary password will expire.</b>
    </p><br/>
  </app-card>
  <app-card>
    <p i18n>
      You can register another user or login
    </p>
    <button i18n id="LOGIN.RegistrationPage3.Previous" mat-raised-button routerLink="/user-register-step1">Register Another User </button>&nbsp;
    <button i18n id="LOGIN.RegistrationPage3.Cancel" mat-raised-button routerLink="/login/sso">Login</button>&nbsp;
  </app-card>
</app-form-panel>

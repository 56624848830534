<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating"
  [fullScreen]="true">
  <p i18n style="color: white">Loading...</p>
</ngx-spinner>
<main *ngIf="env == 'LOCAL'" class="main container-fluid">
  <div class="row desktopVersion" style="padding-left: 30px;">
    <div class="col-md-8 col-lg-8 order-lg-1">
      <div class="row">
        <span i18n ngClass="welcom_to_the_integr">Welcome to DriveON</span>
      </div>
      <div class="row" style="font-size: x-large;">
        <p i18n style="font-size: x-large;  font-family: Arial, Helvetica, sans-serif;">Program Registration COMING SOON</p>
      </div><br/><br/>
      <div class="row">        
        <p i18n style="font-size: large;font-weight: bold;  font-family: Arial, Helvetica, sans-serif;max-width: 782px;">Information Sessions</p>
      </div>
      <div class="row">        
        <p i18n style="font-size: large;font-family: Arial, Helvetica, sans-serif;max-width: 782px;">The build of the new safety inspection software is well under way but has recently encountered 
        some delays. As a result, we are rescheduling the Information Sessions to ensure you are presented 
        with the most current inspection package.</p>
      </div>
    </div>
    <div class="col-md-4 col-lg-4 order-lg-2">
      <div class="main__module login" style="background-color: #f2f2f2;">
        <div class="d-flex">
          <h2 i18n class="login__title" style="
          font-size: 28px;
          height: 32px;
          background-color: rgba(255, 255, 255, 0);
          box-sizing: border-box;
          font-family: Arial, sans-serif;
          font-weight: bold;
          color: #000000;
          text-align: center;
          line-height: normal;">Login</h2>
        </div>
        <div id="ContentPane" align="center">
          <app-card>
            <app-form-panel [appStyle]="{'margin': 'auto'}">
              <div *ngIf="message != null && message.length > 0">
                <mat-error style="white-space: pre-line;">{{message}}</mat-error><br />
              </div>
              <app-input-field i18n-placeholder #userIdInput cmpntId="LOGIN.LoginScreen.UserId" [(inputValue)]="userId"
                placeholder="User ID" appwidth="100%" [required]="true">
              </app-input-field>
              <br /> 
              <app-input-field i18n-placeholder #passwordInput cmpntId="LOGIN.LoginScreen.Password" type="password"
                [(inputValue)]="password" placeholder="Password" appwidth="100%" [required]="true"></app-input-field>
              <br/><br/>
              <button id="LOGIN.LoginScreen.Desktop.ForgetPasswordLink" class="forget-passwordlink" type="button" aria-label="Forgot User ID and/or Password" 
                 (click)="forgetPassword()">
                <span i18n style="font-size: 13px; color: blue; text-decoration: underline;" >
                  Forgot User ID and/or Password?
                </span>
              </button>
              <br/><br/>              
              <button i18n id="LOGIN.LoginScreen.Desktop.Login" mat-raised-button
                [disabled]="isBlank(userId) || isBlank(password)" (click)="login()" style="width:200px;">Login</button>
              <br/><br/>
              <span i18n style="font-size: 15px;">New Inspection Centre User? </span>
              <button id="LOGIN.LoginScreen.Desktop.RegisterLink" class="forget-passwordlink" type="button" aria-label="Register" 
                (click)="registerStationUser()">
                <span i18n style="font-size: 13px; color: blue; text-decoration: underline;" >
                  Register
                </span>
              </button>
            </app-form-panel>
          </app-card>
        </div>
      </div>
    </div>
  </div>  

  <!-- Tablet -->
  <div class="row tabletVersion" style="padding-left: 30px;">
    <div class="col-md-7 col-lg-7 order-lg-1">
      <div class="row" style="padding-top: 20px !important;">
        <span i18n ngClass="welcom_to_the_integr">Welcome to DriveON</span>
      </div>
      <div class="row" >
        <p i18n style="font-size: x-large;font-family: Arial, Helvetica, sans-serif;">Program Registration COMING SOON</p>
      </div><br/><br/>
      <div class="row">        
        <p i18n style="font-size: large;font-weight: bold;  font-family: Arial, Helvetica, sans-serif;max-width: 682px;">Information Sessions</p>
      </div>
      <div class="row">        
        <p i18n style="font-size: large;font-family: Arial, Helvetica, sans-serif;max-width: 682px;">The build of the new safety inspection software is well under way but has recently encountered 
          some delays. As a result, we are rescheduling the Information Sessions to ensure you are presented 
          with the most current inspection package.</p>
      </div>
    </div>
    <div class="col-md-5 order-lg-2">
      <div class="main__module_mobile" style="background-color: #f2f2f2;">
        <div class="d-flex1">
          <h3 i18n class="login__title" style="height: 32px; padding-left: 25px;
          background-color: rgba(255, 255, 255, 0); box-sizing: border-box;
          font-family: Arial, sans-serif; font-weight: bold;
          color: #000000; text-align: center; line-height: normal;">Login</h3>
        </div>      
        <div id="ContentPane" align="center">
          <div class="matCard">
            <app-form-panel [appStyle]="{'margin': 'auto'}">
              <div *ngIf="message != null && message.length > 0">
                <mat-error style="white-space: pre-line;">{{message}}</mat-error><br />
              </div>
              <app-input-field i18n-placeholder #userIdInput cmpntId="LOGIN.LoginScreen.TabletUserId" [(inputValue)]="userId"
                placeholder="User ID" appwidth="100%" [required]="true">
              </app-input-field>
              <br />
              <app-input-field i18n-placeholder #passwordInput cmpntId="LOGIN.LoginScreen.TabletPassword" type="password"
                [(inputValue)]="password" placeholder="Password" appwidth="100%" [required]="true"></app-input-field>
              <br /> <br />
              <button id="LOGIN.LoginScreen.Tablet.ForgetPasswordLink" class="forget-passwordlink" type="button" aria-label="Forgot User ID and/or Password Link"  (click)="forgetPassword()">
                <span i18n tabindex="-1" style="font-size: 13px; color: black; text-decoration: underline;">
                  Forgot User ID and/or Password?
                </span>
              </button>
              <br /><br />
              <button i18n id="LOGIN.LoginScreen.Tablet.TabletLogin" mat-raised-button
                [disabled]="isBlank(userId) || isBlank(password)" (click)="login()" style="width:200px;">Login</button>
              <br /><br />
              <span i18n style="font-size: 15px;">New Inspection Centre User?</span>
              <button id="LOGIN.LoginScreen.Tablet.RegisterLink" class="forget-passwordlink" type="button" aria-label="Register" 
                (click)="registerStationUser()">
                <span i18n style="font-size: 13px; color: blue; text-decoration: underline;" >
                  Register
                </span>
              </button>              
            </app-form-panel>
        </div>
      </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div class="row mobileVersion">
    <div class="col-md-12 col-lg-12 order-lg-1">
      <div class="row" style="padding-top: 20px !important;">
        <span i18n ngClass="welcom_to_the_integr">Welcome to DriveON</span>
      </div>
      <div class="row" style="font-size: large;">      
        <p i18n style="font-size: large;font-family: Arial, Helvetica, sans-serif;">Program Registration COMING SOON</p>
      </div><br/><br/>
      <div class="row">
        <p i18n style="font-weight: bold;font-size: x-large;  font-family: Arial, Helvetica, sans-serif;max-width: 682px;">Information Sessions</p>
      </div>
      <div class="row">
        <p i18n style="font-size: large;font-family: Arial, Helvetica, sans-serif;max-width: 682px;">The build of the new safety inspection software is well under way but has recently encountered 
        some delays. As a result, we are rescheduling the Information Sessions to ensure you are presented 
        with the most current inspection package.</p>
      </div>
      <div class="col-md-9 order-lg-2">
        <div class="main__module_mobile1" style="background-color: #f2f2f2;">
          <div class="d-flex1">
            <h3 i18n class="login__title" style="
              height: 32px;
              padding-left: 25px;
              padding-top: 15px;
              background-color: rgba(255, 255, 255, 0);
              box-sizing: border-box;
              font-family: Arial, sans-serif;
              font-weight: bold;
              color: #000000;
              text-align: center;
              line-height: normal;">Login</h3>
          </div>      
          <div id="ContentPane" align="center">
          <div class="matCard">
            <app-form-panel [appStyle]="{'margin': 'auto'}">
              <div *ngIf="message != null && message.length > 0">
                <mat-error style="white-space: pre-line;">{{message}}</mat-error><br />
              </div>
              <app-input-field i18n-placeholder #userIdInput cmpntId="LOGIN.LoginScreen.MobileUserId" [(inputValue)]="userId"
                placeholder="User ID" appwidth="100%" [required]="true">
              </app-input-field>
              <br /> 
              <app-input-field i18n-placeholder #passwordInput cmpntId="LOGIN.LoginScreen.MobilePassword" type="password"
                [(inputValue)]="password" placeholder="Password" appwidth="100%" [required]="true"></app-input-field>
              <br /> <br />
              <button id="LOGIN.LoginScreen.Mobile.ForgetPasswordLink" class="forget-passwordlink" type="button" aria-label="Forgot User ID and/or Password Link"  (click)="forgetPassword()">
                <span i18n tabindex="-1" style="font-size: 13px; color: black; text-decoration: underline;">
                  Forgot User ID and/or Password?
                </span>
              </button>
              <br /><br />            
              <button i18n id="LOGIN.LoginScreen.Mobile.Login" mat-raised-button
                [disabled]="isBlank(userId) || isBlank(password) || sentRequest" (click)="login()" style="width:200px;">Login</button>
              <br /><br />
              <span i18n style="font-size: 15px;">New Inspection Centre User? </span>
              <button id="LOGIN.LoginScreen.Mobile.RegisterLink" class="forget-passwordlink" type="button" aria-label="Register" 
                (click)="registerStationUser()">
                <span i18n style="font-size: 13px; color: blue; text-decoration: underline;" >
                  Register
                </span>
              </button> 
            </app-form-panel>
          </div>
        </div>
      </div>
    </div><br/>
    </div>
  </div>
  <app-dialog #enrolmentInfoDlg [(visible)]="showEnrolmentInfo">
    <fieldset class="form-group" style="border: solid; border-width: thin; border-color: #d3cdcd; padding: 10px;">
      <legend i18n style="width:auto; border:0px; margin: 0px; font-size: 14px; font-weight: bold;"></legend>
      <p i18n style="font-size: x-large; font-family: Arial, Helvetica, sans-serif;" mat-dialog-title>
        <b>DriveON Enrolment Information</b>
      </p>
      <br/>
      <p i18n style="font-weight: bold;  font-family: Arial, Helvetica, sans-serif;max-width: 782px;">
        DriveON Program enrolment will open soon!
      </p><br/>
      <p i18n style="font-family: Arial, Helvetica, sans-serif;max-width: 782px;">
        To provide MVIS Owners with more information on the DriveON Program, we invite you to attend an <br/>upcoming Information Session.
      </p>
      <p i18n style="font-family: Arial, Helvetica, sans-serif;max-width: 782px;">
        MVIS Owners can choose to attend a virtual session or one of the available in-person sessions in their<br/> 
        region. Both virtual and in-person sessions cover the same information where Ministry of Transportation<br/>
        and Parsons will provide information about the new DriveON Program, required equipment and the <br/>
        enrolment process. Sessions are scheduled for approximately 1 to 1.5 hours and there will be opportunities<br/>
        for questions and answers.
      </p><br/>
      <p i18n style="font-family: Arial, Helvetica, sans-serif;">
        Space is limited – Register for an upcoming Information Session TODAY!
      </p>
      <button i18n id="LOGIN.EnrolmentDlg.Package" mat-raised-button style="padding-left: 35px;
        line-height: 18px !important; padding-top: 1px; padding-bottom: 3px; padding-right: 35px;"
        (click)="openInformationSessionSchedule()"><b>Information Sessions <br>Schedule and Registration</b>
      </button>
      <br/><br/>
    </fieldset><br/>
    <button i18n id="RegiserInfo.Close" mat-raised-button
      (click)="this.showEnrolmentInfo = false">Close</button>
  </app-dialog>
</main>
import {Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { CommonService } from '../../../common/services/common.service';
import { environment } from '../../../common/environments/environment';
import * as Bowser from 'bowser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title: string = 'on-login-web';
  version:string = environment.version;
  isBrowserSupported: boolean = true;

  constructor(public commonService: CommonService, private router: Router) {
    commonService.disableConsole();
    const browser = Bowser.getParser(window.navigator.userAgent);
    const dot = browser.getBrowserVersion().indexOf(".");
    let browserVersion = 0;
    if( dot > 0 ) {
      browserVersion = parseInt(browser.getBrowserVersion().substr(0, dot));
    }
    else {
      browserVersion = parseInt(browser.getBrowserVersion());
    }
    if( (browser.getOSName() !== "Android" && browser.getBrowserName() === "Chrome" && browserVersion < 80) ||
        (browser.getBrowserName() === "Microsoft Edge" && browserVersion < 17) ||
        (browser.getBrowserName() === "Internet Explorer" && browserVersion < 11) ||
        (browser.getBrowserName() === "Firefox" && browserVersion < 72) ||
        (browser.getBrowserName() === "Safari" && browserVersion < 12 ) )
      this.isBrowserSupported = false;
  }

  async ngOnInit() {
    await this.commonService.getKeycloakToken();
    if( !this.commonService.isLoggedIn() || this.commonService.getUserStatus() != 'E' ) {
      if(window.location.href.indexOf('home') > 0) {
        this.router.navigate(['/login']);
      }
    }else {
      this.commonService.postLoginCheck();
      this.commonService.setSessionTimeout();
    }
  }
}

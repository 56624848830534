<div style="max-width: 900px; margin: 0 auto;">
  <div i18n class="welcome">DriveON Pre-Enrolment Information</div>
  <div i18n class="coming">DriveON Program enrolment will open soon!</div>
  <p i18n>Program enrolment will be available early 2024 (Date TBD). To prepare you for enrolment, a Pre-Enrolment
  Guide has been developed to provide MVIS owners with information on the necessary documentation and
  information that you need for a successful program enrolment. Begin to collect the information now due to
  the time it may take to obtain.
  </p>
  <p i18n>Act now to register for one of the upcoming Information Sessions.</p>
  <p i18n>
  Owners can choose to attend a virtual session or one of the available in-person sessions in their region.
  Both virtual and in-person sessions cover the same information where Ministry of Transportation and
  Parsons will provide information about the new DriveON Program. Sessions are scheduled for
  approximately one hour and there will be opportunities for questions and answers.
  </p>
  <p i18n>Space is limited - Register for an upcoming Information Session TODAY!</p>
  <div style="padding-bottom:50px" fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-sm="column">
    <a i18n class="mat-raised-button middle-button" style="padding-top: 10px;"
      href="{{'assets/docs/DriveON_Ph2_3a_Pre-Enrolment_'+locale+'.pdf'}}" target="_blank">
      Pre-Enrolment Guide
    </a>
    <a i18n class="mat-raised-button middle-button" href="{{'assets/docs/DriveON_Information_Sessions_Schedule_'+locale+'.pdf'}}" target="_blank">
      Information Sessions Schedule and Registration
    </a>
  </div>
  <div i18n class="reference">Reference Materials</div>
  <p i18n>Click on the links below to view copies of the reference materials in advance of your enrolment. These are
  provided for reference only, and no signatures are required at this time. All contracts and agreements will
  be signed electronically during the enrolment process.</p>
  <a i18n href="{{'assets/docs/DriveON_Performance_Contract_Public_'+locale+'.pdf'}}" target="_blank">
    Performance Contract, for Public Vehicle Inspection Centres <i>(Contract with Ministry of Transportation)</i>
  </a><br/><br/>
  <a i18n href="{{'assets/docs/DriveON_Performance_Contract_Fleet_'+locale+'.pdf'}}" target="_blank">
    Performance Contract, for Fleet Vehicle Inspection Centres <i>(Contract with Ministry of Transportation)</i>
  </a><br/><br/>
  <a i18n href="{{'assets/docs/DriveON_Ph2_3a_EPMA_'+locale+'.pdf'}}" target="_blank">
    Equipment Purchase and Maintenance Agreement <i>(Agreement with Parsons)</i>
  </a><br/><br/>
  <a i18n href="{{'assets/docs/DriveON_PAD_Agreement_Ministry_'+locale+'.pdf'}}" target="_blank">
    Pre-Authorized Debit <i>(Agreement with Ministry of Transportation)</i>
  </a><br/><br/>
  <a i18n href="{{'assets/docs/DriveON_PAD_Agreement_Parsons_'+locale+'.pdf'}}" target="_blank">
    Pre-Authorized Debit <i>(Agreement with Parsons)</i>
  </a><br/><br/>
  <div>
    <button i18n id="ENROLMENT.Close" mat-raised-button 
      style="font-weight:bold" routerLink="/login/sso">Close</button>
  </div><br/>
</div>
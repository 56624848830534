<app-form-panel i18n-applabel applabel="Vehicle Inspection Centre User Registration"> 
  <app-card i18n-apptitle apptitle="Step 1 of 4 - Select User Type">
    <div fxLayout="row wrap" fxLayoutGap="20px">

      <button i18n i18n-matTooltip id="LOGIN.StationUserRegistration.button.Owner" mat-raised-button class="mat-elevation-z20"
        style="background-color: #1b3349; width: 180px; height: 205px;" (click)="next(service.IC_OWWER)" aria-label="Vehicle Inspection Centre Owner"
        matTooltip="Click on this icon to register as a Vehicle Inspection Centre Owner">
        <span style="display: block; line-height: 110%;">Inspection<br>Centre Owner/<br>Technician&nbsp;</span>
        <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
          src="assets/img/normal_u16.svg" alt=""><br>
      </button>

      <button i18n i18n-matTooltip id="LOGIN.StationUserRegistration.button.Technician" mat-raised-button class="mat-elevation-z20"
        style="background-color: #1b3349; width: 180px; height: 205px;" (click)="next(service.IC_TECH)" aria-label="Technician"
        matTooltip="Click on this icon to register as a Technician only">
        <span style="display: block; line-height: 110%;">Technician<br>Only<br>&nbsp;</span>
        <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
          src="assets/img/normal_u22.svg" alt=""><br>
      </button>
      <div *ngIf="noticeTitle" class="important-notice">
          <div style="padding: 15px;">
            <img src="assets/img/attention.png" height="35px"/>
          </div>    
          <div>
            <div style="font-size:20px;font-weight:700;font-style:normal;">{{noticeTitle}}</div>
            <div style="font-size:20px;font-weight:400;font-style:normal;line-height: 1.3" [innerHTML]="noticeText"></div>
          </div>
      </div>
    </div>
  </app-card> 
  <div i18n style="padding-left: 10px;  font-family: Arial-BoldMT, Arial Bold, Arial, sans-serif;font-size: medium;font-weight: 600">* Select <i>“Inspection Centre Owner/Technician”</i> if you are the Vehicle Inspection Centre (VIC) Owner or if you have authorization to complete the enrolment application and e-sign contracts (with authority to bind) on behalf of the organization.</div>
 
</app-form-panel>
import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {InputFieldComponent} from 'apps/common/components/input-field/input-field.component';
import {CommonService} from 'apps/common/services/common.service';
import { isBlank, isSimilarPasswordPattern, contains, getLocale} from 'apps/common/helper/util-function-class';
import {translateMessage} from 'apps/common/helper/service-messages';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  @ViewChild('currentPasswordField', {static: true}) currentPasswordField : InputFieldComponent ;
  @ViewChild('newPasswordField' , {static: true}) newPasswordField : InputFieldComponent ;
  @ViewChild('confirmPasswordField', {static: true}) confirmPasswordField : InputFieldComponent ;

  userId = '';
  userName = '';
  oldPassword = '';
  newPassword = '';
  confirmNewPassword = '';
  validNewPassword:boolean = false;
  validConfirmPassword:boolean = false;
  validOldPassword:boolean = false;
  yesNoDialogConfig: MatDialogConfig = new MatDialogConfig();

  eightChars = false;
  upperCase = false;
  lowerCase = false;
  numeric = false;
  reuseOldPwd = true;
  similarPattern = true;
  containUserInfo = true;
  usedBefore = false;

  passwordStrength = '0px';
  tickImg = 'assets/img/iconTick.png';
  crossImg = 'assets/img/cross.png';
  naImg = 'assets/img/blank.jpg';

  validationColor: string = 'red' ;
  MSG_UMA_reused_password = 11;
  userDeptId:string = '';
  
  stationUserDepts = ['1', '2', '15', '100'];

  constructor(private commonService: CommonService , private router: Router, private cookie: CookieService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.yesNoDialogConfig.ariaLabel=" ";
    this.yesNoDialogConfig.autoFocus= false;
    this.userId = this.commonService.getUserId();
    this.userName = this.commonService.getUserName();
    if (this.userId == null || this.userId.length === 0) {
      this.router.navigateByUrl('/login');
      return ;
    }else{
      this.commonService.getUserDepartment(this.userId, this.getUserDepartmentCallback, this.showRequestErrorMessage);
    }
  }

  getUserDepartmentCallback = (result:any) =>{
    this.userDeptId = result;
  }
  
  validate = (newPwd) : number => {
    let count : number = 0 ;
    if (!isBlank(newPwd)) {
      this.lowerCase = /[a-z]/.test(newPwd);
      this.upperCase = /[A-Z]/.test(newPwd);
      this.numeric = /[0-9]/.test(newPwd);
      this.eightChars = newPwd.length >= 8;
      this.reuseOldPwd = newPwd === this.oldPassword;
      this.similarPattern = isSimilarPasswordPattern(newPwd, this.oldPassword);
      this.containUserInfo = contains(newPwd, this.userId) || contains(newPwd, this.userName);

      if ( this.lowerCase ) count++  ;
      if ( this.upperCase ) count++  ;
      if ( this.numeric) count++  ;
      if ( this.eightChars) count++  ;
      if ( this.reuseOldPwd) count--  ;
      if ( this.similarPattern) count--  ;
      if ( this.containUserInfo) count--  ;
      if ( !(this.reuseOldPwd || this.similarPattern || this.containUserInfo) ) count++ ;
    } else {
      this.eightChars = false;
      this.upperCase = false;
      this.lowerCase = false;
      this.numeric = false;
      this.reuseOldPwd = true;
      this.similarPattern = true;
      this.containUserInfo = true;
    }
    if (count < 3) {
      this.validationColor = 'red' ;
    }else if (count < 4 ) {
      this.validationColor = 'yellow';
    }else {
      this.validationColor = 'green';
    }
    this.passwordStrength = ((count*36.2) ) + 'px';
    return count ;
  }


  validateOldPassword = (pwd: any) => {
    if (isBlank(pwd)) {
      this.oldPassword = pwd;
      this.validOldPassword = false;
      this.newPasswordField.message = '';
    } else {
      this.validOldPassword = true;
    }
    this.validNewPassword = this.validate(this.newPassword) >= 5;
    this.newPasswordField.message = this.validNewPassword?'':$localize`Not valid new password`;
  };

  validateNewPassword = (pwd: any) => {
    this.validNewPassword = this.validate(pwd) >= 5;
    this.newPasswordField.message = this.validNewPassword || isBlank(pwd)?'':$localize`Not valid new password`;
    this.validConfirmPassword = this.confirmNewPassword === pwd;
    this.confirmPasswordField.message = this.validConfirmPassword?'':$localize`Password does not match`;
  }

  validateConfirmNewPassword = (pwd: any) => {
    if (isBlank(pwd)) {
      this.validConfirmPassword = false;
      this.confirmPasswordField.message = '';
    } else {
      this.validConfirmPassword = this.newPassword === pwd;
      this.confirmPasswordField.message = this.validConfirmPassword?'':'Password does not match';
      this.usedBefore = false;
    }
  }

  changePassword() {
    const request = {'pwd': this.oldPassword, 'newPwd':
      this.newPassword, 'userId': this.userId};

    this.commonService.changePassword(request, this.userId, this.changePasswordSuccess, this.showRequestErrorMessage)
  }

  changePasswordSuccess = (result:any) =>{
    if(this.isStationUser(this.userDeptId)) {
      alert($localize`Password has been successfully changed! A Data File Synchronization must be performed on the Tablet to be able to perform inspections.`);
    }else{
      alert($localize`Password has been successfully changed!`);
    }
    this.commonService.pwdResetRequired = false;
    this.commonService.setCookie('pwdChangeConfirmed', 'Y');
    this.commonService.setCookie('pwdExpiryDays', '90');
    this.goHome();
  }

  isStationUser(userDept:string):boolean{
    return this.stationUserDepts.some(stationUserDept => userDept == stationUserDept);
  }

  showRequestErrorMessage = (error:any) => {
    if( error !== null && error.error !== null ) {
      const serviceMessage = error.error;
      this.currentPasswordField.message = translateMessage(serviceMessage.serviceName, serviceMessage.msgId, serviceMessage.msgValues);
      this.usedBefore = serviceMessage.msgId == this.MSG_UMA_reused_password;
    } else {
      alert( $localize`Oops, something went wrong! Please try again later.`);
    }
  }

  cancel() {
    if (this.commonService.pwdResetRequired) {
      this.commonService.logout();
    }
    else {
      if((this.oldPassword !== null && this.oldPassword !== undefined && this.oldPassword !== "") ||
      (this.newPassword !== null && this.newPassword !== undefined && this.newPassword !== "") ||
      (this.confirmNewPassword !== null && this.confirmNewPassword !== undefined && this.confirmNewPassword !== "")){
        this.yesNoDialogConfig.data = { type: 'confirm-dialog', title: "Leave without saving?", cancel: 'No', ok: 'Yes' };
        const yesNoDialog = this.dialog.open(CustomDialogComponent, this.yesNoDialogConfig);
        yesNoDialog.afterClosed().subscribe(dialogData => {
          if (dialogData != null) { // Yes
            this.goHome();          }
        });
      }
      else {
        this.goHome();
      }
    }
  }

  goHome() {
    if (this.commonService.isPageEnabled('LOGIN.TechnicianLanding') || this.commonService.isPageEnabled('LOGIN.StationLanding')) {
      this.commonService.goToHomePage();
    }
    else
      this.router.navigateByUrl('/home');
    }
}

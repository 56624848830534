import { Component, OnInit } from '@angular/core';
import {RouterWrapperService} from '../../../../services/router-wrapper.service';
import {LoginService} from '../../../../services/login.service';
import {CommonService} from 'apps/common/services/common.service';

@Component({
  selector: 'user-registration-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})
export class UserRegistrationStep1Component implements OnInit {
  userProfile: any = {};
  org: any = [];
  formIsValid: any = true;
  mydate: Date ;
  IC_USER = 3;

  constructor(private router: RouterWrapperService, public service: LoginService, private commonService: CommonService) {
  }

  ngOnInit() {
    this.commonService.userProfile = {step:'user-register-step1'};
    this.userProfile = this.commonService.userProfile;
    this.userProfile.organizationId = "";
    this.service.getOrganizations(this.setOrgs);
    this.service.getVerificationCode();
  }

  setOrgs = (o:any) => {
    this.org = [] ;
    const result: Array<any> = o.lookup.Employer;
    for (let item of result) {
      if (item.code != this.IC_USER) {
        this.org.push([item .code,
          item .value]) ;
      }
    }
    this.service.org = this.org;
  }

  next() {
    this.service.getJobFunctions(this.userProfile.organizationId);
    this.commonService.userProfile.step = 'user-register-step2';
    this.router.gotoUrl('user-register-step2');
  }

  cancel() {
    this.commonService.userProfile = {} ;
    this.router.gotoUrl('');
  }

}



import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterWrapperService} from '../../../../services/router-wrapper.service';
import {LoginService} from '../../../../services/login.service';
import {CommonService} from 'apps/common/services/common.service';
import {InputFieldComponent} from 'apps/common/components/input-field/input-field.component';
import { isBlank, isValidStr, isNumeric, isValidPhone, isValidEmail, phoneString2Number, getLocale, clickInputField} from 'apps/common/helper/util-function-class';

@Component({
  selector: 'station-user-registration-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})

export class StationUserRegistrationStep2Component implements OnInit {
  userNamePattern = /^[a-zA-Z][a-zA-Z.' ]*$/;

  @ViewChild('confirmEmailAddressField', {static: true}) confirmEmailAddressField: InputFieldComponent;
  @ViewChild('verificationCodeField', {static: true}) verificationCodeField: InputFieldComponent;
  @ViewChild("termConditionsDialog") termConditionsDialog:any;
  @ViewChild('mobilePhoneField') mobilePhoneField: any;
  @ViewChild('busPhoneField') busPhoneField: any;

  userProfile: any = {};
  invalidEmailAddr = true;
  usedMobilePhoneNum = true;
  mobilePhoneChecked = false;
  styleUpperCase = "text-transform:uppercase";
  styleLowerCase = "text-transform:lowercase";
  termOfUseChecked = false;
  verificationCode = '' ;

  emailTooltip = '';

  locales = [
    { code: 'en', value: 'English' },
    { code: 'fr', value: 'Français' },
  ];

  showTermConditions:boolean = false;
  termConditions = 'assets/term-conditions';
  isTechnician = false;
  isOwnerTech = null;

  pageLabel = '';
  pageInstuction = '';

  constructor(private router: RouterWrapperService, public service: LoginService, private commonService: CommonService) {
  }

  ngOnInit() {
    this.userProfile = this.commonService.userProfile ;

    this.userProfile.step = 'station-user-register-step2';
    this.termConditions = this.termConditions + '-' + getLocale() + '.html';

    this.userProfile.preferredLanguage = getLocale();
    this.isTechnician = this.userProfile.departmentId == this.service.IC_TECH;
    if (this.isTechnician) {
      this.pageLabel =  $localize`Step 2 of 4 - Technician Only`;
      this.pageInstuction = $localize`To perform Safety Inspections, the applicant's Name (First, Middle and Last) MUST match precisely with the name as recorded on your Skilled Trades Ontario (STO) membership information. An active STO Certificate of Qualification must be held for the required compulsory trade(s) to perform safety inspections. You have selected 'Technician Only' - you will be unable to start a new Vehicle Inspection Centre (VIC) application with this option. Select 'Previous' to go back and choose 'Owner/Technician’ if you wish to register a new VIC. <br>Complete the form below (* marks required fields).`;
      this.emailTooltip = '';
    }else {
      this.pageLabel = $localize`Step 2 of 4 - Vehicle Inspection Centre Owner/Technician`;
      this.pageInstuction = $localize`To perform Safety Inspections, the applicant's Name (First, Middle and Last) MUST match precisely with the name as recorded on your Skilled Trades Ontario (STO) membership information. An active STO Certificate of Qualification must be held for the required compulsory trade(s) to perform safety inspections. If you are not performing Safety Inspections, matching the STO information is not required. <br>Complete the form below (* marks required fields).`;
      this.emailTooltip = $localize`Make sure this is an active email, we will send important emails and documentations.`;
    }

    this.service.getVerificationCode();
  }

  validateEmail(email:string) {
    if (this.confirmEmailAddressField) {
      this.confirmEmailAddressField.message = '';
      this.invalidEmailAddr = true;
      if(isValidEmail(this.userProfile.emailAddress) && !isBlank(this.userProfile.confirmEmailAddress)) {
        if (this.userProfile.confirmEmailAddress.toLowerCase() !== this.userProfile.emailAddress.toLowerCase()) {
           this.confirmEmailAddressField.message = $localize`Email address does not match`;
        }else {
          this.commonService.checkEmailExists(this.userProfile.emailAddress, this.checkEmailCallBack, this.commonService.showErrorMsg);
        }
      }
    }
  }

  checkEmailCallBack = (result:any) =>{
    if (result && result.responseCode !== 0) {
      this.confirmEmailAddressField.message = $localize`Email address has already been registered in the system.`;
    } else {
      this.invalidEmailAddr = false;
      this.confirmEmailAddressField.message = this.isTechnician?'':$localize`Make sure this is an active email, we will send important emails and documentations.`;
      clickInputField('LOGIN.RegistrationPage2.MobilePhone');
    }
  }

  validateMobilePhone() {
    if (this.mobilePhoneField) {
      this.usedMobilePhoneNum = true;
      const validPhoneNum = !isBlank(this.userProfile.mobilePhoneNum) && isValidPhone(this.userProfile.mobilePhoneNum);
      if (validPhoneNum) {
        this.commonService.isMobilePhoneExist(this.userProfile.mobilePhoneNum, this.isPhoneExistCallBack, this.commonService.showErrorMsg);
      }
    }
  }

  isPhoneExistCallBack = (isPhoneExist:boolean) =>{
    this.usedMobilePhoneNum = isPhoneExist;
    this.mobilePhoneField.message = '';
    clickInputField('LOGIN.RegistrationPage2.Phone');
    
    if (isPhoneExist) {
      this.mobilePhoneField.message = $localize`Mobile Phone Number has already been registered in the system.`;
      clickInputField('LOGIN.RegistrationPage2.MobilePhone');
    } 

  }

  changeVerificationCode() {
    this.service.getVerificationCode() ;
  }

  invalidInputs = () => {
    if(this.busPhoneField == null || this.mobilePhoneField == null) {
      return true;
    }

    if(isBlank(this.mobilePhoneField.value) || !isValidPhone(this.mobilePhoneField.value)) {
      this.mobilePhoneChecked = false;
    }else if (!this.mobilePhoneChecked) {  
      this.mobilePhoneChecked = true;
      this.commonService.isMobilePhoneExist(this.mobilePhoneField.value, this.isPhoneExistCallBack, this.commonService.showErrorMsg);
    }

    const validBusPhoneNum = !isBlank(this.busPhoneField.value) && 
                             isValidPhone(this.busPhoneField.value);
    const validMobilePhoneNum = !isBlank(this.mobilePhoneField.value) && 
                                isValidPhone(this.mobilePhoneField.value);
    const validOwnerPhones = validBusPhoneNum && validMobilePhoneNum && !this.usedMobilePhoneNum;

    const validTechPhone = validOwnerPhones || 
                           (validMobilePhoneNum && !this.usedMobilePhoneNum && isBlank(this.busPhoneField.value)) ||
                           (validBusPhoneNum && isBlank(this.mobilePhoneField.value));
    let hasValidPhone = this.isTechnician?validTechPhone:validOwnerPhones;

    if (this.termOfUseChecked) {
      if (this.isTechnician && !validBusPhoneNum && !validMobilePhoneNum) {
        this.mobilePhoneField.message = $localize`At least one phone number is required.`;
      }else if (isBlank(this.userProfile.mobilePhoneNum)) {
        this.mobilePhoneField.message = '';
      }  
    }

    return isBlank(this.userProfile.firstName) || !isValidStr(this.userProfile.firstName, this.userNamePattern)
        || isBlank(this.userProfile.lastName) || !isValidStr(this.userProfile.lastName, this.userNamePattern)
        || (!isBlank(this.userProfile.middleName) && !isValidStr(this.userProfile.middleName, this.userNamePattern))
        || this.invalidEmailAddr
        || !hasValidPhone
        || (!isBlank(this.userProfile.busPhoneNumExt)  && !isNumeric(this.userProfile.busPhoneNumExt))
        || isBlank(this.verificationCode) || !this.termOfUseChecked;
  }

  clear() {
    this.userProfile.firstName = '' ;
    this.userProfile.lastName = '' ;
    this.userProfile.middleName = '' ;
    this.userProfile.preferredLanguage = 'en';
    this.userProfile.emailAddress = '' ;
    this.userProfile.confirmEmailAddress = '' ;
    this.userProfile.busPhoneNum = '' ;
    this.userProfile.busPhoneNumExt = '' ;
    this.userProfile.mobilePhoneNum =  '' ;
    this.verificationCode = '' ;
    this.termOfUseChecked = false;
    this.isOwnerTech == null;
  }

  submit() {
    if ( (this.verificationCode.toLowerCase() !== this.service.captcha.captchaValue.toLowerCase())) {
      this.verificationCodeField.message = $localize`Invalid Verification code.` ;
    } else {
      this.userProfile.busPhoneNum = phoneString2Number(this.userProfile.busPhoneNum);
      this.userProfile.mobilePhoneNum = phoneString2Number(this.userProfile.mobilePhoneNum);
      if ( !this.isTechnician) {
        this.userProfile.departmentId = this.service.IC_OWWER_TECH;
        this.isOwnerTech == true;
     //   this.service.genNumberUserId(this.genUserIdCallBack, this.commonService.showErrorMsg );
      }/* else {
        this.service.genNumberUserId(this.genUserIdCallBack, this.commonService.showErrorMsg );
      } */
      this.genUserIdCallBack();
    }
  }

  genUserIdCallBack(){
    //  this.userProfile.recommendPortalId = result != null?result.value:null;
      this.userProfile.step = 'station-user-register-step3';
      this.router.gotoUrl('station-user-register-step3');
    }

  openTermConditions() {
    this.showTermConditions = true;
    setTimeout(() => {
      this.termConditionsDialog.focus();
    }, 200);
  }
}

<mat-form-field [floatLabel]="inputdisabled?'always':'auto'"
  [floatLabel]="inputdisabled?'always':'auto'">

  <input #formControl="ngModel" [required]="true" matInput [disabled]="inputdisabled" [ngxMatDatetimePicker]="picker"
    [min]="minDate" i18n-placeholder [placeholder]="placeholder" [(ngModel)]="appdate" (keydown.enter)="handleKeyEnter($event)"
    (ngModelChange)="inputChange($event)" (focus)="picker.close()">
  <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepMinute]="stepMinute"
    [stepHour]="stepHour" [defaultTime]="defaultTime"></ngx-mat-datetime-picker>
  <mat-error *ngIf="formControl.hasError('required')"> is required</mat-error>
  <mat-error *ngIf="!formControl.valid">Invalid </mat-error>
</mat-form-field>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content>
    <div class="toolbar-container">
      <button id="MainScreen.Home" class="homeBtn" (click)="goHome()" matTooltip="Back to home page" aria-label="Back to home page">
        <img style="max-width:90%" src="assets/img/DriveON_Portal_En.png" alt="Ontario DriveON" *ngIf="!switchLocale || switchLocale.includes('fr')" />
        <img style="max-width:90%" src="assets/img/DriveON_Portal_Fr.png" alt="Ontario DriveON" *ngIf="switchLocale && switchLocale.includes('en')" />
      </button>
      <span class="vl"></span>
      <span class="toolbar-item header_text width4075">
        {{appName}}
       </span>
      <!--Version + Logout -->
      <span class="toolbar-item versionLogout">
        <br>
        <span>
          <img src="assets/img/parsons_logo.png" style="width:138px;height:29px;padding-right: 10px;" alt="Parsons Logo" />
        </span>
        <br>
        <!--Environment + version-->

        <span id="banner_env"
          style="float: right; color:#50bf34; font-family: Arial, Helvetica, sans-serif; text-decoration: none; font-size: 12px;padding-right: 10px;">
          {{env}} - {{version}}
          <br><br>
          <span *ngIf="env == 'LOCAL'">
            <a i18n [routerLink]="'user-register-step1'" (click)="isRegistration = true;">REGISTER</a>
          </span>&nbsp;&nbsp;
          <span *ngIf="env == 'LOCAL'">
            <a i18n href="#" (click)="commonService.help($event)">Help</a>
          </span>&nbsp;&nbsp;
          <span *ngIf="env != 'Production' && env != 'Stage'">
            <a href="#" (click)="switch($event)">{{switchLanguage}}</a>
          </span>  
        </span>
      </span>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
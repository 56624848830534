<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating"
    [fullScreen]="true">
    <p i18n style="color: white">Processing...</p>
</ngx-spinner>
<app-form-panel applabel="">
  <div fxLayout="row wrap" fxLayoutGap="30px" fxLayout.lt-md="column" style="display:block">
    <div style="width: 350px">
      <img src="assets/img/ICAA_Banner_Image_Phase1_Final.jpg" alt="ICAA">
    </div>
    <div appwidth="100%" fxFlex="55%">
      <h2 ngClass="onboardingStepMainTitle" tabindex="0">
        {{welcomeMessage}}<br>
      </h2> <br>
      
      <!-- <a i18n href="assets/docs/240405_DriveON_Phase2_3a_PEP_v2_11_Section5.pdf" target="_blank">
        <span ngClass="onboardingStepSubTitle" style="display:flex">List of Documents you need for a successful registration</span>
      </a> -->
      
      <a i18n  ngClass="onboardingStepSubTitle" style="display:flex" style="cursor: pointer;" href="assets/docs/240405_DriveON_Phase2_3a_PEP_v2_11_Section5.pdf" target="_blank">
        See the list of documents you need for a successful registration
      </a><br/><br/><br>
      
      <img style="height:50px;width:50px;vertical-align:middle;" src="assets/img/videoicon.png" alt="Watch the Vehicle Inspection Centre Enrolment video">
      <a i18n href="#" (click)="playVideo()" tabindex="0">       
        <span style="padding:5px;">Watch the Vehicle Inspection Centre Enrolment video</span>
      </a><br>
      
    </div>
  </div><br/>
  <div style="width:90%; text-align: center; background-color: #FCAF17 !important; padding: 15px; border-radius: 15px; font-size: 18px;">
    <mat-label i18n tabindex="0" i18n class="important_instruction" style="font-size: 18px;">
      ATTENTION:<br>Only existing Motor Vehicle Inspection Stations (MVIS) are eligible to apply.
    </mat-label>
  </div><br/>
  <mat-label i18n ngClass="onboardingStepSubTitle">
    Click each step below to begin.
  </mat-label>
  <br/><br/>
  <table>
    <tr *ngFor="let step of onboardingSteps">
      <td style="width: 100%; text-align: left;" *ngIf="step.stepStatus === 'C'">
        <span *ngIf="!step.stepDisabled">
          <img style="height:50px;width:50px; vertical-align: middle;" src="assets/img/check_mark_green.png"
            alt="{{translateStepTitle(step)}} Completed">&nbsp;&nbsp;
            <a i18n tabindex="0" (click)="navigateToOnboardingStep(step)" 
              (keyup.enter)="navigateToOnboardingStep(step)"
              attr.aria-label="{{translateStepTitle(step)}} Completed"
              style="text-decoration: none; cursor:pointer; background: none; border: none;">
              <span style="border-width: 0px; font-size:20px; color: black; cursor: pointer;
                font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif; font-weight: 700;
                font-style: normal;">{{translateStepTitle(step)}}
              </span>
            </a>
        </span>
        <span *ngIf="step.stepDisabled">
          <img style="height:50px;width:50px; vertical-align: middle;" src="assets/img/not_active_{{step.stepNum}}.png"
            alt="{{translateStepTitle(step)}} Not Active" tabindex="0">&nbsp;&nbsp;
          <span title="not active" i18n
            style="border-width: 0px; font-size:20px; color: black;
            font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif; font-weight: 700;
            font-style: normal;">{{translateStepTitle(step)}} <span style="color:red;">{{setExtraStepTitle}}</span>
          </span>
        </span>
      </td>
      <td style="width: 100%; text-align: left;" *ngIf="step.stepStatus === 'I'">
          <span *ngIf="!step.stepDisabled">
            <img style="height:50px;width:50px; vertical-align: middle;" src="assets/img/active_{{step.stepNum}}.png"
              alt="{{translateStepTitle(step)}} In Progress">&nbsp;&nbsp;
            <a i18n tabindex="0" (click)="navigateToOnboardingStep(step)" 
              (keyup.enter)="navigateToOnboardingStep(step)"
              disabled="step.stepDisabled"
              attr.aria-label="{{translateStepTitle(step)}} in progress"
              style="text-decoration: none;cursor:pointer;background: none; border: none;">
              <span style="border-width: 0px; font-size:20px;color: black; cursor: pointer;
                font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif; font-weight: 700;
                font-style: normal;">{{translateStepTitle(step)}}
              </span>
            </a>
          </span>
          <span *ngIf="step.stepDisabled">
            <img style="height:50px;width:50px; vertical-align: middle;" src="assets/img/not_active_{{step.stepNum}}.png"
              alt="{{translateStepTitle(step)}} Not Active" tabindex="0">&nbsp;&nbsp;
            <span title="not active" i18n
              style="border-width: 0px; font-size:20px; color: black;
              font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif; font-weight: 700;
              font-style: normal;">{{translateStepTitle(step)}} <span style="color:red;">{{setExtraStepTitle}}</span>
            </span>
          </span>
        </td>
        <td style="width: 10%; text-align: left;" *ngIf="step.stepStatus === 'N'">
          <img style="height:50px;width:50px; vertical-align: middle;" src="assets/img/not_active_{{step.stepNum}}.png"
            alt="{{translateStepTitle(step)}} Not Active" tabindex="0">&nbsp;&nbsp;
          <span title="not active" i18n
            style="border-width: 0px; font-size:20px; color: black;
            font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif; font-weight: 700;
            font-style: normal">{{translateStepTitle(step)}}
          </span>
        </td>
    </tr>
  </table><br>
  <button i18n id="ICAA.OnboardingSteps.Cancel" mat-raised-button (click)="cancel()">Back to Main Page</button><br><br><br>
</app-form-panel>
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from '../../../services/login.service';
import { CommonService } from 'apps/common/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { environment } from 'apps/common/environments/environment';
import { getLocale } from 'apps/common/helper/util-function-class';

@Component({
  selector: 'login-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild("messageDialog") messageDialog:any;

  url:string;
  msgServiceError = $localize`Oops, something went wrong! Please try again later.`;
  showMessage:boolean = false; 
  message = '';
  embeddedDashboard:any;
  params: any;
  dashboardId:string;
  sheetId:string;
  action:string;
  userId:string;
  newWindow:string;
  searchCriteria: any = {};
  searchApiURL = environment.icaaApiUrl + '/findStationOptions';

  isDirectorUser: boolean = false;
  isMinistryUser: boolean = false;

  ICAA_MINISTRY_ROLE_NAME = "icaaministryuser";
  ICAA_DIRECTOR_ROLE_NAME = "icaaministrydirector";
  
  constructor(public commonService: CommonService, public service: LoginService, private route: ActivatedRoute, public sanitizer: DomSanitizer) {
    this.userId = this.commonService.getUserId();
  }

  ngOnInit(): void {
    let userAuthorities = this.commonService.getUserAuthorities();
    userAuthorities.forEach(element => {
      if (!this.isMinistryUser && element.role && (element.role.toLowerCase().includes(this.ICAA_MINISTRY_ROLE_NAME))) {
        this.isMinistryUser = true;
      }
      if (!this.isDirectorUser && element.role && (element.role.toLowerCase().includes(this.ICAA_DIRECTOR_ROLE_NAME))) {
        this.isDirectorUser = true;
      }
    });

    var url  = new URL(document.referrer);
    this.route.params.subscribe(params => {
      this.dashboardId = params['id'];
      if (this.dashboardId == 'main')
        this.service.getDashboardId(this.setDashboardId, this.showErrorMsg); 
    });

    this.route.queryParams.subscribe(params => {
      this.params = JSON.parse(JSON.stringify(params).replaceAll('p.', ''));
      this.params.prmEnvironment = url.hostname;
      this.params.prmLocale = getLocale();
      this.action = this.params['action']; 
      this.newWindow = this.params['newWindow']; 

      if (this.action != null) {
        if ((this.action.includes('mto') && !this.isMinistryUser) || 
          (this.action.includes('director') && !this.isDirectorUser)) {
          this.message = "You don't have permission to approve VIC";   
          this.showMessage = true;
          return;
        }
        switch(this.action) {
          case 'mtoApprove': {            
            this.service.mtoApprove(this.userId, this.approveCallback, this.showErrorMsg )
            break;
          }
          case 'directorApprove': {
            this.service.directorApprove(this.userId, this.approveCallback, this.showErrorMsg )
            break;
          }
          case 'mtoSingleApprove': {
            var stationId = this.params['stationId']; 
            this.service.mtoSingleApprove(this.userId, stationId, this.approveCallback, this.showErrorMsg )
            break;
          }
          case 'directorSingleApprove': {
            var stationId = this.params['stationId']; 
            this.service.directorSingleApprove(this.userId, stationId, this.approveCallback, this.showErrorMsg )
            break;
          }        
          default: {
            console.error('Unknown action ' + this.action);
          }
        }
      }
      else if (this.newWindow != null && this.newWindow === 'No') {
        console.log(window.location.href);
        const params = new URLSearchParams(window.location.href.split('?')[1]);
        params.delete("newWindow");
        if (params.toString() != "") {
          parent.location.href = window.location.href.split('?')[0]+"?"+params;
        }
        else {
          parent.location.href = window.location.href.split('?')[0];
        }
        parent.location.reload();
      }
      else if(this.dashboardId != 'main') {
        this.loadDashboard();
      }
    });
  }

  setDashboardId = (result: any) => {
    if (result != null) {
      this.dashboardId = result.reportUrl;
      this.loadDashboard();
    }
  }

  loadDashboard() {
    if(this.dashboardId === 'console')
      this.service.getConsoleUrl(this.dashboardId, this.getUrlCallback, this.getUrlError);
    else  
      this.service.getDashboardUrl(this.dashboardId, this.getUrlCallback, this.getUrlError);
  }
  
  approveCallback = (result:any) =>{
    this.message = "The total of approved VICs is " + result;      
    this.showMessage = true;
  }

  getUrlCallback = (result:any) => {
    document.getElementById('dashboardContainer').innerHTML = ""; 
    if( result != null ) {
      this.url = result.value;
      const options = {
        url: result.value,
        container: document.getElementById('dashboardContainer'),
        parameters: this.params,
        scrolling: "yes",
        height: "AutoFit",
        width: "100%"
      };
      this.embeddedDashboard = embedDashboard(options);
      this.embeddedDashboard.on('error', this.onError);
      this.embeddedDashboard.on('load', this.onDashboardLoad);
    }   
  }

  onDashboardLoad() {
    // Quicksight has min-width set to 800px, so when enlarge to 200%, it shows part of the dashboard.
    // Trick, in html set width to 500px. After load, enlarge to 100%
//    const dashboardContainer = document.getElementById("dashboardContainer");
//    dashboardContainer.setAttribute("style","width:100%");
  }

  onError(payload) {
    console.log("Do something when the dashboard fails loading", payload);
  }

  close() {
    this.showMessage = false;
  }

  getUrlError = (error:any) =>  {
    if( error !== null && 
      error.error !== null && 
      error.error.serviceMessage !== undefined && 
      error.error.serviceMessage != null ) {
      const serviceMessage = error.error.serviceMessage;
//      this.message = translateMessage(serviceMessage.serviceName, serviceMessage.msgId, serviceMessage.msgValues);
    } 
    else {
//      this.message = this.msgServiceError;
      console.log(error.message + '(response code: ' + error.status + ')');
    }
  }

  showErrorMsg = (error: any) => {
    console.log('####### error: ' + JSON.stringify(error));
    if (error.status === 400) {
      alert($localize`Invalid request parameters!`);
    } else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    } else if (error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }
}

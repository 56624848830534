import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MaterialModule } from './material.module';
import { AppDatePickerComponent } from '../components/app-date-picker/app-date-picker.component';
import { AppDateTimePickerComponent } from '../components/app-datetime-picker/app-date-time-picker.component';
import { AutocompleteFieldComponent } from '../components/autocomplete-field/autocomplete-field.component';
import { CardComponent } from '../components/card/card.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { CustomDialogComponent } from '../components/custom-dialog/custom-dialog.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { FooterComponent } from '../components/footer/footer.component';
import { PwsFooterComponent } from '../components/pws-footer/footer.component';
import { FormPanelComponent } from '../components/form-panel/form-panel.component';
import { InputFieldComponent } from '../components/input-field/input-field.component';
import { PaddedPanelComponent } from '../components/padded-panel/padded-panel.component';
import { PhoneFieldComponent } from '../components/phone-field/phone-field.component';
import { PhoneFieldMaskComponent } from '../components/phone-field-mask/phone-field-mask.component';
import { RouterWrapperComponent } from '../components/router-wrapper/router-wrapper.component';
import { SelectFieldComponent } from '../components/select-field/select-field.component';
import { SelectListComponent } from '../components/select-list/select-list.component';
import { SimpleDialogComponent } from '../components/simple-dialog/simple-dialog.component';
import { TextareaComponent } from '../components/textarea/textarea.component';
import { StaticHtmlComponent } from '../components/static-html/static-html.component';
import { AwsChatComponent } from '../components/aws-chat/aws-chat.component';
import { PhoneFormatPipe } from '../pipes/phone-format.pipe';
import { UnsupportedBrowserComponent } from '../components/unsupported-browser/unsupported-browser.component';
import { HighlightPipe } from '../pipes/highlight.pipe';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { MultiSelectFieldComponent } from '../components/multi-select-field/multi-select-field.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { ImageModalComponent } from '../components/image-modal/image-modal.component';

@NgModule({
  declarations: [
    AppDatePickerComponent,
    AppDateTimePickerComponent,
    AutocompleteFieldComponent,
    CardComponent,
    ChangePasswordComponent,
    CustomDialogComponent,
    DialogComponent,
    FooterComponent,
    PwsFooterComponent,
    FormPanelComponent,
    InputFieldComponent,
    PaddedPanelComponent,
    PhoneFieldComponent,
    PhoneFieldMaskComponent,
    RouterWrapperComponent,
    SelectFieldComponent,
    SelectListComponent,
    SimpleDialogComponent,
    TextareaComponent,
    UnsupportedBrowserComponent,
    HighlightPipe,
    PhoneFormatPipe,
    AwsChatComponent,
    StaticHtmlComponent,
    MultiSelectFieldComponent,
    FileUploadComponent,
    ImageModalComponent
  ],
  imports: [
    LayoutModule,
    FlexLayoutModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  exports: [
    AppDatePickerComponent,
    AppDateTimePickerComponent,
    AutocompleteFieldComponent,
    CardComponent,
    ChangePasswordComponent,
    CustomDialogComponent,
    DialogComponent,
    FooterComponent,
    PwsFooterComponent,
    FormPanelComponent,
    InputFieldComponent,
    PaddedPanelComponent,
    PhoneFieldComponent,
    PhoneFieldMaskComponent,
    RouterWrapperComponent,
    SelectFieldComponent,
    SelectListComponent,
    SimpleDialogComponent,
    TextareaComponent,
    UnsupportedBrowserComponent,
    HighlightPipe,
    PhoneFormatPipe,
    AwsChatComponent,
    StaticHtmlComponent,
    MultiSelectFieldComponent,
    FileUploadComponent,
    ImageModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentModule {}

<app-form-panel i18n-applabel applabel="Standard Operating Procedures and Directives">
    <br>
    <label i18n style="padding-left: 10px; display:flex" class="select-a-inspection">
        Click on each accordion below to view the documents 
    </label><br><br>
    <br>
    <table style="width:100% !important;" cellpadding="0" cellspacing="2">
        <tr>
            <td>
                <div #step1 tabindex="0">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                            <mat-panel-title i18n id="ICAA.InspectionCentre.SOP" class="mpt">
                                Standard Operating Procedure (SOP)
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <span *ngFor="let step of soFileList">
                            <a i18n style="cursor: pointer; font-size: 16px;" href="#" (click)="openFile(step)" target="_blank">
                                {{step.displayName}}
                            </a><br/><br/>
                        </span>
                    </mat-expansion-panel>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div #step1 tabindex="0">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                            <mat-panel-title i18n id="ICAA.InspectionCentre.Directives" class="mpt">
                                Directives
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <span *ngFor="let step of directiveFileList">
                            <a i18n style="cursor: pointer; font-size: 16px;" href="#" (click)="openFile(step)" target="_blank">
                                {{step.displayName}}
                            </a><br/><br/>
                        </span>
                    </mat-expansion-panel>
                </div>
            </td>
        </tr>
    </table>
</app-form-panel>
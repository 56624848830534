<table mat-table [dataSource]="appdata" class="mat-elevation-z8" [ngStyle]="{'width': '100%'}">
  <ng-container [matColumnDef]="column" *ngFor="let column of columns">
    <th mat-header-cell *matHeaderCellDef>{{columnsNames[columns.indexOf(column)]}}<span *ngIf="column==='action'">Action</span></th>
    <td mat-cell *matCellDef="let element">{{element[column]}}
      <span *ngIf="column==='action'"><a href="">Delete</a></span>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row [ngClass]="{'highlight': selectedRowIndex == getSelectedRow(row)}" *matRowDef="let row; columns: columns;"  (dblclick)="doubleClickAction(row)" (click)="highlight(row)"></tr>
</table>
<span *ngIf="appactionname != undefined && appaction != undefined">
<br/>
<button mat-stroked-button color="primary" (click)="appaction()">{{appactionname}}</button>
</span>

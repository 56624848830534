import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AmtService } from 'apps/amt/src/app/service/amt.service';

export interface DialogData {
  title:string;
  reason:string;
  lockoutReason: string;
  auditId;
  auditDate;
  id:string;
  name:string;
  action:string;
  acknowledged:boolean;
  additionalMessage:string;
  ok:'Close';
}

@Component({
  selector: 'app-compliance-dialog',
  templateUrl: './compliance-dialog.component.html',
  styleUrls: ['./compliance-dialog.component.css']
})
export class ComplianceDialogComponent implements OnInit {

  title = '';

  constructor(public dialogRef: MatDialogRef<ComplianceDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) 
    public dialogData: DialogData, public amtService:AmtService) {
  }

  ngOnInit() {
  }

  showReport() {
    if(this.dialogData.auditId != null && this.dialogData.auditId != undefined){
    this.amtService.downloadAuditReportByAuditId(this.dialogData.auditId).subscribe(
      response => {
        let blob:any = new Blob([response.body], { type: response.body.type });
        const url= window.URL.createObjectURL(blob);
        window.open(url);
      },
      err => {
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}

<mat-form-field [ngStyle]="{'width':appwidth, 'padding':'2px'}"
  [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'"
  [floatLabel]="inputdisabled?'always':'auto'">
  <mat-label [ngClass]="{'labelColor': labelColorRequired}">{{placeholder}} <span *ngIf="labelColorRequired">(required)</span></mat-label>
  <input  #formControl="ngModel" [id]="cmpntId" [required]="required"
    [disabled]="inputdisabled || commonService.isCmpntDisabled(cmpntId)"
    matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate"
    [placeholder]="placeholder" [(ngModel)]="appdate" (keydown.enter)="handleKeyEnter($event)"
    (ngModelChange)="inputChange()" (focus)="picker.close()">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error *ngIf="formControl.hasError('required')" i18n>{{placeholder}} is required</mat-error>
  <mat-error *ngIf="formControl.hasError('invalidInput')" i18n>Invalid {{placeholder}}</mat-error>
  <mat-error *ngIf="formControl.hasError('matDatepickerMin')" i18n>{{placeholder}} cannot be before {{minDate.toLocaleDateString('en-CA')}}</mat-error>
  <mat-error *ngIf="formControl.hasError('matDatepickerMax')" i18n>{{placeholder}} cannot be after {{maxDate.toLocaleDateString('en-CA')}}</mat-error>
</mat-form-field>

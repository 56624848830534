<ngx-spinner bdColor= "rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating" [fullScreen]="true">
	<p i18n style="color: white">Loading...</p>
</ngx-spinner>

<app-form-panel i18n-applabel applabel="User Registration">
  <app-card i18n-applabel apptitle="Step 3 of 4 - Confirm Account"> 
    <p i18n style="font-weight: bold; font-size: 16px">Create a security question and answer. Information marked with * are required fields.</p>
    <app-input-field i18n-placeholder placeholder="Security Question"
      cmpntId="LOGIN.RegistrationPage3.SecurityQuestion" #question [required]="true"      
      appwidth="100%" [(inputValue)]="userProfile.securityQuestion">
    </app-input-field>
    <br><br>
    <app-input-field i18n-placeholder placeholder="Security Question Answer"
      cmpntId="LOGIN.RegistrationPage3.SecurityQuestionAnswer" #answer [required]="true"
      appwidth="100%"      
      [(inputValue)]="userProfile.securityQuestionAnswer"></app-input-field>
    <p i18n>
      Click on the Create Account button to finish setting up your account.
    </p>
    <p i18n>
      Security answer is not case sensitive.
    </p>
  </app-card>
  <app-padded-panel>
    <br/><br/><br/>
    <button i18n id="LOGIN.RegistrationPage3.Previous" mat-raised-button routerLink="/user-register-step2">Previous</button>&nbsp;
    <button i18n id="LOGIN.RegistrationPage3.CreateAccount" mat-raised-button (click)="submit()" [disabled]="invalidInputs()">Create
      Account</button>&nbsp;
    <button i18n id="LOGIN.RegistrationPage3.Cancel" mat-raised-button routerLink="/login/sso">Cancel</button>&nbsp;
    <br/><br/><br/><br/><br/>
    <div fxLayout="row wrap">
      <span i18n align="center" class="bg" fxFlex="30%">Select User Type</span>
      <span i18n align="center" class="bg" fxFlex="30%">User Info Creation</span>
      <span i18n align="center" class="bg" fxFlex="30%">Confirm Account</span>
    </div>
    <div fxLayout="row wrap">
      <span align="center" class="bggreen" height="9" fxFlex="33%" style="height:9px;"></span>
      <span align="center" class="bggreen" height="9" fxFlex="33%" style="height:9px;" ></span>
      <span align="center" class="bgred" height="9" fxFlex="33%" style="height:9px;" ></span>
    </div>
  </app-padded-panel>
  <app-padded-panel>&nbsp;</app-padded-panel>
</app-form-panel>


<mat-form-field [ngStyle]="{'width':appwidth, 'padding': '2px'}" 
  [floatLabel]="inputdisabled?'always':'auto'"
  [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'">
  <mat-label [ngClass]="{'labelColor': labelColorRequired}">{{placeholder}} <span *ngIf="labelColorRequired">(required)</span></mat-label>
  <textarea [id]="cmpntId" matInput #formControl="ngModel" placeholder="{{placeholder}}"
    [maxlength]="maxlength"
    [minlength]="minlength"
    [(ngModel)]="inputValue"  (ngModelChange)="update()"
    [disabled]="inputdisabled || commonService.isCmpntDisabled(cmpntId)"
    [required]="required" 
    [style]="style" (keydown.enter)="handleKeyEnter($event)"
    [rows]="rows">
  </textarea>
  <mat-error *ngIf="formControl.hasError('required')">{{placeholder}} is required</mat-error>
  <mat-error *ngIf="formControl.hasError('minlength')">{{placeholder}} must be at least {{minlength}} characters</mat-error>
  <mat-error *ngIf="formControl.hasError('maxlength')">{{placeholder}} cannot be more than {{maxlength}} characters</mat-error>
</mat-form-field>


import {Injectable, OnInit} from '@angular/core';
import {CommonService} from '../../../../common/services/common.service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileGuardService implements  OnInit, CanActivate {
  IC_USER = 3;

  ngOnInit(): void {
  }

  constructor(private commonService: CommonService, private _router: Router ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const uri = window.location.href.split('/');
    const nextStep = uri[uri.length-1];
    const currStep = this.commonService.userProfile.step;

    if (currStep != null && (currStep.length != nextStep.length ||  currStep < nextStep)) {
      this._router.navigate([currStep]);
      return false;
    }

    return true;
  }
}

import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { of } from 'rxjs'
import { catchError, map } from 'rxjs/operators';
import { environment } from 'apps/common/environments/environment';

const datePipe:DatePipe = new DatePipe('en-CA');
const imgFileExt = ['jpg','jpeg','jfif','png', 'gif', 'tiff'];

export function isBlank(str: string): boolean {
  return (str === null || str === undefined || str.toString().trim().length === 0) ;
}

export function isEmpty(obj:any) {
  return obj == null || obj.length === 0;
}

export function  getLocale(): string {
  const locale = window.location.pathname.split('/')[2];
  return !isBlank(locale) && (locale.toLowerCase() == 'en' || locale.toLowerCase() == 'fr')?locale:'en';
}

export function isValidStr(val:string, regExp:RegExp) {
  return !isBlank(val) &&  new RegExp(regExp).test(val);
}

export function  isAlphabet(val: string): boolean {
  return !isBlank(val) && /^[a-zA-Z ]*$/.test(val);
}

export function  isNumeric(val: string): boolean {
  return !isBlank(val) && /^[0-9]+$/.test(val);
}

export function  isYes(val: string): boolean {
  return val && val.toUpperCase() == 'Y';
}

export function  isNo(val: string): boolean {
  return val && val.toUpperCase() == 'N';
}

export function  isValidTransitNum(val: string): boolean {
  return !isBlank(val) && /^[0-9-]{1,5}$/.test(val);
}

export function  isValidTCVNum(val: string): boolean {
   //return val.replace(/[^A-Za-z0-9]+/g, '').match(/(\d{2})(\d{6})([A-Z]{3})/) != null; correct
  return !isBlank(val) && /^[0-9]{2}-\d{6}-TCV$/.test(val);
}

//https://stackoverflow.com/questions/12310837/implementation-of-luhn-algorithm
export function isValidBusinessNumber(sin: string) {
  if(isBlank(sin)) {
    return false;
  } else if(!isBlank(sin) && sin.length < 9) {
    return false;
  } else if(!isSinStartwithCorrectNum(sin)){
    return false;
  } else {
    let nSum:number=0;
    let isSecond:boolean = false;
    for (let i = sin.length-1; i >= 0; i--) {
      let cDigit:any = sin.charAt(i);
      let nDigit = parseInt(cDigit, 10);
      if (isSecond)
        if ((nDigit *= 2) > 9) nDigit -= 9;
      nSum += nDigit;
      isSecond = !isSecond;
    }
    return (nSum % 10 == 0);
  }
}

export function isSinStartwithCorrectNum(sin: string) {
  // start with 1, 7 or 8 - [178][0-9]{8}
  //let flag: boolean = !isBlank(sin) && /^[178][0-9]{8}$/.test(sin);
  //console.log('isSinStartwithCorrectNum flag: ' + flag);
  return !isBlank(sin) && /^[178][0-9]{8}$/.test(sin);
}

export function  isValidMonth(val: string): boolean {
  const month = !isBlank(val)?parseInt(val):0;
  return month > 0 && month < 13;;
}

export function  isValidYear(val: string): boolean {
  const year = !isBlank(val)?parseInt(val):0;
  return year > 1999 && year < 2100;
}

export function isValidWebSiteUrl(val: string): boolean {
  const pattern = /^(?:HTTP(S)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  return !isBlank(val) && pattern.test(val);
}

export function  isAlphaNumeric(val: string): boolean {
  return !isBlank(val) && /^\S[a-zA-Z0-9 ]*$/.test(val);
}

export function  isAlphaNumericColun(val: string): boolean {
  return !isBlank(val) && /^\S[a-zA-Z0-9;]*$/.test(val);
}

export function isValidEmail(val: string): boolean {
  return !isBlank(val) && /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(val);
}

export function  isString(val: string): boolean {
  return !isBlank(val) && /^[a-zA-Z ]*$/.test(val);
}

/*
A valid canadian postcode is –
in the format A1A 1A1, where A is a letter and 1 is a digit.
a space separates the third and fourth characters.
do not include the letters D, F, I, O, Q or U.
the first position does not make use of the letters W or Z
*/
export function isValidPostalCode(val: string): boolean {
  return !isBlank(val) && /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/.test(val);
}

export function isValidPostalCodeNoSpace(val: string): boolean {
  return !isBlank(val) && /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z]?[0-9][A-Za-z][0-9]$/.test(val);
}

export function formatPostalCode(param) {
  if(!param || param === undefined)
  return param;
  let value = param != null && param.value != null ? param.value.toUpperCase():param.toUpperCase();
  if(value != null && value.length >= 6) {
    if(isValidPostalCode(value)) {
      if(value.charAt(3) != ' ') {
        return value.substring(0,3) + ' ' + value.substring(3, 7);
      } else {
        return value;
      }
    }
    return value;
  }
  return value;
}

export function isValidPhone(val: string): boolean {
  if (val == undefined)
    return false;
  const num = phoneString2Number(val.toString());
  return !isBlank(num) && isNumeric(num) && (/^1\d{10}$/.test(num) || /^[2-9]\d{9}$/.test(num));
}

export function phoneString2Number(val: string):string{
  if (isNumeric(val))
    return val;
  return val!=null?val.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '').trim():null
}

export function isValidPhoneMask(val: string): boolean {
  return !isBlank(val) && isNumeric(val) && (val.toString().length == 10);
}

export function isValidPhoneExt(val: string): boolean {
  return !isBlank(val) && /^(\d)?(\d)?(\d)?(\d)?(\d)?$/.test(val);
}

export function isValidZip(zipCode) {
  return !isBlank(zipCode) && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
}

export function formatPhoneNumber(param) {
  const value = param != null && param.value != null?param.value:param;
  if (value != null) {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return value;
  }
  return '';
}

export function yesNoFormatter(param) {
  const value = param != null && param.value != null?param.value:param;
  return value === 'Y'?$localize`Yes`:$localize`No`;
}

export function dateFormatter(param) {
  const value = param != null && param.value != null?param.value:param;
  return value != null? datePipe.transform(value):'';
}

export function dateFormatLong(param, format) {
  //return new Date(moment(param).format('yyyy-MM-ddTHH:mm:s'));
  return !isBlank(param) ? datePipe.transform(param, format, 'UTC'):null;
}

export function dateFormat(param, format) {
  return !isBlank(param)?new Date(moment(param).format(format)):null;
  //return !isBlank(param) ? datePipe.transform(param, format):null;
}

export function timeToDate(timeStr:any) {
  var timeArr = timeStr.split(":");
  var d =  new Date();
  d.setMinutes(timeArr[1]);
  d.setHours(timeArr[0]);
  return d;
}

export  function statusFormatter(param) {
  const value = param != null && param.value != null?param.value:param;
  switch (value) {
    case 'A': {
      return 'Active';
    }
    case 'E': {
      return 'Enabled';
    }
    case 'D': {
      return 'Disabled';
    }
    case 'X': {
      return 'Deleted';
    }
    case 'L': {
      return 'Locked out';
    }
  }
}

export function randomString(len, an) {
  if (an) {
    an = an&&an.toLowerCase();
    let str='', i=0;
    const min=an==='a'?10:0, max=an==='n'?10:62;
    for(;i++<len;) {
      let r = Math.random()*(max-min)+min;
      str += String.fromCharCode(r+=r>9?r<36?55:61:48);
    }
    return str;
  }
  return null;
}

export function getShortText(text, len) {
  if (text) {
    if (text.length < len ) {
      return text;
    } else {
      return text.substring(0,len) + '...';
    }
  }
}

export  function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export  function toggleRowSelected(row:any, event:any, dataSource:any) {
  if (event.ctrlKey) {
    row.isSelected = !row.isSelected;
  } else {
    dataSource.data.forEach(row => {row.isSelected = false;});
    row.isSelected = true;
  }
}

export  function arrayToMap(codeValues:[any]) {
  const result = {};
  if (codeValues !== null && codeValues.length > 0) {
    for (const codeValue of codeValues) {
      result[codeValue.code] = codeValue.values != null? codeValue.values:codeValue.value;
    }
  }
  return result;
}

export  function arrayToMapMany(codeValues:[any]) {
  const result = {};
  if (codeValues !== null && codeValues.length > 0) {
    for (const codeValue of codeValues) {
      result[codeValue.code] = codeValue.values[0];
    }
  }
  return result;
}

export  function arrayCodeToString(codeValues:[any]) {
  let result = '';
  if (codeValues !== null && codeValues.length > 0) {
    for (const codeValue of codeValues) {
      result += ',' + codeValue.code;
    }
  }
  return result.substring(1);
}

export  function convertRoles(authoritiesStr:string) {
  let roles = [];
  if (authoritiesStr) {
    const authorities = authoritiesStr.split(',');
    for (const authority of authorities) {
      roles.push({role:authority});
    }
  }
  return roles;
}

export function isSimilarPasswordPattern(newPwd:string, oldPwd:string) {
  if (!isBlank(newPwd) && !isBlank(oldPwd)) {
    newPwd = newPwd.replace(/[^A-Za-z]/g, '').toLowerCase();
    oldPwd = oldPwd.replace(/[^A-Za-z]/g, '').toLowerCase();
    if (newPwd.length > 2 && oldPwd.length > 2) {
      return newPwd.indexOf(oldPwd) > -1 || oldPwd.indexOf(newPwd) > -1;
    }else {
      return false;
    }
  }
}

export function contains(pwd:string, str:string){
  return str.length>2 && pwd.toUpperCase().indexOf(str.toUpperCase())!=-1
}

export function isValidMvisId(val: string): boolean {
  return !isBlank(val) && isNumeric(val) && (val.toString().length == 7 || val.toString().length == 8);
}

export function toString(val:any):string {
  return val;
}

export function getHTMLInputElementValue(event:any) {
  return  (<HTMLInputElement>event.target).value;
}

export function getHTMLInputElementValueUpperCase(event:any) {
  return  (<HTMLInputElement>event.target).value.toUpperCase();
}

export function ping(url:string) {
  const http = new XMLHttpRequest();
  http.open("GET",  url, /*async*/false);

  try {
    http.send();
    return true;
  } catch(error) {
    return false;
  }
}

export function dateFormatHHmm(param) {
  return moment(param).format('HH:mm');
}

export function clickInputField(field:string){
  document.getElementById(field).click();
}

export function hasEmptyValues(obj:any):boolean {
  const values = Object.values(obj);
  for (const value of values){
    if (value){
      return true;
    }
  }
  return false;
}


export function isImageFile(fileName:string){
  return !isBlank(fileName)&&(imgFileExt.some(str => fileName.endsWith(str))); 
}

export function isDocFile(fileName:string){
  return !isBlank(fileName)&&!this.isImageFile(fileName);
}

export function debounce(func, wait) {
  var timeout;
  return function() {
      var context = this, args = arguments;
      var later = function() {
          timeout = null;
          func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
  };
};

export function loadGoogleMapsApi(httpClient:any):any{
  return httpClient.jsonp(environment.google_maps_api + getLocale(), 'callback').pipe(
    map(() => true),
    catchError((e) => of(false, e)),
  );
}

export function  hasUniCode(aString: string): boolean {
  var hasUnicodeFlag = /[^\u0000-\u00ff]/.test(aString);  
  return hasUnicodeFlag;
}

export function dummyFunc() {}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'login-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.css']
})
export class SimpleDialogComponent implements OnInit {

  message = '' ;

  static openDialog = null;


  constructor(private dialogRef: MatDialogRef<SimpleDialogComponent >, @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog) {
    this.message = data.message;
  }

  public static open (dialog: MatDialog, message: string) {
    const data : any = {
      'message' : message
    };
    let result = SimpleDialogComponent.openDialog = dialog.open(SimpleDialogComponent, { ariaLabel: ' ', autoFocus: false, width: '500px', data});
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(this.dialogRef);
  }
}

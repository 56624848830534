import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormsModule} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import { CommonService } from '../../services/common.service';
import { isBlank } from 'apps/common/helper/util-function-class';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.css']
})
export class SelectFieldComponent implements OnInit {

  @Input() appvalue: any = "";
  @Input() placeholder: string;
  @Input() appoptions: any[] = [];
  @Input() appwidth = '';
  @Input() errorMessage = null;
  @Input() required = false;
  @Input() isvalid = true;
  @Input() disabled = false ;
  @Input() cmpntId = null ;
  @Input() areaLabel = null ;
  @Input() selectFieldClass = 'select-field' ;
  @Input() helpIconName = null;
  @Input() toolTipText: string;
  @Input() labelColorRequired:boolean = false;
  @Output() change = new EventEmitter();
  @Output() appvalueChange = new EventEmitter();

  isBlank = isBlank;

  @ViewChild('parsonsSelect', {static: true})  parsonsSelect : MatSelect ;

  public isReady: boolean ;

  public formControl = new FormControl();

  constructor(public commonService: CommonService) { }

  ngOnInit() {
    this.setIsReady();
  }

  private setIsReady() {
    this.isReady = this.formControl.valid  && this.appvalue != null ;
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }

  onSelectionChange() {
    this.isvalid = this.formControl.valid;
    this.setIsReady();
    this.appvalueChange.emit(this.appvalue);
    this.change.emit(this.appvalue);
  }

  isArray(obj: any) {
    return obj instanceof  Array;
  }
  
}

import { integer } from "aws-sdk/clients/cloudfront";

const msgMap:any = {
  "*":[$localize`System error: {1}  Please try again later.`,
     ],
  UMA:[$localize`Invalid User ID`,
       $localize`Sorry, your account is expired. Please click on the "Forgot User ID and/or Password?" link to reset your password and login.`,
       $localize`Error while trying to get the Vehicle Inspection Centre {1} status information. Please try again later.`,
       $localize`Sorry, your account has been locked. Please click "Forgot User ID and/or Password?" link to unlock your account.`,
       $localize`Sorry, your account is disabled. Please  click on the "Forgot User ID and/or Password?" link to reset your password and login.`,
       $localize`Sorry, your account is deleted. Please click on the "Register as a new user" button to create a new account.`,
       $localize`Invalid account status.`,
       $localize`Invalid password. You have {1} more attempts before your account is locked.`,
       $localize`Sorry, your password is expired. Please reset your password.`,
       $localize`Please provide User ID and password.`,
       $localize`Invalid new password. Passwords used within the last 12 months cannot be reused.`,
       $localize`Incorrect password`
  ],
  EPA:[$localize`Are you an existing Vehicle Inspection Centre?`,
    $localize`You must sign your Performance Contracts before purchasing any equipment.`,
  ]
};

const dictionary = {
  "Y":$localize`Yes`,
  "N":$localize`No`,
  "P":$localize`PASS`, 
  "F":$localize`FAIL`, 
}

export function translateMessage(serviceName:string, msgId:integer, msgValues:string[]) {
  let msg = serviceName != null && serviceName.length > 1?msgMap[serviceName][msgId-1]:msgMap['*'][msgId-1];
  if(msgValues != null && msgValues.length > 0) {
    for(let i = 1; i <= msgValues.length; i++) {
      msg = msg.replace('{' + i + '}', msgValues[i-1]);
    }
  }
  return msg;
}

export function translateText(text:string){
  if(text){
    const translate = dictionary[text];
    return translate?translate:text;
  }else{
    return '';
  }
}


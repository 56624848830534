import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-phone-field-mask',
  templateUrl: './phone-field-mask.component.html',
  styleUrls: ['./phone-field-mask.component.css']
})
export class PhoneFieldMaskComponent implements OnInit {
  @ViewChild('phoneField') phoneField:any;
  @Input() placeholder: string;
  @Input() ext = false;
  @Input() value = '';
  @Input() extValue: any = '';
  @Output() valueChange = new EventEmitter();
  @Output() extValueChange = new EventEmitter();
  @Input() valueRequired = false;
  @Input() cmpntId = null;
  @Input() extId = null;
  @Input() inputdisabled: boolean;
  @Input() appwidth: string;
  @Input() pattern = null;
  @Input() labelColorRequired:boolean = false;

  public isReady = false;

  phonePatternMsg = "Phone # must be 10 digits.";
  extPatternMsg = "1-5 digits.";
  requiredMsg = '';
  invalidMsg = '';
  
  private setIsReady() {
    if (this.value !== undefined && this.value !== null) {
      this.isReady = this.value.toString().trim().length > 0;
    } else {
      this.isReady = false;
    }
  }

  constructor(public commonService: CommonService) {}

  ngOnInit() {
    this.requiredMsg = this.placeholder +  " is required";
    this.invalidMsg =  "Invalid " + this.placeholder;
    this.change();
  };
   
  change() {
    this.valueChange.emit(this.value);
    this.extValueChange.emit(this.extValue);
    this.setIsReady();
  }

}


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.css']
})
export class PhoneFieldComponent implements OnInit {
  @Input() placeholder: string;
  @Input() ext = false;
  @Input() value = '';
  @Input() extValue: any = '';
  @Output() valueChange = new EventEmitter();
  @Output() extValueChange = new EventEmitter();
  @Input() valueRequired = false;
  @Input() cmpntId = null;
  @Input() extId = null;
  @Input() inputdisabled: boolean;
  @Input() appwidth = null ;
  @Input() labelColorRequired:boolean = false;

  ngStyle = {};

  public message = null ;
  public isReady = false;

  phonePatternMsg = $localize`Phone # must be 10 digits.`;
  extPatternMsg = $localize`1-5 digits.`;

  requiredMsg = '';
  invalidMsg = '';

  public customPhoneErrorMatcher: ErrorStateMatcher;

  public customExtErrorMatcher: ErrorStateMatcher;

  constructor(public commonService: CommonService) {
  }

  ngOnInit() {
    this.requiredMsg = this.placeholder +  $localize` is required`;
    this.invalidMsg =  $localize`Invalid ` + this.placeholder;

    if (this.appwidth != null && this.appwidth.length > 0) {
      this.ngStyle = {'width':this.appwidth, 'padding': '2px'};
    }else {
      this.ngStyle = {'padding': '2px'};
    }

    this.phoneNumChange();
    this.extChange();
  }

  phoneNumChange() {
    this.valueChange.emit(this.value);
  }

  extChange() {
    this.extValueChange.emit(this.extValue);
  }

}

<div *ngIf="commonService.isLoggedIn()">
  <main-nav [version] = "version"></main-nav>
</div>
<div *ngIf="!commonService.isLoggedIn()">
  <div *ngIf="!this.isBrowserSupported">
    <login-unsupported-browser></login-unsupported-browser>
  </div>
  <div *ngIf="this.isBrowserSupported">
    <login-header></login-header>
    <mat-sidenav-container class="all-wrap">
      <div id="outer">
        <div id="inner">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-container>
    <app-footer></app-footer>
    <hr style="visibility: hidden;" />
  </div>
</div>


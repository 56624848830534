import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { CommonService } from 'apps/common/services/common.service';
import { getLocale } from 'apps/common/helper/util-function-class';
import createReportCardPdf from '../../../template/pdfReportCardService';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'login-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent implements OnInit {
  currentLocale: string;
  stationId:string;
  docDefinition:any;
  runReport = 0;

  constructor(public service: LoginService, public commonService: CommonService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.currentLocale = getLocale();
    this.route.params.subscribe(params => {
      this.stationId = params['stationId'];   
    });     

    if(this.stationId != undefined)
    {
      this.service.getReportCardByCategory("EMS", this.stationId, this.getReportCardByCategoryCallback, this.commonService.showErrorMsg);
      this.service.getReportCardByCategory("SAF", this.stationId, this.getReportCardByCategoryCallback, this.commonService.showErrorMsg);
    }else{
      window.self.close();
    }
  }

  getReportCardByCategoryCallback= (result: any) => {
//    this.runReport++;
    if(result != null && result.stationList != null && result.stationList.length > 0) {
      for(const aStationDto of result.stationList) {
        aStationDto.aLocale = this.currentLocale;
        aStationDto.inspectionCategoryCd = result.inspectionCategoryCd;
        this.docDefinition = createReportCardPdf(aStationDto, result.pdList, result.pgList, result.tvList);
        pdfMake.createPdf(this.docDefinition).open();
        setTimeout(function() {closeWindow()}, 3000); 
        break;
      }  

    } else {
  //    setTimeout(function() {closeWindow(this.runReport)}, 3000);      
    }    
  }
}

function closeWindow() : void {
    window.self.close();     
}

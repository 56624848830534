import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BotDetectCaptchaModule } from 'angular-captcha';

import { NgxSpinnerModule } from "ngx-spinner";
import { CookieService} from 'ngx-cookie-service';

import { CommonService} from 'apps/common/services/common.service';
import { AuthGuardService} from 'apps/common/services/auth-guard.service';

import { MaterialModule} from 'apps/common/modules/material.module';
import { ComponentModule } from 'apps/common/modules/component.module';
import { KeycloakModule } from 'apps/common/modules/kaycloak.module';

import { CustomDialogComponent } from 'apps/common/components/custom-dialog/custom-dialog.component';
import { SimpleDialogComponent } from 'apps/common/components/simple-dialog/simple-dialog.component';
import { MainNavComponent } from 'apps/common/main-nav/main-nav.component';

import { RouterWrapperService} from './services/router-wrapper.service';
import { LoginService} from './services/login.service';
import { UserProfileGuardService} from './services/user-profile-guard.service';

import { AppComponent } from './app.component';

import { HeaderComponent} from './component/screens/header/header.component';

import { AppRoutingModule } from './component/app-routing/app-routing.module';
import { MainScreenComponent } from './component/screens/main-screen/main-screen.component';
import { LoginScreenComponent } from './component/screens/login-screen/login-screen.component';

import { UserRegistrationStep1Component } from './component/screens/user-registration/step1/step1.component';
import { UserRegistrationStep2Component } from './component/screens/user-registration/step2/step2.component';
import { UserRegistrationStep3Component } from './component/screens/user-registration/step3/step3.component';
import { UserRegistrationStep4Component } from './component/screens/user-registration/step4/step4.component';
import { OnboardingStepsComponent } from "../../../icaa/src/app/component/screens/onboarding-steps/onboarding-steps.component";
import { StationUserRegistrationStep1Component } from './component/screens/station-user-registration/step1/step1.component';
import { StationUserRegistrationStep2Component } from './component/screens/station-user-registration/step2/step2.component';
import { StationUserRegistrationStep3Component } from './component/screens/station-user-registration/step3/step3.component';
import { StationUserRegistrationStep4Component } from './component/screens/station-user-registration/step4/step4.component';
import { ReportsScreenComponent } from './component/screens/reports/reports-screen.component';
import { DialogBodyComponent } from './component/dialog-body/dialog-body.component';
import { ComplianceDialogComponent } from './component/screens/compliance-dialog/compliance-dialog.component';
import { QuickSightComponent } from './component/screens/quick-sight/quick-sight.component';
import { DashboardComponent } from './component/screens/dashboard/dashboard.component';
import { DashboardDialogComponent } from './component/screens/dashboard-dialog/dashboard-dialog.component';
import { SheetComponent } from './component/screens/sheet/sheet.component';
import { AmtService } from 'apps/amt/src/app/service/amt.service';
import { TrainingVideosComponent } from './component/screens/training-videos/training-videos.component';
import { SopAndDirectvieComponent } from './component/screens/sop-and-directvie/sop-and-directvie.component';
import { EnrolmentComponent } from './component/screens/enrolment/enrolment.component';
import { ReportCardComponent } from './component/screens/report-card/report-card.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    MainScreenComponent,
    LoginScreenComponent,
    OnboardingStepsComponent,
    UserRegistrationStep1Component,
    UserRegistrationStep2Component,
    UserRegistrationStep3Component,
    UserRegistrationStep4Component,
    StationUserRegistrationStep1Component,
    StationUserRegistrationStep2Component,
    StationUserRegistrationStep3Component,
    StationUserRegistrationStep4Component,
    HeaderComponent,
    ReportsScreenComponent,
    DialogBodyComponent,
    ComplianceDialogComponent,
    QuickSightComponent,
    DashboardComponent,
    DashboardDialogComponent,
    SheetComponent,
    TrainingVideosComponent,    
    SopAndDirectvieComponent, 
    EnrolmentComponent, 
    ReportCardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    ComponentModule,
    KeycloakModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    AppRoutingModule,
    FlexLayoutModule,
    BotDetectCaptchaModule.forRoot({captchaEndpoint: 'captcha-endpoint/botdetectcaptcha'}),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [SimpleDialogComponent, CustomDialogComponent, DialogBodyComponent],
  exports: [SimpleDialogComponent],
  providers: [CookieService, RouterWrapperService, LoginService, UserProfileGuardService, CommonService, AuthGuardService,AmtService],
  bootstrap: [AppComponent]
})
export class AppModule { }

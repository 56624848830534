<mat-form-field [ngStyle]="{'width':appwidth, 'padding':'2px'}"
  [floatLabel]="inputdisabled?'always':'auto'"
  [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'">
  <mat-label [ngClass]="{'labelColor': labelColorRequired}">{{placeholder}} <span *ngIf="labelColorRequired">(required)</span></mat-label>
  <input #formControl="ngModel" [id]="cmpntId" [required]="valueRequired" maxlength="13" (change)="change()"
    pattern="^[0-9]{10}$"
    [disabled]="inputdisabled || commonService.isCmpntDisabled(cmpntId)" matInput [(ngModel)]="value"
    placeholder="{{placeholder}}" mask="(000)000-0000">
  <!-- <mat-hint align="start"><strong>(###)###-####</strong> </mat-hint> -->
  <mat-error *ngIf="formControl.hasError('required')">{{requiredMsg}}</mat-error>
  <mat-error *ngIf="formControl.hasError('pattern')">{{phonePatternMsg}}</mat-error>
</mat-form-field>

<span *ngIf="ext">
  <mat-form-field class="small" [floatLabel]="inputdisabled?'always':'auto'"
    [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'">
    <input #extFormControl="ngModel" [id]="extId" (change)="change()" maxlength="5"
      [disabled]="inputdisabled || commonService.isCmpntDisabled(cmpntId)" matInput [(ngModel)]="extValue"
      placeholder="Ext" pattern="^[0-9]+$">
      <mat-error *ngIf="extFormControl.hasError('required')">{{requiredMsg}}</mat-error>
      <mat-error *ngIf="extFormControl.hasError('pattern')">{{extPatternMsg}}</mat-error>
  </mat-form-field>
</span>
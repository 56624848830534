<app-form-panel i18n-applabel applabel="User Registration"> 
  <app-card i18n-apptitle apptitle="Step 1 of 4 - Select User Type">
    <p i18n style="font-weight: bold; font-size: 16px">Please select a user type from the list and click the 'Sign Up' button.</p>
    <ng-container i18n>User Type:</ng-container> &nbsp;&nbsp;
    <app-select-field i18n-placeholder placeholder="Select Option"
      cmpntId="LOGIN.RegistrationPage1.Organization" #fieldOne [required]="true"
      errorMessage="Please select a valid option" areaLabel="Organization Dropdown"
      [(appvalue)]="userProfile.organizationId" [appoptions]="this.org">
    </app-select-field>
    <p>
      <button i18n id="LOGIN.RegistrationPage1.Next" class="hereBtn" mat-raised-button [disabled]="!fieldOne.isReady" (click)="next()">
        Sign Up
      </button>&nbsp;&nbsp;
      <button i18n id="LOGIN.RegistrationPage1.Cancel" class="hereBtn" (click)="cancel()" mat-raised-button>
        Cancel
      </button>
    </p>
  </app-card>
</app-form-panel>

import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewChild,Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './app-date-time-picker.component.html',
  styleUrls: ['./app-date-time-picker.component.css']
})

export class AppDateTimePickerComponent implements OnInit {
  @ViewChild('picker') picker: any;  
  @ViewChild('formControl') formControl:any;
  @Output() appdateChange = new EventEmitter();
  @Output() hasError:boolean;

  @Input() inputdisabled = false ;
  @Input() cmpntId = null ;
  @Input() minDate:Date = null;
  @Input() maxDate:Date = null;
  @Input() required = false;
  @Input() appdate = null;
  @Input() appwidth = null;

  public date: Date;
  @Input() disabled = false;
  @Input() showSpinners = true;
  @Input() showSeconds = false;
  @Input() touchUi = false;
  @Input() enableMeridian = false;  
  @Input() stepHour = 1;
  @Input() stepMinute = 15;
  @Input() stepSecond = 1;

  public color: ThemePalette = 'primary';
  @Input() placeholder = 'Choose a Date' ;
  
  public defaultTime = [new Date().getHours(), new Date().getMinutes() , 0]
  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  })
  public dateControl = new FormControl(new Date(2021,9,4,5,6,7));
  public dateControlMinMax = new FormControl(new Date());

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  public listColors = ['primary', 'accent', 'warn'];

  /*public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];*/

  
  constructor(private http: HttpClient, private zone: NgZone) {
  }

  ngOnInit() {
    this.date = new Date(2021,9,4,5,6,7);
  }

  toggleMinDate(evt: any) {
    if (evt.checked) {
      this._setMinDate();
    } else {
      this.minDate = null;
    }
  }

  toggleMaxDate(evt: any) {
    if (evt.checked) {
      this._setMaxDate();
    } else {
      this.maxDate = null;
    }
  }

  closePicker() {
    this.picker.cancel();
  }

  private _setMinDate() {
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate() - 1);
  }


  private _setMaxDate() {
    const now = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(now.getDate() + 1);
  }

  inputChange(date) {
    this.hasError = this.formControl?!this.formControl.valid:false;
    this.appdateChange.emit(this.appdate);
  }

  handleKeyEnter(event) {
    event.preventDefault();   
  }

}


<span>

<mat-card>
  <mat-card-header>
    <mat-card-title>{{apptitle}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
</span>
<br/>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<mat-form-field [ngStyle]="ngStyle"
  [floatLabel]="inputdisabled?'always':'auto'"
  [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'">
  <mat-label [ngClass]="{'labelColor': labelColorRequired}">{{placeholder}} <span *ngIf="labelColorRequired">(required)</span></mat-label>
  <input matInput #formControl="ngModel" name="formControl"
    autocomplete="off"
    [id]="cmpntId" [mask]="mask"
    [maxlength]="maxlength"
    [minlength]="minlength"
    [pattern]="pattern"
    [required]="required"
    (paste)="onPaste($event)" [showMaskTyped]="showMaskTyped"
    (keypress)="onKeyPress($event)"
    [suffix]="suffix"
    [type]="type" (keydown.enter)="handleKeyEnter($event)"
    [(ngModel)]="inputValue" [style]="style"
    (ngModelChange)="onInputChange()" [disabled]="inputdisabled || commonService.isCmpntDisabled(cmpntId)" >
    <span matSuffix *ngIf = "helpIconName != null && helpIconName.length > 0" >
      <mat-icon *ngIf = "helpIconName.includes('info')" color="primary" [matTooltip]="toolTipText">info</mat-icon>
      <mat-icon *ngIf = "!helpIconName.includes('info')" color="primary" [matTooltip]="toolTipText"><img  style="height:25px;width:25px; vertical-align: top;" 
        src="assets/img/{{helpIconName}}"></mat-icon>
    </span>
    <i *ngIf="type=='password' && inputValue && !isEdge" class="fa fa-eye" id="showNewPasswordIcon" style="font-size: 18px;" (click)="type = 'text'" tabindex="-1"></i>
    <i *ngIf="isPasswordField && type=='text' && inputValue && !isEdge" class="fa fa-eye-slash" id="showNewPasswordIcon" style="font-size: 18px;" (click)="type = 'password'" tabindex="-1"></i>

  <!--div aria-live="assertive"-->
  <mat-error *ngIf="formControl.hasError('required')">{{requiredMsg}}</mat-error>
  <mat-error *ngIf="!formControl.valid && !formControl.hasError('required') && !formControl.hasError('pattern')">{{invalidMsg}}</mat-error>
  <mat-error *ngIf="formControl.hasError('pattern')">{{errorMessage}}</mat-error>
  <mat-error *ngIf="length != null && formControl.hasError('maxlength')">{{maxlengthMsg}}</mat-error>
  <mat-error *ngIf="length != null && formControl.hasError('minlength')">{{minlengthMsg}}</mat-error>
  <mat-error *ngIf="formControl.hasError('min')">{{minValueMsg}}</mat-error>
  <mat-error *ngIf="formControl.hasError('max')">{{maxValueMsg}}</mat-error>
  <!--/div-->
</mat-form-field>
<mat-error *ngIf="(message != null && message.trim() !='') || extra_message !=''">{{message? message + ' ' + extra_message:extra_message}}</mat-error>

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CustomDialogComponent } from 'apps/common/components/custom-dialog/custom-dialog.component'
import { CommonService } from 'apps/common/services/common.service';
import {isBlank, getLocale} from 'apps/common/helper/util-function-class';

@Component({
  selector: 'pws-app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class PwsFooterComponent implements OnInit {

  @ViewChild("contactUsDialog") contactUsDialog:any;
  
  currentLocale: string = null;
  showContactUs:boolean = false;
  contactUs:any = {};
  disableAddButton = false;
  today: number;
  dialogConfig:MatDialogConfig = null;
  dialogData: any = {};

  constructor(public commonService: CommonService, private dialog: MatDialog) { }

  ngOnInit() {
    this.currentLocale = getLocale();
    this.today = new Date().getFullYear();
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.ariaLabel=" ";
    this.dialogConfig.autoFocus= false;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '540px';
  }

  openAccessibility() {
    if(this.currentLocale == 'en')
      window.open("https://www.ontario.ca/page/accessibility");
    else
      window.open("https://www.ontario.ca/fr/page/accessibilite");
  }

  openPrivacy() {
    if(this.currentLocale == 'en')
      window.open("https://www.ontario.ca/page/privacy-statement");
    else
      window.open("https://www.ontario.ca/fr/page/declaration-concernant-la-protection-de-la-vie-privee");
  }

  openCopyright() {
    if(this.currentLocale == 'en')
      window.open("https://www.ontario.ca/page/copyright-information-c-queens-printer-ontario");
    else
      window.open("https://www.ontario.ca/fr/page/droits-dauteur-imprimeur-de-la-reine-pour-lontarioc");  
  }

  invalidContactUsInputs = () => {
    return isBlank(this.contactUs.userName) || isBlank(this.contactUs.userEmailAddress) || isBlank(this.contactUs.messageBody);
  }

  displayContactUs() {
    this.showContactUs = true;
  }

  addContactUs() {
    this.disableAddButton = true;
    this.commonService.addContactUs(this.contactUs, this.addContactUsCallback, this.showErrorMsg);
  }

  addContactUsCallback = (result:any) => {
    this.disableAddButton = false;
    if (result && result > 0) {
      this.dialogData = { type:'warning-dialog', message:$localize`<b>Thank you for contacting us. </b><br/><br/>You will receive a response within one business day. <br/><br/>If you need immediate assistance please call
      Inspection Centre Technical Support at 1-833-420-2110`, ok:$localize`Close`};
    }else {
      this.dialogData = { type:'warning-dialog', message:$localize`Failed to submit your message.`, ok:$localize`Close`};
    }

    this.dialogConfig.data = this.dialogData;
    const dialogRef = this.dialog.open(CustomDialogComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe(dialogData => {
      this.showContactUs = false;
    });
  }

  change() {
    if (this.contactUs.userPhoneNum != null) {
      const x = this.contactUs.userPhoneNum.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.contactUs.userPhoneNum = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }   
  }
  showErrorMsg = (error:any) => {
    this.disableAddButton = false;
    if (error.status === 400) {
      alert($localize`Invalid Request Parameters!`);
    }else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    }else if( error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0 ) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }

}
import { Component, ViewChild, Input} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'apps/common/environments/environment';
import { CommonService } from 'apps/common/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'login-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../../../../../common/main-nav/main-nav.component.css'],
})

export class HeaderComponent {
  @Input() appName:any;
  version: string = environment.version ;
  env: string = environment.env;
  userName: string = '';
  appUrl:string = '';
  sliceCount = 100;
  txtVic:string = '';
  txtPortal:string = '';
  isShowScrollMenu = true;
  isRegistration = false;
  subscription: Subscription;

  switchLanguage: string;
  switchLocale: string;
  contextRoot: string;

  @ViewChild('drawer', {static: true})  drawer ;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 768px)'])
    .pipe(
      map(result => result.matches)
    );

  public static generateLink (name: string, link: string, external = false, roles = [] ): any {
    return { 'name': name, 'link': link, 'roles': roles, 'isExternal': external, 'display': true} ;
  }

  constructor(private breakpointObserver: BreakpointObserver, private router: Router,
    public commonService: CommonService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const uri = window.location.pathname.split('/');
    this.contextRoot = uri[1];
    const currentLocale = uri[2];
    if (currentLocale == 'en' ) {
      this.switchLocale = 'fr';
      this.switchLanguage = 'Français';
    }else {
      this.switchLocale = 'en'
      this.switchLanguage = 'English';
    }

    this.appUrl = window.location.href;
    this.userName = this.commonService.getUserName();

    if(this.appUrl.includes('on-login-web')) {
      this.appName = $localize`Integrated Vehicle Safety and Emissions Inspection Program`;
      this.txtVic = '';
      this.txtPortal = '';
      this.isShowScrollMenu = false;
    } else if(this.appUrl.includes('on-uma-web')) {
      this.appName = 'User Management';
    } else if(this.appUrl.includes('on-stationadmin-web')) {
      this.appName = 'Station Admin';
    } else if(this.appUrl.includes('on-tmq-web')) {
      this.appName = 'Training';
    } else if(this.appUrl.includes('on-amt-web')) {
      this.appName = 'Audit Management and Trigger';
    } else if(this.appUrl.includes('on-icpaa-web')) {
      this.appName = 'Inspection Centre Personnel Administration';
    } else if(this.appUrl.includes('on-vca-web')) {
      this.appName = 'Vehicle Control Application';
    }

    console.log('####### value: ' + this.isRegistration);
    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      console.log('####### id: ' + id);
    });
  }

  switch(event) {
    event.preventDefault();
    const to = "/"+this.switchLocale;
    var from = "/en";
    if (this.switchLocale === 'en')
      from = '/fr';
    const href = window.location.href.replace(from, to);
    window.location.href = href;
  }

  showScrollMenu() {
    setTimeout(() => this.showMenu(), 100);
  }

  showMenu() {
    this.isShowScrollMenu = true;
  }

  goHome() {
    if (this.commonService.pwdResetRequired) {
      this.commonService.logout();
    } 
    else if(this.commonService.isLoggedIn()) {
      this.commonService.goToHomePage();
    }
    else {
      this.router.navigate(['/login/sso']);
    }
  }

  navigateToApp(item, sub){
    this.drawer.close();
    if(item && sub) {
      const link = item.link + sub.link;
      window.location.href = link;
      this.showScrollMenu();
    }

  }

  gotoUrl(url: string, isExternal: boolean) {
    this.drawer.close();
    if( isExternal) {
      window.location.href = url;
    }else {
      this.router.navigateByUrl(url);
    }
  }

}

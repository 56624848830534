<mat-form-field [ngClass]="selectFieldClass" [ngStyle]="{'width':appwidth, 'padding':'2px'}"
  [floatLabel]="disabled?'always':'auto'"
  [style.display]="commonService.isCmpntHidden(cmpntId)?'none':'inline-block'">
  <mat-label i18n>{{placeholder}}</mat-label>
  <mat-select  #mySelCategory matNativeControl disableOptionCentering #formControl="ngModel" [id]="cmpntId" name="formControl" multiple
    [compareWith]="compareObjects" [disabled]="disabled || commonService.isCmpntDisabled(cmpntId)"
    (change)="update()" placeholder="{{placeholder}}" [(ngModel)]="appvalue" [required]="required"
    [attr.aria-label]="areaLabel !== null ? areaLabel : 'Dropdown'">
    <mat-option [value]="0" (click)="toggleAllSelection(mySelCategory)">Select all</mat-option>
    <mat-option style="height: 1.7em;" *ngFor="let o of appoptions; index as i" [selected]="o.code == appvalue"
      [value]="o.code !== undefined ? o.code : (isArray(o)?o[0]:o)"
      [id]="cmpntId!==null ? cmpntId + '-option-' + i : null">{{o.value !== undefined? o.value : (
      isArray(o)?o[1]:(o.value !== undefined?o.value[0]:o)) }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="formControl.hasError('required')">
    <span style="color: red;" *ngIf="!isBlank(errorMessage)">
    {{errorMessage}}
    </span>
    <span style="color: red;" *ngIf="isBlank(errorMessage)">
      Please select {{placeholder}}
    </span>
  </mat-error>
</mat-form-field>

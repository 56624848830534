import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterWrapperService {

  constructor(private router: Router) { }

  gotoUrl(url: string, isExternal = false) {
    if ( isExternal) {
      window.location.href = url;
    } else {
      this.router.navigateByUrl(url);
    }
  }
}

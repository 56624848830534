<div class="footer" fxLayout="row" fxLayoutGap="50px"
  fxLayout.lt-md="column" fxLayoutGap.lt-md="2px">
  <div id="termOfUse">
    <a class="hereBtn" i18n (click)="displayTermConditions()"
    (keyup.enter)="displayTermConditions()" tabindex="0">
      <u>Terms of Use</u>
    </a>
  </div>
  <div id="contactUs">
    <a class="hereBtn" i18n (click)="displayContactUs()"
      (keyup.enter)="displayContactUs()" tabindex="0">
      <u>Contact Us</u>
    </a>
  </div>
  <div id="browsers">
    <a class="hereBtn" i18n (click)="displayBrowserCompatibility()"
      (keyup.enter)="displayBrowserCompatibility()" tabindex="0">
      <u>Browser Compatibility</u>
    </a>
  </div>
  <div i18n id="copyright">
    Copyright {{today}} Parsons. All rights reserved.
  </div>
    <img id="parsonsLogo" alt="Parsons" src="assets/img/parsons_logo.png" alt="Parsons Logo">
  <aws-chat-field appwidth="20%"></aws-chat-field>
</div>
<app-dialog #browserCompatibilityDialog [(visible)]="showSupportedBrowsers">
  <br/><br/>
  <div style="margin-left: 15%; color: #3700B3;">
    <div i18n style="font-size:20px; font-weight:bold">Supported Browsers:</div>
    <br/><br/>
    <div i18n><img src="assets/img/chrome.png" alt="Chrome" style="width:50px;height:50px;vertical-align: middle;">&nbsp;&nbsp;&nbsp;<strong>Chrome</strong> version 117 and later</div>
    <br/>
    <div i18n><img src="assets/img/firefox.png" alt="Firefox" style="width:50px;height:50px;vertical-align: middle;">&nbsp;&nbsp;&nbsp;<strong>Mozilla Firefox</strong> version 117 and later</div>
    <br/>
    <div i18n><img src="assets/img/safari.png" alt="Safari" style="width:50px;height:50px;vertical-align: middle;">&nbsp;&nbsp;&nbsp;<strong>Safari</strong> version 16 and later</div>
    <br/>
    <div i18n><img src="assets/img/edge.png" alt="IE Edge" style="width:50px;height:50px;vertical-align: middle;">&nbsp;&nbsp;&nbsp;<strong>Microsoft Edge</strong> version 116 and later</div>
    <br/><br/><br/>
    <button i18n id="Footer.Close" mat-raised-button
        (click)="this.showSupportedBrowsers = false;">Close</button>
  </div>
</app-dialog>

<app-dialog #termConditionsDialog [(visible)]="showTermConditions">
  <div>
    <app-static-html source="{{termConditions}}"></app-static-html>
  </div>
  <button i18n id="Footer.Close" mat-raised-button
      (click)="this.showTermConditions = false">Close
  </button><br/><br/>
</app-dialog>
<app-dialog #contactUsDialog [(visible)]="showContactUs">
  <app-card i18n-apptitle apptitle="Contact Us">
    <br/>
    <div i18n style="font-size: 16px;font-style: italic;">
      Thank you for visiting the DriveON Portal. We look forward to your suggestions and inquiries.
      Please enter the information below and press the "submit" button.
    </div><br/>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
      <app-input-field i18n-placeholder placeholder="Name"
        type="email"  [(inputValue)]="contactUs.userName" [required]="true"
        fxFlex="23%" appwidth="100%">
      </app-input-field>
      <app-input-field i18n-placeholder placeholder="Email"
        i18n-errorMessage errorMessage="Please enter a valid email address"
        pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" 
        [(inputValue)]="contactUs.userEmailAddress" [required]="true"
        fxFlex="35%" appwidth="100%">
      </app-input-field>
      <app-phone-field i18n-placeholder placeholder="Telephone" #phoneNum
        [(value)]="contactUs.userPhoneNum" [ext]="false" [valueRequired]="false"
        fxFlex="35%" appwidth="100%">
      </app-phone-field>
    </div>
    <div fxLayout="row wrap" fxLayout.lt-md="column">
      <app-input-field i18n-placeholder placeholder="Subject"
        fxFlex="60%" appwidth="100%"
        [(inputValue)]="contactUs.messageTitle">
      </app-input-field>
    </div>
    <br>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
      <app-textarea i18n-placeholder placeholder="Message"
        [(inputValue)]="contactUs.messageBody" [required]="true" [minlength]="2" [maxlength]="500"
        [rows]="4" [required]="true" fxFlex="60%" appwidth="100%">
      </app-textarea>
    </div>
  </app-card>
    <br><br>
    <button i18n id="Footer.Submit" (click) = "addContactUs()" mat-raised-button [disabled]="disableAddButton || invalidContactUsInputs()">
      Submit
    </button>&nbsp;&nbsp;

    <button i18n id="Footer.Close" mat-raised-button (click)="this.showContactUs = false">Close</button>

    <br><br><br><br><br>
    <div style="font-size:16px;font-weight:bold">
      <p i18n>
        Vehicle Inspection Centre Assistance Line at 1-833-420-2110
      </p>
      <p i18n>
        8:00 a.m. to 6:00 p.m. (ET) Monday to Friday (excluding Holidays)
      </p>
      <p i18n>
        9:00 a.m. to 3:00 p.m. (ET) Saturday (excluding Holidays)
      </p>
    </div>
</app-dialog>

<ngx-spinner bdColor="rgba(170,170,170,0.4)" size="medium" color="black" type="ball-spin-fade-rotating"
    [fullScreen]="false">
    <p i18n style="color: white">Loading...</p>
</ngx-spinner>

<div style="width: 90%; margin-left: 38px;">
    <span i18n style="font-size: 18px; font-family: Arial, Helvetica, sans-serif; font-weight: 700;">
      Training Videos 
    </span><br/><br/>
    <span i18n style="font-size: small; font-family: Arial, Helvetica, sans-serif;">
      Click on one of the videos below to get help with performing inspections, equipment setup, maintaining equipment, and navigating the DriveON Portal.
    </span><br/><br/>
    <span i18n style="font-size: small; font-family: Arial, Helvetica, sans-serif;">
      All videos will open a new window to play. Ensure your computer settings allow browser pop-ups. 
    </span><br/><br/>
    <div style="font-size: 14px; color: #9E0000;" id="no-videos-list"></div>
    <div id="Login.TraingVideoList.Grid" style="overflow: hidden; flex-grow: 1">      
        <span *ngFor="let step of trainingVideoList">
            <span i18n style="font-size: 14px; font-family: Arial, Helvetica, sans-serif; font-weight: 700;">
                {{step.videoFileCategoryDto.videoFileCategoryDesc}}
            </span><br/>
            <span *ngFor="let step1 of step.videoFileDtoList">
                <a i18n style="font-size: small; font-family: Arial, Helvetica, sans-serif; vertical-align:middle" href="#" (click)="playVideo(step1.fileName)" tabindex="0">
                    <img style="height:25px;width:25px;vertical-align:middle" src="assets/img/play.png" alt="step.displayName">
                    {{step1.displayName}}
                </a><br>
            </span><br>
        </span>
    </div>
</div>

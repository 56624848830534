import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat'
})

export class PhoneFormatPipe implements PipeTransform {
  transform(number:string) {
    if (number != null) {
      const x = number.toString().replace(/\D/g, '').match(/(1?)(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[3] ? x[1] + x[2] :x[1] + '(' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '');
    }
    return '';

  }

}

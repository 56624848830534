import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoginService } from '../../../services/login.service';
import { translateMessage } from 'apps/common/helper/service-messages';
import { CommonService } from 'apps/common/services/common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'login-quick-sight',
  templateUrl: './quick-sight.component.html',
  styleUrls: ['./quick-sight.component.css']
})
export class QuickSightComponent implements OnInit {

  @ViewChild('quickSight', { static: false }) quickSightRef: ElementRef;
  msgServiceError = $localize`Oops, something went wrong! Please try again later.`;
  message = '';
  urlSafe: SafeResourceUrl;

  constructor(public commonService: CommonService, public service: LoginService, 
    private route: ActivatedRoute, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['id'];
      if(id === 'console')
        this.service.getConsoleUrl(id, this.getUrlCallback, this.getUrlError);
      else  
        this.service.getDashboardUrl(id, this.getUrlCallback, this.getUrlError);
    });
  }

  getUrlCallback = (result:any) => {
    if( result != null )
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(result.value);
  }

  getUrlError = (error:any) =>  {
    if( error !== null && 
      error.error !== null && 
      error.error.serviceMessage !== undefined && 
      error.error.serviceMessage != null ) {
      const serviceMessage = error.error.serviceMessage;
      this.message = translateMessage(serviceMessage.serviceName, serviceMessage.msgId, serviceMessage.msgValues);
    } 
    else {
      this.message = this.msgServiceError;
      console.log(error.message + '(response code: ' + error.status + ')');
    }
  }

  resize() {
    this.quickSightRef.nativeElement.style.height = this.quickSightRef.nativeElement.contentWindow.parent.innerHeight-250+'px';
  }
}

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'dashboard-dialog',
  templateUrl: './dashboard-dialog.component.html',
  styleUrls: ['./dashboard-dialog.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'translateX(150%)' }),
        animate(300)
      ]),
      transition('* => void', [
        animate(300, style({ transform: 'translateX(150%)' }))
      ])
    ])
  ]
})
export class DashboardDialogComponent implements OnInit {
  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("innerInput") innerInput: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  focus() {
    this.innerInput.nativeElement.focus();
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

}

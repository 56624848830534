import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomDialogComponent } from 'apps/common/components/custom-dialog/custom-dialog.component'
import { isBlank } from 'apps/common/helper/util-function-class';

@Component({
  selector: 'login-sop-and-directvie',
  templateUrl: './sop-and-directvie.component.html',
  styleUrls: ['./sop-and-directvie.component.css']
})
export class SopAndDirectvieComponent implements OnInit {

  soFileList: any = [];
  directiveFileList: any = [];
  hostname: string;
  fileCatgoryCount: number;
  constructor(private service: LoginService,public spinnerService: NgxSpinnerService, private dialog: MatDialog,) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.service.getPortalSopAndDirectives(this.setPortalSopAndDirectivesCallback, this.showErrorMsg);
  }

  setPortalSopAndDirectivesCallback = (result: any) => {
    this.spinnerService.hide();
    if (result && result != null) {
      this.soFileList = result.sopFileList;
      this.directiveFileList = result.directiveFileList;
    } else {
      var infoArea = document.getElementById('no-videos-list');
      infoArea.textContent = 'No Records found.';
    }
  }

  dialogConfig: MatDialogConfig = null;
  dialogData: any = {};
  resetPasswordRequest:any = {};
  openFile(row) {
    //https://dev.on.parsonsvic.com/ONPublicWeb/Directive_Schedule_2HDV_Safety_12012022_Final.pdf
    if(!isBlank(row.fileName)) {
      window.open(window.location.protocol+"//"+window.location.host+"/"+row.s3BucketPath+"/"+row.fileName, '_blank');
    } else {
      let yesNoDialogConfig: MatDialogConfig = new MatDialogConfig();
      yesNoDialogConfig.ariaLabel = " ";
      yesNoDialogConfig.autoFocus = false;
      yesNoDialogConfig.data = { type: 'confirm-dialog', title: $localize`Document is currently unavailable. Please check again later.`, ok: $localize`Ok`};
      const aDialogReference = this.dialog.open(CustomDialogComponent, yesNoDialogConfig);
      aDialogReference.afterClosed().subscribe(dialogData => {
        if (dialogData != null) {
          return;
        }
        return;
      });
    }
    return false;
  }

  showErrorMsg = (error: any) => {
    this.spinnerService.hide();
    if (error.status === 400) {
      alert($localize`Invalid request parameters!`);
    } else if (error.status === 503) {
      alert($localize`Service is not available. Please try again later!`);
    } else if (error.error != null && error.error.msg !== undefined && error.error.msg != null && error.error.msg.length > 0) {
      console.error(error.error.msg);
      alert($localize`Oops, something went wrong! Please try again later`);
    } else {
      console.error(error.message + '(response code: ' + error.status + ')');
      alert($localize`Oops, something went wrong! Please try again later`);
    }
  }
}

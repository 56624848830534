import { Injectable } from '@angular/core';
import '@angular/localize/init';
import { environment } from '../../../../common/environments/environment';
import { CommonService } from '../../../../common/services/common.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent } from '@angular/common/http';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CustomDialogComponent } from 'apps/common/components/custom-dialog/custom-dialog.component';
import { AnyFalsy } from 'underscore';

@Injectable()
export class AmtService {


  public static ROLE_ID_AUDITOR=37;
  public static ROLE_ID_SENIOR_AUDITOR=38;
  public static ROLE_ID_ADMIN_USER=39;
  public static ROLE_ID_SERVICE_LEAD=40;
  public static ROLE_ID_MINISTRY_USER=41;
  public static ROLE_ID_DRR=59;
  public static ROLE_ID_REPORT_AUTHOR=78;
  public static ROLE_ID_REPORT_VIEWER=79;

  alertConfig:MatDialogConfig = new MatDialogConfig();

  constructor(public http:HttpClient,
    private commonService: CommonService,
    private alertDialog: MatDialog) {
      this.alertConfig.ariaLabel=" ";
      this.alertConfig.autoFocus= false;
      this.alertConfig.width = "400px";
    }

  private amtApiUrl = environment.amtApiUrl;
  private umaApiUrl = environment.umaApiUrl;
  private icaaApiUrl = environment.icaaApiUrl;
  private emisApiUrl = environment.emisApiUrl;
  private fcraApiUrl = environment.fcraApiUrl;
  private icpaaApiUrl = environment.icpaaApiUrl;
  private vcaApiUrl = environment.vcaApiUrl;
  private lookupApiUrl = environment.lookupApiUrl;

  showAlert(anAlert:string) {
    this.alertConfig.data = { type:'warning-dialog', ok:$localize`OK`,
    message:$localize `${anAlert}` };
    this.alertDialog.open(CustomDialogComponent, this.alertConfig);
  }

  dateFormatter(params) {
    if(params != null)
      return moment(params).format('MM/DD/YYYY');
    else
      return "";
  }

  getAuditTypes(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditTypes', aCallback, anErrorCallback);
  }

  getTriggerTypes(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getTriggerTypes', aCallback, anErrorCallback);
  }

  refreshRandomTechnicianList(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/refresh-random-technician-details', aCallback, anErrorCallback);
  }

  clearAnnualSafetyAuditList(aCallback, anErrorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/clearAnnualSafetyRiskFlag', aCallback, anErrorCallback);
  }

  saveAuditType(anAuditType:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/saveAuditType/' + this.commonService.getCurrentUserid(), anAuditType, aCallback, anErrorCallback);
  }

  saveComplianceAction(complianceAction:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/complianceActions/' + this.commonService.getCurrentUserid(), complianceAction, aCallback, anErrorCallback);
  }

  saveObservation(observations:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/observations/' + this.commonService.getCurrentUserid(), observations, aCallback, anErrorCallback);
  }
  
  deleteAuditType(anAuditTypeId:string, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/deleteAuditType/' + anAuditTypeId, null, aCallback, anErrorCallback);
  }

  getAuditTasks(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditTasks', aCallback, anErrorCallback);
  }

  getAnnualSafetyAuditList(aCallback, anErrorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/annual-safety-high-risk-details', aCallback, anErrorCallback);
  }

  saveAuditTask(anAuditTask:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/saveAuditTask/' + this.commonService.getCurrentUserid(), anAuditTask, aCallback, anErrorCallback);
  }

  saveTriggerStatus(triggerIds:any,status:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/updateTriggerDefinitions/' + this.commonService.getCurrentUserid()+'?status='+status, triggerIds, aCallback, anErrorCallback);
  }
  
  saveAmtParameter(amtParam:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/updateAmtParameters/' + this.commonService.getCurrentUserid(), amtParam, aCallback, anErrorCallback);
  }

  deleteAuditTask(anAuditTaskId:string, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/deleteAuditTask/' + anAuditTaskId, null, aCallback, anErrorCallback);
  }

  public triggerRealtimeAudit(triggeredInspectionIds:any,callback,anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl+'/triggerRealtimeAudit',triggeredInspectionIds,callback, anErrorCallback);
  }

  triggerRealtimeAuditStatus(inspectionId:any,triggerRealtimeAuditStatusCallBack,anErrorCallback){
    this.commonService.getApi(this.amtApiUrl + '/checkRealtimeViolationMasterStatus/'+inspectionId+'/'+this.commonService.getCurrentUserid(), triggerRealtimeAuditStatusCallBack, anErrorCallback);
  }

  getAuditStatuses(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditStatuses', aCallback, anErrorCallback);
  }

  getAuditTaskResultCodes(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditTaskResultCodes', aCallback, anErrorCallback);
  }

  getAuditors(aCallback, anErrorCallback) {
    this.commonService.getApi(this.umaApiUrl + '/getAuditors', aCallback, anErrorCallback);
  }

  getRealtimeTriggerTimeElapsed(anAuditId:any,getRealtimeTriggerTimeElapsedCallback, anErrorCallback){
    this.commonService.getApi(this.amtApiUrl + '/realtimeTriggerTimeElapsed/'+ anAuditId, getRealtimeTriggerTimeElapsedCallback, anErrorCallback);
  }

  getInspectionCentreInfo(stationId:string, callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getInspectionCentreInfo/' + stationId, callback, errorCallback);
  }

  getTriggerDefinitions(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getTriggerDefinitions', aCallback, anErrorCallback);
  }

  getAmtParameter(paramNames:any,aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/amtParameters',paramNames, aCallback, anErrorCallback);
  }

  getTriggerOccurrences(by:any, callback, errorCallback) {
     this.commonService.getApi(this.amtApiUrl + '/getTriggerOccurrences?by=' + encodeURI(JSON.stringify(by)), callback, errorCallback);
  }

  getInspectionInfos(testIds:string, callback, errorCallback) {
    this.commonService.postApi(this.emisApiUrl + '/getInspectionInfos',testIds, callback, errorCallback);
  }

  getInspectionInfoByInpectionIdAndTriggerId(testId:string,triggerId:any, callback, errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/inspection-opacity-results/' + testId+'/'+triggerId, callback, errorCallback);
  }

  saveAudit(anAudit:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/saveAudit/' + this.commonService.getCurrentUserid(), anAudit, aCallback, anErrorCallback);
  }

  generateTriggerOccurAudits(searchBy:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/triggerOccrAudits/' + this.commonService.getCurrentUserid(), searchBy, aCallback, anErrorCallback);
  }

  updateSafetyHighRiskFlagCountRemaining(updateSafetyHighRiskFlagObje:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.emisApiUrl + '/modifySafetyHighRiskFlagRemainingCount', updateSafetyHighRiskFlagObje, aCallback, anErrorCallback);
  }

  saveOrUpdateTechnicianRestrictHrs(selectedTechnician:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.icpaaApiUrl + '/technicianRestrictHours/' + this.commonService.getCurrentUserid(), selectedTechnician, aCallback, anErrorCallback);
  }

  getStationRestrictHrs(stationId:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/stationRestrictHours/' + stationId, aCallback, anErrorCallback);
  }

  saveOrUpdateStationRestrictHrs(selectedStation:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/stationRestrictHours/' + this.commonService.getCurrentUserid(), selectedStation, aCallback, anErrorCallback);
  }

  deleteStationRestrictHrs(stationRestrictWorkHourId:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/deleteStationRestrictHours/' + stationRestrictWorkHourId, aCallback, anErrorCallback);
  }

  getTechnicianRestrictHrs(personId:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icpaaApiUrl + '/technicianRestrictHours/' + personId, aCallback, anErrorCallback);
  }

  deleteTechnicianRestrictHrs(technicianRestrictWorkHourId:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.icpaaApiUrl + '/deleteTechnicianRestrictHours/' + technicianRestrictWorkHourId, aCallback, anErrorCallback);
  }

  updatePostTestViolation(postTestViolationId:any,auditId:any,userId:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/updatePostTestViolation/' + postTestViolationId+'/'+auditId+'/'+userId, aCallback, anErrorCallback);
  }

  acceptRealtimeAudit(realTestViolationMasterDTO:any,auditType:any,triggerId:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/accept-expired-realtime-audits/'+auditType+'/'+triggerId,realTestViolationMasterDTO, aCallback, anErrorCallback);
  }

  updateDBCounter(realTestViolationMasterDTO: any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/modify-timer-realtime-audits',realTestViolationMasterDTO, aCallback, anErrorCallback);
  }

  searchAudits(searchCriteria:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/searchAudits', searchCriteria, aCallback, anErrorCallback);
  }

  auditHistoryDetails(stationId:any,personId:any,vin:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/audit-history-details/' +stationId+'/'+personId+'/'+vin, aCallback, anErrorCallback);
  }

  getAuditById(anAuditId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditById/' + anAuditId, aCallback, anErrorCallback);
  }

  getAuditDetails(anAuditId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditDetails/' + anAuditId, aCallback, anErrorCallback);
  }

  getVisInspectionImages(inspectionId:any, getInspectionImagesCallback,anErrorCallback){
    this.commonService.getApi(this.emisApiUrl + '/getVisInspectionImages/' + inspectionId, getInspectionImagesCallback,anErrorCallback);
  }

  getAuditResultByAuditId(anAuditId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditResultByAuditId/' + anAuditId, aCallback, anErrorCallback);
  }

  saveAuditResult(anAuditResult:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/saveAuditResult/' + this.commonService.getCurrentUserid(), anAuditResult, aCallback, anErrorCallback);
  }

  getAuditTasksByAuditTypeId(anAuditTypeId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditTasksByAuditTypeId/' + anAuditTypeId, aCallback, anErrorCallback);
  }

  getAllAuditTasksByAuditTypeId(anAuditTypeId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAllAuditTasksByAuditTypeId/' + anAuditTypeId, aCallback, anErrorCallback);
  }

  getObservationsByAuditTaskId(anAuditTaskId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getObservationsByAuditTaskId/' + anAuditTaskId, aCallback, anErrorCallback);
  }

  getAuditFindingTypes(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditFindingTypes', aCallback, anErrorCallback);
  }

  getAuditFindingSubTypes(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditFindingSubTypes', aCallback, anErrorCallback);
  }

  getObservationsByAuditTypeId(anAuditTypeId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getObservationsByAuditTypeId/' + anAuditTypeId, aCallback, anErrorCallback);
  }

  getAuditDocsByAuditId(anAuditId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditDocsByAuditId/' + anAuditId, aCallback, anErrorCallback);
  }

  downloadAuditReportByAuditId(auditId:string): Observable<any>  {
    return this.commonService.downloadFile(this.amtApiUrl + '/downloadAuditReportByAuditId/' + auditId);
  }

  uploadAuditDoc(anAuditId:string, data:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.amtApiUrl + '/uploadAuditDoc/' + anAuditId + "/" + this.commonService.getCurrentUserid(), data);
  }  
  updateAuditDoc(anAuditDoc:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/updateAuditDoc/' + this.commonService.getCurrentUserid(), anAuditDoc, aCallback, anErrorCallback);
  }

  removeAuditDoc(anAuditDocId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/removeAuditDoc/' + anAuditDocId, aCallback, anErrorCallback);
  }

  downloadAuditDoc(anAuditDocId:string): Observable<any>  {
    return this.commonService.downloadFile(this.amtApiUrl + '/downloadAuditDoc/' + anAuditDocId);
  }

  getAuditNotesByAuditId(anAuditId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAuditNotesByAuditId/' + anAuditId, aCallback, anErrorCallback);
  }

  getAudioLinkByAuditId(anAuditId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getAudioLinkByAuditId/' + anAuditId, aCallback, anErrorCallback);
  }

  saveAuditNote(anAuditNote:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/saveAuditNote', anAuditNote, aCallback, anErrorCallback);
  }

  submitAuditResult(anAuditResult:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/submitAuditResult/' + this.commonService.getCurrentUserid(), anAuditResult, aCallback, anErrorCallback);
  }

  escalateAudit(anAuditId:any, assignTo:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/escalateAudit/' + anAuditId + "/" + assignTo + "/" + this.commonService.getCurrentUserid(), aCallback, anErrorCallback);
  }

  cancelAudit(anAuditId:string, assignTo:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/cancelAudit/' + anAuditId + '/'  + assignTo + "/" + this.commonService.getCurrentUserid(), aCallback, anErrorCallback);
  }

  reassignAudit(anAuditId:string, assignTo:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/reassignAudit/' + anAuditId + '/' + assignTo + "/" + this.commonService.getCurrentUserid(), aCallback, anErrorCallback);
  }

  approveAudit(auditIds:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/approveAudit/'+this.commonService.getCurrentUserid(), auditIds, aCallback, anErrorCallback);
  }

  createTicket(anAuditId:string, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/createZendeskTicket/' + anAuditId + '/' + this.commonService.getCurrentUserid(), aCallback, anErrorCallback);
  }

  getStationLockoutReasons(callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getStationLockoutReasons', callback, errorCallback);
  }

  getStationFlagHistory(stationId:any,callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/stationHighRiskFlagDetails/'+stationId, callback, errorCallback);
  }
  getTechnicianFlagHistory(personId:any,callback, errorCallback) {
    this.commonService.getApi(this.icpaaApiUrl + '/technicianHighRiskFlagDetails/'+personId, callback, errorCallback);
  }
  getVehicleFlagHistory(vin:any,callback, errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/vehicleHighRiskFlagHistory/'+vin, callback, errorCallback);
  }
  getStations(aSearch:string, callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getInspectionCentres/' + encodeURI(aSearch), callback, errorCallback);
  }

  highRiskDetailsHistory(sourceDetails:any,activeFlag:boolean,requestFlag:string,structuralFlag:any, callback, errorCallback) {
    this.commonService.postApi(this.emisApiUrl + '/safety-high-risk-active-history-details/' + requestFlag + '/' + activeFlag+'/'+structuralFlag, sourceDetails, callback, errorCallback);
  }

  lockStation(lockoutStationDto:any, userId:any, callback, errorCallback){
    this.commonService.postApi(this.icaaApiUrl + '/lockout/'+ userId, lockoutStationDto, callback, errorCallback);
  }

  getStationLockouts(callback, errorCallback) {
    this.commonService.getApi(this.fcraApiUrl + '/getStationLockouts', callback, errorCallback);
  }

  unlockStation(stationUnlockRequest:any, callback, errorCallback){
    this.commonService.postApi(this.icaaApiUrl + '/unlockoutStation', stationUnlockRequest, callback, errorCallback);
  }

  lockVehicle(vehicleLockoutDto:any, callback, errorCallback){
    this.commonService.postApi(this.vcaApiUrl + '/vehicleLockout', vehicleLockoutDto, callback, errorCallback);
  }

  getTechnicianLockoutReasons(callback, errorCallback) {
    this.commonService.getApi(this.icpaaApiUrl + '/getTechnicianLockoutReasons', callback, errorCallback);
  }

  getVehicleLockoutReasons(callback, errorCallback) {
    this.commonService.getApi(this.vcaApiUrl + '/vehicle-lockout-reasons', callback, errorCallback);
  }

  getTechnicians(searchBy:any, callback, errorCallback) {
    this.commonService.getApi(this.icpaaApiUrl + '/findTechniciansAndAssignedStations?searchBy=' + encodeURI(JSON.stringify(searchBy)), callback, errorCallback);
  }

  lockTechnician(techLockoutInfo:any, callback, errorCallback){
    this.commonService.postApi(this.icpaaApiUrl + '/setTechnicianLockoutV2', techLockoutInfo, callback, errorCallback);
  }

  getTechnicianLockouts(callback, errorCallback) {
    this.commonService.getApi(this.icpaaApiUrl + '/getTechnicianLockouts', callback, errorCallback);
  }

  getVehicleLockouts(callback, errorCallback) {
    this.commonService.getApi(this.vcaApiUrl + '/vehicle-lockout-details', callback, errorCallback);
  }

  unlockTechnician(techLockoutInfo:any, callback, errorCallback){
    this.commonService.postApi(this.icpaaApiUrl + '/clearTechnicianLockout',
    techLockoutInfo, callback, errorCallback);
  }

  getTechniciansByStationId(stationId:any, callback, errorCallback) {
    this.commonService.getApi(this.icaaApiUrl + '/getTechniciansByStationId/' + stationId, callback, errorCallback);
  }

  getVehicleTestResults(vin:string, callback, errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/getVehicleResults/' + vin, callback, errorCallback);
  }

  setStationRiskFlag(riskFlag:any, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/setStationRiskFlag/'+this.commonService.getUserId(), riskFlag, callback, errorCallback);
  }

  removeStationRiskFlag(riskFlag:any, callback, errorCallback) {
    this.commonService.postApi(this.icaaApiUrl + '/stationRiskFlags/'+this.commonService.getUserId(), riskFlag, callback, errorCallback);
  }

  removeTechnicianRiskFlag(riskFlag:any, callback, errorCallback) {
    this.commonService.postApi(this.icpaaApiUrl + '/technicianRiskFlags/'+this.commonService.getUserId(), riskFlag, callback, errorCallback);
  }

  removeVehicleRiskFlag(riskFlag:any, callback, errorCallback) {
    this.commonService.postApi(this.emisApiUrl + '/vehicleRiskFlags/'+this.commonService.getUserId(), riskFlag, callback, errorCallback);
  }

  setTechnicianRiskFlag(riskFlag:any, callback, errorCallback) {
    this.commonService.postApi(this.icpaaApiUrl + '/setTechnicianRiskFlag/'+this.commonService.getUserId(), riskFlag, callback, errorCallback);
  }

  setVehicleRiskFlag(riskFlag:any, callback, errorCallback) {
    this.commonService.postApi(this.emisApiUrl + '/setVehicleRiskFlag/'+this.commonService.getUserId(), riskFlag, callback, errorCallback);
  }

  getVehicleYears(callback, errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/getVehicleYears', callback, errorCallback);
  }

  getVehicleMakes(year:any, callback, errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/getVehicleMakesByYear/' + year, callback, errorCallback);
  }

  getVehicleModels(year:any, make:string, callback, errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/getVehicleModelsByYearMake/' + year +'/' + make, callback, errorCallback);
  }

  getVehiclesByYearMakeModel(year:any, make:string, model:string, callback, errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/getVehicleTestResults/' + year +'/' + make + "/" + model, callback, errorCallback);
  }

  getSafetyComponentDetails(componentFlag:any,requestParam:any,sourceId:any,structuralFlag:any,callback,errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/new-safety-component-details/' + componentFlag+'?requestParam='+requestParam+'&sourceId='+sourceId+'&structuralFlag='+structuralFlag, callback, errorCallback);
  }

  getSafetyHighRiskComponentDetails(componentFlag:any,sourceId:any,safetyHighRiskBatchId:any,callback,errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/safety-high-risk-details/'+sourceId +'/'+componentFlag+'/'+safetyHighRiskBatchId, callback, errorCallback);
  }

  getSafetyHighRiskComponentDetailsByVehType(auditId:any,callback,errorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/real-time-audit-safety-high-risk-details/'+auditId, callback, errorCallback);
  }

  getComplianceActionRTAudits(technicianId:any,stationId:any,vin:any,callback,errorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getComplianceActionRTAudits/'+technicianId+'/'+stationId+'/'+vin, callback, errorCallback);
  }

  getHighRiskFlagDetails(technicianId:any,vin:any,stationId:any,callback,errorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/real-time-high-risk-details/'+technicianId+'/'+vin+'/'+stationId, callback, errorCallback);
  }

  getComplianceActions(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getComplianceActions', aCallback, anErrorCallback);
  }

  getComplianceActionsByTargetId(targetId:any,aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getComplianceActionByTargetId/'+targetId, aCallback, anErrorCallback);
  }

  getComplianceActionTypes(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getComplianceActionTypes', aCallback, anErrorCallback);
  }

  getObservations(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getObservations', aCallback, anErrorCallback);
  }

  getComplianceActionTargets(aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/complianceActionTarget', aCallback, anErrorCallback);
  }

  saveComplianceActionResult(aComplianceActionResult:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/saveComplianceActionResult/' + this.commonService.getCurrentUserid(), aComplianceActionResult, aCallback, anErrorCallback);
  }

  getComplianceActionTooltip(anAuditTaskId, aComplianceActionTyeId, aSearchString, aCallback, anErrorCallback) {
    this.commonService.getApi(this.amtApiUrl + '/getComplianceActionTooltip/'+anAuditTaskId+"/"+aComplianceActionTyeId+"/"+aSearchString, aCallback, anErrorCallback);
  }

  postSafetyHighRiskFlagDetails(safetyHighRiskFlagDtoList:any,createdByProcess:string, aCallback, anErrorCallback) {
    this.commonService.postApi(this.emisApiUrl + '/safetyHighRiskFlagDetails/'+createdByProcess, safetyHighRiskFlagDtoList, aCallback, anErrorCallback);
  }

  getSafetyAuditNotesDocs(inspectionId:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/safety-result-notes/'+inspectionId, aCallback, anErrorCallback);
  }

  lookUp(lookUpStr:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.lookupApiUrl + '/en/get/'+ lookUpStr, aCallback, anErrorCallback);
  }

  getCountries(locale, aCallback, anErrorCallback) {
    this.commonService.getApi(this.lookupApiUrl + '/getCountries/'+locale, aCallback, anErrorCallback);    
  }

  getProvinces(locale, aCallback, anErrorCallback) {
    this.commonService.getApi(this.lookupApiUrl + '/getProvinces/'+locale, aCallback, anErrorCallback);    
  }
  
  getCommercialvehicleTypes(aCallback, anErrorCallback) {
    this.commonService.getApi(this.lookupApiUrl + '/getCommercialvehicleTypes', aCallback, anErrorCallback);    
  }

  getSafetyOOPResults(searchCriteria:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.emisApiUrl + '/getSafetyOOPResults', searchCriteria, aCallback, anErrorCallback);
  }
  
  getMaxReportNumByVisId(visId:any, aCallback, anErrorCallback) {
    this.commonService.getApi(this.emisApiUrl + '/getMaxReportNumByVisId/'+visId, aCallback, anErrorCallback);
  }

  saveSafetyOOPRecord(oppRecord:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.emisApiUrl + '/saveSafetyOOPRecord', oppRecord, aCallback, anErrorCallback);
  }

  uploadOOPFiles(inspectionId:number, userId: string, formData:any): Observable<HttpEvent<any>> {
    return this.commonService.uploadFile(this.emisApiUrl + '/uploadOOPFiles/'+inspectionId+"/"+userId, formData);
  }

  getTechnicianById(technicianId:string, callback, errorCallback) {
    this.commonService.getApi(this.icpaaApiUrl + '/getTechnicianById/' + technicianId, callback, errorCallback);
  }

  saveAuditTaskAssignments(assignedAuditTasks:any,auditTypeId:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/saveAuditTasksByAuditTypeId/'+auditTypeId+"/"+this.commonService.getCurrentUserid(), assignedAuditTasks, aCallback, anErrorCallback);
  }

  saveObservationsByAuditTaskId(assignedObservations:any,auditTaskId:any, aCallback, anErrorCallback) {
    this.commonService.postApi(this.amtApiUrl + '/saveObservationsByAuditTaskId/'+auditTaskId+"/"+this.commonService.getCurrentUserid(), assignedObservations, aCallback, anErrorCallback);
  }

}

<!-- Default -->
<footer class="ontario-footer ontario-footer--default">
  <div class="ontario-row">
      <div class="ontario-columns ontario-small-12">
          <ul class="ontario-footer__links-container ontario-footer__links-container--inline">
              <li><a i18n class="ontario-footer__link" (click)="openAccessibility()" tabindex="0">Accessibility</a></li>
              <li><a i18n class="ontario-footer__link" (click)="openPrivacy()" tabindex="0">Privacy</a></li>
              <li><a i18n class="ontario-footer__link" (click)="this.showContactUs = true;" tabindex="0">Contact us</a></li>
          </ul>
          <div class="ontario-footer__copyright">
              <a i18n class="ontario-footer__link" (click)="openCopyright()" tabindex="0">&copy; King’s Printer for Ontario, <span class="ontario-nbsp">2022</span></a>
          </div>
      </div>
  </div>
</footer>
<app-dialog #contactUsDialog [(visible)]="showContactUs">
    <h1 i18n class="ontario-label--heading" tabindex="0">
        Contact Us
    </h1>
    <label i18n class="ontario-lead-statement" tabindex="0">
      Thank you for visiting the Ontario Vehicle Safety and Emissions Inspection Portal. We look forward to your suggestions and inquiries.
      Please enter the information below and press the submit button.
    </label>
    <br>
    <div class="ontario-form-group">
      <label i18n class="ontario-label" for="name" tabindex="0">
          Name<span class="ontario-label__flag">(required)</span>
      </label>
      <div class="ontario-error-messaging" *ngIf="name.invalid && (name.dirty || name.touched)">
        <div class="ontario-error-messaging__container">
            <div class="ontario-error-messaging__icon">
              <img style="height:24px;width:24px;" src="assets/img/ontario-icon-alert-error.svg">
            </div>
            <div i18n class="ontario-error-messaging__content">
                Name is required.
            </div>
        </div>
      </div>
      <input id="name" name="name" class="ontario-input ontario-input--20-char-width" type="text" #name="ngModel" i18n-placeholder [(ngModel)]="contactUs.userName" required="true" tabindex="0">
      <label i18n class="ontario-label" for="email" tabindex="0">
        Email<span class="ontario-label__flag">(required)</span>
      </label>
      <div class="ontario-error-messaging" *ngIf="email.invalid && (email.dirty || email.touched)">
        <div class="ontario-error-messaging__container">
            <div class="ontario-error-messaging__icon">
              <img style="height:24px;width:24px;" src="assets/img/ontario-icon-alert-error.svg">
            </div>
            <div i18n class="ontario-error-messaging__content">
                The email is invalid.
            </div>
        </div>
      </div>
      <input id="email" name="email" class="ontario-input ontario-input--20-char-width" type="email" #email="ngModel" i18n-placeholder [(ngModel)]="contactUs.userEmailAddress" 
             pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
             required="true" tabindex="0">
      <label i18n class="ontario-label" for="phone" tabindex="0">
        Telephone<span class="ontario-label__flag">(optional)</span>
      </label>
      <div class="ontario-error-messaging" *ngIf="phone.invalid && (phone.dirty || phone.touched)">
        <div class="ontario-error-messaging__container">
            <div class="ontario-error-messaging__icon">
              <img style="height:24px;width:24px;" src="assets/img/ontario-icon-alert-error.svg">
            </div>
            <div i18n class="ontario-error-messaging__content">
                The phone number must only include numbers.
            </div>
        </div>
      </div>
      <input id="phone" name="phone" class="ontario-input ontario-input--20-char-width" type="tel" #phone="ngModel" [(ngModel)]="contactUs.userPhoneNum" 
             maxlength="14" pattern="^\(\d{3}\)\s\d{3}-\d{4}$" (keyup)="change()" tabindex="0">
      <label i18n class="ontario-label" for="subject" tabindex="0">
        Subject<span class="ontario-label__flag">(required)</span>
      </label>
      <div class="ontario-error-messaging" *ngIf="subject.invalid && (subject.dirty || subject.touched)">
        <div class="ontario-error-messaging__container">
            <div class="ontario-error-messaging__icon">
              <img style="height:24px;width:24px;" src="assets/img/ontario-icon-alert-error.svg">
            </div>
            <div i18n class="ontario-error-messaging__content">
              Subject is required.
            </div>
        </div>
      </div>
      <input id="subject" name="subject" class="ontario-input ontario-input--20-char-width" type="text" #subject="ngModel" i18n-placeholder [(ngModel)]="contactUs.messageTitle" required="true" tabindex="0">
      <label i18n class="ontario-label" for="message" tabindex="0">
        Message<span class="ontario-label__flag">(required)</span>
      </label>
      <div class="ontario-error-messaging" *ngIf="message.invalid && (message.dirty || message.touched)">
        <div class="ontario-error-messaging__container">
            <div class="ontario-error-messaging__icon">
              <img style="height:24px;width:24px;" src="assets/img/ontario-icon-alert-error.svg">
            </div>
            <div i18n class="ontario-error-messaging__content">
              Message is required.
            </div>
        </div>
      </div>
      <textarea id="message" name="message" class="ontario-input ontario-textarea" [(ngModel)]="contactUs.messageBody" #message="ngModel" required="true" tabindex="0"></textarea>
    </div>
    <button class="ontario-button ontario-button--primary" i18n id="Footer.Submit" (click)="addContactUs()" [disabled]="disableAddButton || invalidContactUsInputs()" tabindex="0">
      Submit
    </button>
    <button class="ontario-button ontario-button--primary" i18n id="Footer.Close" (click)="this.showContactUs = false" tabindex="0">Close
    </button>

    <div>
      <p i18n class="ontario-hint" tabindex="0">
        Inspection Centre Technical Support at 1-833-420-2110
      </p>
      <p i18n class="ontario-hint" tabindex="0">
        8:00 a.m. to 6:00 p.m. (ET) Monday to Friday (excluding Holidays)
      </p>
      <p i18n class="ontario-hint" tabindex="0">
        9:00 a.m. to 3:00 p.m. (ET) Saturday (excluding Holidays)
      </p>
    </div>
</app-dialog>
import { Component,Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'component-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.css']
})

export class ImageModalComponent {
  @Input() imageUrl = null;
  @Input() imageName = '';
  @Input() imageId = '';
  @Input() tooltip = '';
  @Output() onClose = new EventEmitter(); 
  @Output() onClick = new EventEmitter(); 

  constructor() { }

  closeModal(){
    this.onClose.emit(this.imageId);
  }

  onClikcImage(){
    this.onClick.emit(this.imageId);
  }
}

import { Component, OnInit } from '@angular/core';
import { CommonService } from 'apps/common/services/common.service';

@Component({
  selector: 'login-enrolment',
  templateUrl: './enrolment.component.html',
  styleUrls: ['./enrolment.component.css']
})
export class EnrolmentComponent implements OnInit {
  locale: string = "en";
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.locale = "en";
    const uri = window.location.pathname.split('/');
    if (uri.length > 2) {
      this.locale = uri[2];
    }
  }
}

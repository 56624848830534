<form>
  <mat-form-field [ngStyle]="appStyle" [floatLabel]="inputdisabled?'always':'auto'">
    <input [id]="cmpntId" [disabled]="inputdisabled || commonService.isCmpntDisabled(cmpntId)" (blur)="onFieldBlurClient(searchValue)"
      [required]="required"
      matInput [placeholder]="placeholder" [(ngModel)]="searchValue" [matAutocomplete]="auto" [formControl]="autoCompletFormControl">
      <mat-error *ngIf="autoCompletFormControl.hasError('required')">
        {{placeholder}} is required
      </mat-error>
      <button type="button" mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>  
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" [displayWith]="getOptValue">
    <mat-option style="height: 1.7em;" *ngFor="let option of filteredIdOptions | async" [value]="option.id">
      <span [innerHTML]="option.value | highlight: toHighlight"></span>
      <span></span>
    </mat-option>
  </mat-autocomplete>
  
</form>